import { Guardian } from '../pages/guardian';
import { GuardianDetails } from '../pages/guardian-details';
import { Guardians } from '../pages/guardians';

const guardiansRouter = {
  path: 'guardians',
  children: [
    {
      path: '',
      element: <Guardians />,
      children: []
    },
    {
      path: 'add-guardian',
      element: <Guardian />,
      children: []
    },
    {
      path: ':guardianId',
      element: <GuardianDetails />,
      children: []
    },
    {
      path: 'edit-guardian/:guardianId',
      element: <Guardian />,
      children: []
    }
  ]
};

export default guardiansRouter;
