import { muiDateToString } from 'src/shared/utils/helpers';
import { IStudentDetailsAPI, TStudentsRoot, TStudentsRootAPI } from '../type';

export function studentMapper(value: any): any {
  const fullName = value?.fullName;
  return {
    id: value?._id,
    code: value?.code,
    fullName: value?.fullName,
    email: value?.email,
    name: value?.name,
    guardian: value?.guardian?.fullName,
    archived: value?.archived
  };
}
export function studentFormMapper(values: any): any {
  const date = new Date();
  const currentYear = new Date().getFullYear();
  console.log(currentYear);

  const term1 =
    new Date(currentYear, 0, 2) < date && date < new Date(currentYear, 5, 15);

  const term2 =
    date > new Date(currentYear, 5, 15) && date < new Date(currentYear, 12, 1);

  const schoolAdmittionYearValue =
    +values.schoolAdmittionYear !== 0 && term1
      ? currentYear - Number(values.schoolAdmittionYear)
      : Number(values.schoolAdmittionYear) !== 0 && term2
      ? currentYear + 1 - Number(values.schoolAdmittionYear)
      : '';

  const student = {
    name: values.name,
    surname: values.surname,
    patronymic: values.patronymic,
    description: values.description,
    note: values.note,
    phoneNumbers: values?.isForeign
      ? [values.phoneNumbers].flat()
      : [`994${values.phoneNumbers}`].flat(),
    email: values.email,
    dateOfBirth: muiDateToString(values?.dateOfBirth),
    groupDP: Number(values.groupDP),
    schoolAdmittionYear: schoolAdmittionYearValue,
    guardian: values.guardian?.id,
    relationship: values.relationship,
    package: values.package?.id,
    gender: values.gender,
    registeredBranches: [values.registeredBranches]
  };
  return student;
}

export function studentDetailsMapper(value: IStudentDetailsAPI): any {
  const date = new Date();
  const currentYear = new Date().getFullYear();

  const term1 =
    new Date(currentYear, 0, 2) < date && date < new Date(currentYear, 5, 15);
  const term2 =
    date > new Date(currentYear, 5, 15) && date < new Date(currentYear, 12, 1);

  const schoolAdmittionYearValue =
    currentYear - Number(value?.schoolAdmittionYear) <= 12 && term1
      ? currentYear - Number(value?.schoolAdmittionYear)
      : term2
      ? currentYear - Number(value?.schoolAdmittionYear) + 1
      : '';

  const student = {
    id: value?.id,
    code: value?.code,
    fullName: value?.fullName,
    patronymic: value?.patronymic,
    name: value.name,
    surname: value.surname,
    description: value.description,
    phoneNumbers: [String(value.phoneNumbers)],
    email: value.email,
    dateOfBirth: value.dateOfBirth,
    groupDP: value.groupDP,
    schoolAdmittionYear: schoolAdmittionYearValue,
    guardian: value.guardian,
    relationship: value.relationship,
    package: value.package,
    groups: value.groups,
    gender: value.gender,
    registeredBranches: value?.registeredBranches[0]
  };
  return student;
}

export const studentsMapper = function (data: TStudentsRootAPI): TStudentsRoot {
  const students = data?.data?.map((student) => studentMapper(student));
  const total = data?.total;
  return { data: students, total: total };
};
