import { Outlet } from 'react-router';
import { LessonsContainer } from '../containers/lessons-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Lessons() {
  return (
    <TableContextProvider>
      <LessonsContainer />
      <Outlet />
    </TableContextProvider>
  );
}
