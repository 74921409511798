import TableContextProvider from 'src/shared/provider/table-context-provider';
import { GroupsContainer } from '../containers/groups';

export function Groups() {
  return (
    <TableContextProvider>
      <GroupsContainer />
    </TableContextProvider>
  );
}
