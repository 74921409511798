import { Outlet } from 'react-router';
import { RoomContainer } from '../containers/room-container';

export const Room = function () {
  return (
    <>
      <RoomContainer />
      <Outlet />
    </>
  );
};
