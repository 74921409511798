import { AxiosResponse } from 'axios';

import axios from 'src/shared/utils/axios';
import getAuthToken from 'src/shared/utils/getAuthToken';
import queryStringMaker from '../utils/query-string-maker';

export const getGenericService = async (query): Promise<AxiosResponse> => {
  const [, type, queryObj] = query.queryKey;

  try {
    return await axios.get(`/api/v1/${type}${queryStringMaker(queryObj)}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const getGenericServiceById = async (query): Promise<AxiosResponse> => {
  const [type, id] = query.queryKey;
  if (!id) return;
  try {
    return await axios.get(`/api/v1/${type}/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const getLowerCategoriesByUpperSlug = async (
  query
): Promise<AxiosResponse> => {
  const [type] = query.queryKey;
  try {
    return await axios.get(`/api/v1/categories/parent/${type}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const addAndUpdateGenericService = async ({
  data,
  type,
  genericId = null
}): Promise<AxiosResponse> => {
  const url = genericId ? `/api/v1/${type}/${genericId}` : `/api/v1/${type}`;

  try {
    return await axios({
      url,
      method: genericId ? 'patch' : 'post',
      data,
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const editGenericService = async ({
  type,
  genericId,
  data
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/${type}/${genericId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const makeDeleteGenericService = async ({
  type,
  genericId
}): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/${type}/${genericId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};

export const archiveGenericService = async ({
  type,
  genericId,
  isArchived
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(
      `/api/v1/${type}/${genericId}/${isArchived ? 'unarchive' : 'archive'}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      }
    );
  } catch (e) {
    return e;
  }
};

export const removeGenericService = async ({
  genericId,
  type
}): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/${type}/${genericId}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
  } catch (e) {
    return e;
  }
};
