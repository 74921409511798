import { Box, Modal, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';

const style: any = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 300,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3
};

function SharedConfirmModal() {
  const { state, dispatch } = useContext(ConfirmationModalContext);

  const handleClose = () => {
    dispatch({
      type: 'close'
    });
  };

  return (
    <Modal
      keepMounted
      open={state.isOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      sx={{ zIndex: 9999999999 }}
    >
      <Box sx={style}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ py: 2, fontSize: '18px', textAlign: 'center' }}
        >
          {state.message}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: '15px;' }}
            onClick={handleClose}
          >
            {state.closeMessage}
          </Button>
          <Button
            variant="contained"
            color={state.color}
            sx={{ marginTop: '15px;' }}
            onClick={state.handleConfirm}
            disabled={false}
          >
            {state.buttonMessage}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default SharedConfirmModal;
