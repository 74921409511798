import moment from 'moment';
import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { DATE_TYPES } from 'src/shared/costants/constants';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { ITransaction } from '../types';
import { TitleStatusAndHidden } from '../components/title-desc-and-hidden';
import { AmountTransactionsTable } from '../components/amount';
import { useTranslation } from 'react-i18next';
import { TransactionssTableActionButtons } from '../components/transactions-table-action-buttons';

export const useTransactionsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('transactions.name'),
      href: '/dashboard/transactions'
    }
  ];
  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      // {
      //   id: 'code',
      //   align: 'left',
      //   label: t('table.code'),
      //   extructor: (transaction: ITransaction) => transaction?.code
      // },
      {
        id: 'title',
        align: 'center',
        label: t('table.title'),
        extructor: (transaction: ITransaction) => (
          <TitleStatusAndHidden transaction={transaction} />
        )
      },

      {
        id: 'payment-method',
        align: 'center',
        label: t('table.paymentMethod'),
        extructor: (transaction: ITransaction) => transaction?.paymentMethod
      },
      {
        id: 'category',
        align: 'center',
        label: t('table.category'),
        extructor: (transaction: ITransaction) => transaction?.category?.title
      },
      {
        id: 'date',
        align: 'center',
        label: t('table.date'),
        extructor: (transaction: ITransaction) =>
          transaction?.realDate
            ? moment(transaction?.realDate).format(DATE_TYPES.mdy)
            : moment(transaction?.createdAt).format(DATE_TYPES.mdy)
      },

      {
        id: 'amount',
        align: 'center',
        label: t('table.amount'),
        extructor: (transaction: ITransaction) => (
          <AmountTransactionsTable
            type={transaction?.isIncome}
            amount={transaction?.amount}
          />
        )
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (transaction: ITransaction) => {
          return <TransactionssTableActionButtons transaction={transaction} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
