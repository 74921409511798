import { muiDateToString } from 'src/shared/utils/helpers';

export const recurrenceMapper = function (values) {
  const recurrence = {
    title: values?.title,
    amount: values?.amount,
    description: values?.description,
    category: values?.category?._id,
    isIncome: values?.isIncome === 'income' ? true : false,
    paymentMethod: values?.paymentMethod,
    branch: values?.branch?.id,
    relatedTo: values?.relatedTo?.id,
    periodicity: `*/20 * * * * *`,
    priority: values?.priority.id,
    remindDuration: values?.remindDuration,
    dueDate: muiDateToString(values?.dueDate),
    hidden: values?.hidden,
    recipients: [
      { user: '6542c945089de22e00c92a1c', notifications: ['sms', 'email'] }
    ]
  };
  return recurrence;
};

export const getPriority = (data) => {
  let priority = {
    id: undefined,
    name: undefined
  };

  switch (data?.type) {
    case '1':
      priority.id = 1;
      break;
    case '2':
      priority.id = 2;
      break;
    case '3':
      priority.id = 3;
      break;
    case '4':
      priority.id = 4;
      break;
    case '5':
      priority.id = 5;
      break;
    case '6':
      priority.id = 6;
      break;
    default:
      break;
  }

  priority.name = data?.priority;

  return priority;
};
