import { Outlet } from 'react-router';
import { StudentsContainer } from '../containers/students-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Students() {
  return (
    <TableContextProvider>
      <StudentsContainer />
      <Outlet />
    </TableContextProvider>
  );
}
