import { Grid, Card } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { FC, ReactElement } from 'react';
import PageHeaderNew from '../page-header-new';

interface PageWrapperProps {
  title: string;
  description?: string;
  routes?: any[];
  action?: ReactElement;
  isPaper?: boolean;
}

const PageWrapper: FC<PageWrapperProps> = ({
  title,
  description,
  children,
  routes,
  action,
  isPaper = true
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Grid
        sx={{
          p: { xs: 1, sm: 3 }
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <PageHeaderNew
            routes={routes}
            title={title}
            description={description}
          >
            {action}
          </PageHeaderNew>
          {isPaper ? <Card>{children}</Card> : children}
        </Grid>
      </Grid>
      {/* <PageFooter /> */}
    </>
  );
};

export default PageWrapper;
