import { Outlet } from 'react-router';
import { UserContainer } from '../containers/user-container';

export const User = function () {
  return (
    <>
      <UserContainer />
      <Outlet />
    </>
  );
};
