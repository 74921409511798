import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { OmInput } from 'src/shared/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { options } from 'numeral';
import { validateYupSchema } from 'formik';

export const BranchForm = function ({
  formik,
  managersData,
  branchId,
  isSubmitting
}) {
  const { t } = useTranslation();

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2} sx={{ maxWidth: '550px' }}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.name')}
            name="name"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.address')}
            name="address"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Autocomplete
            multiple
            fullWidth={true}
            options={managersData || []}
            disablePortal
            value={formik.values?.managers}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_e, newValue: any) => {
              formik.setFieldValue('managers', newValue ? newValue : '');
            }}
            getOptionLabel={(option) => {
              return `${option?.fullName} (${option?.code})`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.managers')}
              />
            )}
          />
          {!!formik.errors?.managers && formik.touched?.managers && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors?.managers}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t(`buttons.${branchId ? 'update' : 'addBranch'}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
