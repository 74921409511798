import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  LaunchTwoTone as LaunchTwoToneIcon,
  Edit as EditIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useMakeDeleteEmployee } from '../hooks/use-make-delete-employee';
import { useArchiveEmployee } from '../hooks/use-archive-employee';

export const EmployeesTableActionButtons: FC<{
  employee;
}> = ({ employee }) => {
  const { t } = useTranslation();
  const { makeDeleteEmployee } = useMakeDeleteEmployee();
  const { archiveEmployee } = useArchiveEmployee();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/employees/${employee.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/employees/edit-employee/${employee.id}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={t(
            `${
              employee.archived
                ? 'tooltips.unArchiveEmployee'
                : 'tooltips.archiveEmployee'
            }`
          )}
          arrow
        >
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    archiveEmployee({
                      type: 'users',
                      genericId: employee.id,
                      isArchived: employee.archived
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t(
                    `${
                      employee.archived
                        ? 'messages.unArchiveEmployee'
                        : 'messages.archiveEmployee'
                    }`
                  ),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'primary'
                }
              });
            }}
            color="primary"
          >
            {employee.archived ? (
              <UnarchiveOutlinedIcon fontSize="small" />
            ) : (
              <ArchiveOutlinedIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteEmployee')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteEmployee({
                      type: 'users',
                      genericId: employee.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteEmployee'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
