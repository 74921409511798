import {
  Box,
  Modal,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Button,
  CircularProgress
} from '@mui/material';

import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAddStudentModal } from '../hooks/use-add-student-modal';
import { useTranslation } from 'react-i18next';
import TableContextProvider from 'src/shared/provider/table-context-provider';

const style: any = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 400,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3
};

export const AddStudentModal = () => {
  const { t } = useTranslation();
  const { formik, studentsData, isAddingStudent } = useAddStudentModal();
  const [openModal, setOpenModal] = useState(true);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate(-1);
  };

  return (
    <TableContextProvider>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        sx={{ zIndex: 999 }}
      >
        <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ py: 2, fontSize: '18px', textAlign: 'center' }}
          >
            {t('messages.addStudentToGroup')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={studentsData?.data || []}
                disablePortal
                multiple
                onChange={(_e, newValue: any) => {
                  formik.setFieldValue(
                    'ids',
                    newValue ? newValue.map((item) => item.id) : ''
                  );
                }}
                getOptionLabel={(option) => {
                  return `${option.fullName} (${option.code})`;
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    label={t('inputs.label.student')}
                    error={formik.touched.ids && Boolean(formik.errors.ids)}
                  />
                )}
              />
              {!!formik.errors.ids && formik.touched.ids && (
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: '#FF1943'
                  }}
                >
                  {formik.errors.ids}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ width: '100%' }}
                disabled={isAddingStudent}
              >
                {isAddingStudent && (
                  <CircularProgress size={18} sx={{ marginRight: 0.5 }} />
                )}
                {t('buttons.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </TableContextProvider>
  );
};
