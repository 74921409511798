import { Outlet } from 'react-router';
import { RoomsContainer } from '../containers/rooms-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Rooms() {
  return (
    <TableContextProvider>
      <RoomsContainer />
      <Outlet />
    </TableContextProvider>
  );
}
