import { muiDateToString } from 'src/shared/utils/helpers';
import {
  IEmployee,
  IEmployeeAPI,
  TEmployeesRoot,
  TEmployeesRootAPI
} from '../type';

export function employeeMapper(value: IEmployeeAPI): IEmployee {
  const employee = {
    id: value.id,
    code: value.code,
    fullName: value.fullName,
    email: value.email,
    name: value.name,
    archived: value.archived
  };

  return employee;
}

export function employeeFormMapper(values: any, employeeId = null): any {
  const isTeacher = values?.roles.length
    ? values?.roles?.map((item) => item.id).includes('teacher')
    : values?.roles?.id === 'teacher';
  const employeeObj = {
    name: values?.name,
    surname: values?.surname,
    patronymic: values?.patronymic,
    description: values?.description,
    phoneNumbers: values?.isForeign
      ? [values?.phoneNumbers].flat()
      : [`994${values?.phoneNumbers}`].flat(),
    email: values?.email,
    dateOfBirth: muiDateToString(values?.dateOfBirth),
    isForeign: values?.isForeign,
    gender: values?.gender,
    positions: values?.positions?.map((role) => role.id),
    registeredBranches: [values.registeredBranches]
  };

  const employee = isTeacher
    ? {
        ...employeeObj,
        subjects: values?.subjects?.map((subject) => subject.id)
      }
    : { ...employeeObj };

  if (employeeId) {
    return {
      ...employee,
      roles: [...values.roles?.map((item) => item?.id), 'employee']
    };
  }
  return employee;
}

export const detailsEmployeeMapper = function (data: any): any {
  const employee = {
    id: data?.id,
    code: data?.code,
    name: data?.name,
    surname: data?.surname,
    patronymic: data?.patronymic,
    description: data?.description,
    phoneNumbers: data?.phoneNumbers,
    email: data?.email,
    dateOfBirth: data?.dateOfBirth,
    fullName: data?.fullName,
    subjects: data?.subjects,
    roles: data?.roles,
    gender: data?.gender,
    positions: data?.positions,
    registeredBranches: data?.registeredBranches?.[0]
  };
  return employee;
};

export const employeesMapper = function (
  data: TEmployeesRootAPI
): TEmployeesRoot {
  const employees = data?.data?.map((employee) => employeeMapper(employee));
  const total = data?.total;
  return { data: employees, total: total };
};
