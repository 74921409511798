import { Maintenance } from '../pages/maintenance';

const maintenanceRouter = {
  path: 'maintenance',
  children: [
    {
      path: '',
      element: <Maintenance />,
      children: []
    }
  ]
};

export default maintenanceRouter;
