import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePackage } from '../hooks/use-package';
import { PackageDetails } from '../components/package-details';

export const PackageDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = usePackage();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('packages.name'),
      href: '/dashboard/packages'
    },
    {
      label: t('packages.package'),
      href: '/dashboard/package'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('packages.package')}: ${data?.name ? data?.name : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <PackageDetails data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
