import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import { queryError } from 'src/shared/utils/query-error';

import { getGenericServiceById } from 'src/shared/services/generic-services';
import { usePermissions } from './use-permissions';
import { updateUserPermissions } from '../services';
import { useMutation, useQuery } from 'react-query';

export const useUpdateUserPermissions = () => {
  const { handleMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const { employeeId } = useParams();
  const { data: userData } = useQuery(
    ['users', employeeId],
    getGenericServiceById,
    {
      select: ({ data }) => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError
    }
  );
  const permissions =
    userData?.permissions.map((permission) => permission) || [];
  const { data } = usePermissions();

  const { mutate: updateUserPermissionsMutate, isLoading } = useMutation(
    'update-user-permissions',
    updateUserPermissions,
    {
      onSuccess: (e: any) => {
        if (e && e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e?.message || 'Unknown error', 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      permissions
    },
    onSubmit: (values) => {
      updateUserPermissionsMutate({ employeeId, data: values });
    },
    enableReinitialize: true
  });

  return {
    data,
    userData,
    formik,
    isLoading
  };
};
