import { Outlet } from 'react-router';
import TableContextProvider from 'src/shared/provider/table-context-provider';
import { PackageContainer } from '../containers/package-container';

export function Package() {
  return (
    <TableContextProvider>
      <PackageContainer />
      <Outlet />
    </TableContextProvider>
  );
}
