import { Outlet } from 'react-router';
import { LessonContainer } from '../containers/lesson-container';

export const AddLesson = function () {
  return (
    <>
      <LessonContainer />
      <Outlet />
    </>
  );
};
