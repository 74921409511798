import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUser } from '../hooks/use-user';
import { UserDetails } from '../components/user-details';

export const UserDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useUser();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('users.name'),
      href: '/dashboard/users'
    },
    {
      label: t('users.user'),
      href: '/dashboard/user'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('users.user')}: ${data?.code ? data?.code : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <UserDetails data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
