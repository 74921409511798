import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { AlertContext } from 'src/shared/contexts/Alert';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { useRooms } from 'src/modules/rooms/hooks/use-rooms';
import { useStudents } from 'src/modules/students/hooks/use-students';
import { groupMapper } from '../mappers';
import { useGroup } from './use-group';
import useAuth from 'src/shared/hooks/useAuth';
import { useTeachers } from 'src/modules/employees/hooks/use-teachers';
import { useTranslation } from 'react-i18next';

export function useGroupForm() {
  const { t } = useTranslation();
  const { groupId } = useParams();

  const navigate = useNavigate();

  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();
  const { data } = useGroup();
  const { user } = useAuth();

  const { data: roomsData } = useRooms(false);
  const { data: studentsData } = useStudents(false);
  const { data: teachersData } = useTeachers(false);

  const isSingleBranch =
    user?.branches?.length <= 1
      ? user.branches?.map((branch) => ({
          id: branch.id,
          name: branch.name
        }))[0]
      : '';

  const { mutate: addAndEditGroupMutate, isLoading: isSubmitting } =
    useMutation('group-from', addAndUpdateGenericService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('groups-get-all');
          if (!groupId) navigate('/dashboard/groups');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.required.name')),
    branch: Yup.object().required(t('yup.required.branch')),
    room: Yup.string().required(t('yup.required.room'))
    // isOnline: Yup.boolean(),
    // program: Yup.string().required('program is required'),
    // teacherId: Yup.string().required('teacher is required'),
    // lessonsStarts: Yup.string().required('lesson start is required'),
    // lessonsEnds: Yup.string().required('lesson end is required'),
    // groupType: Yup.string().required('group type is required'),
    // lessonType: Yup.string().required('lesson type is required'),
    // room: Yup.string().when('isOnline', {
    //   is: false,
    //   then: Yup.string().required(),
    //   otherwise: Yup.string()
    // }),
    // status: Yup.string().required('status is required'),
    // startDate: Yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      branch: data?.branch || isSingleBranch,
      name: data?.name || '',
      room: data?.room?.id || '',
      students: data?.students || [],
      teachers: data?.teachers || [],
      weekdays: data?.weekdays.join('') || '135'
    },
    onSubmit: (values) => {
      const mappedGroup = groupMapper(values);
      if (values) {
        if (groupId) {
          addAndEditGroupMutate({
            data: mappedGroup,
            type: 'groups',
            genericId: groupId
          });
        } else {
          addAndEditGroupMutate({ data: mappedGroup, type: 'groups' });
        }
      }
    },
    validationSchema,
    enableReinitialize: true
  });

  return {
    data,
    formik,
    isSubmitting,
    roomsData,
    studentsData,
    teachersData,
    groupId,
    user
  };
}
