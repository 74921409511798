const ruJSON = {
  languageSwitcher: {
    title: 'язык',
    switchLanguage: 'сменить язык'
  },
  login: { title: 'Вход', ourTeam: 'Наша kоманда' },
  signin: {
    title: 'Войти',
    label: 'Заполните поля ниже, чтобы войти в свой аккаунт.'
  },
  popover: {
    profile: 'Профиль',
    signOut: 'Выйти'
  },
  general: {
    name: 'Общий'
  },
  managment: {
    title: 'Управление'
  },
  appManager: {
    title: 'Менеджер приложения'
  },
  dashboard: {
    name: 'Панель управления'
  },
  teachers: {
    name: 'Учителя'
  },
  transactions: {
    name: 'Транзакции',
    addTransaction: 'Добавить доход',
    addExpense: 'Добавить расход',
    transaction: 'Транзакция'
  },
  home: {
    name: 'Главная'
  },
  branches: {
    name: 'Филиалы',
    addBranch: 'Добавить филиал',
    editBranch: 'Редактировать филиал',
    branch: 'Филиал'
  },
  parents: {
    name: 'Родители',
    addParent: 'Добавить родителя'
  },
  users: {
    name: 'Пользователи',
    addUser: 'Добавить пользователя',
    user: 'Пользователь',
    editUser: 'Редактировать пользователя'
  },
  guardians: {
    name: 'Родители',
    addGuardian: 'Добавить родителя',
    guardian: 'Родитель',
    editGuardian: 'Редактировать родителя'
  },
  students: {
    name: 'Ученики',
    addStudent: 'Добавить ученика',
    student: 'Ученика',
    editStudent: 'Редактировать'
  },
  groups: {
    name: 'Группы',
    addGroup: 'Добавить группу',
    group: 'Группа',
    editGroup: 'Редактировать группу'
  },
  employees: {
    name: 'Сотрудники',
    addEmployee: 'Добавить сотрудника',
    employee: 'Сотрудник',
    editEmployee: 'Редактировать сотрудника'
  },
  managePermissions: {
    name: 'Управление разрешениями'
  },
  lessons: {
    name: 'Уроки',
    addLesson: 'Добавить урок',
    editLesson: 'Редактировать урок',
    lesson: 'Урок'
  },
  exams: {
    name: 'Экзамены',
    addExam: 'Добавить экзамен',
    exam: 'Экзамен',
    editExam: 'Редактировать экзамен'
  },
  feedbacks: {
    name: 'Отзывы',
    addFeedback: 'Добавить отзыв',
    feedback: 'Отзыв',
    editFeedback: 'Редактировать отзыв'
  },
  recurrences: {
    name: 'Повторения',
    addRecurrence: 'Добавить повторение',
    recurrence: 'Повторение',
    editRecurrence: 'Редактировать повторение'
  },
  subjects: {
    name: 'Предметы',
    addSubject: 'Добавить предмет',
    subject: 'Предмет',
    editSubject: 'Редактировать предмет',
    inputName: 'Название'
  },
  packages: {
    name: 'Пакеты',
    addPackage: 'Добавить пакет',
    package: 'Пакет',
    editPackage: 'Редактировать пакет'
  },
  rooms: {
    name: 'Комнаты',
    addRoom: 'Добавить комнату',
    room: 'Комната',
    editRoom: 'Редактировать комнату'
  },
  upperCategories: {
    name: 'Верхние категории',
    addUpperCategory: 'Добавить верхнюю категорию',
    upperCategory: 'Верхняя категория',
    editUpperCategory: 'Редактировать верхнюю категорию'
  },
  lowerCategories: {
    name: 'Нижние категории',
    addLowerCategory: 'Добавить нижнюю категорию',
    lowerCategory: 'Нижняя категория',
    editLowerCategory: 'Редактировать нижнюю категорию'
  },
  maintenance: {
    name: 'Техническое обслуживание'
  },
  addTransaction: {
    name: 'Добавить доход'
  },
  table: {
    fullName: 'Полное имя',
    rowsOrder: '№',
    code: 'Код',
    email: 'Электронная почта',
    actions: 'Действия',
    date: 'Дата',
    name: 'Имя',
    time: 'Время',
    isTought: 'Преподаватель',
    price: 'Цена',
    subjects: 'Предметы',
    branch: 'Филиал',
    number: 'Номер',
    size: 'Размер',
    category: 'Категория',
    paymentMethod: 'Способ оплаты',
    teacherCode: 'Код преподавателя',
    title: 'Название',
    amount: 'Сумма',
    schoolAmount: 'Сумма для школы',
    executor: 'Исполнитель',
    company: 'Компания',
    address: 'Адрес',
    manager: 'Менеджер',
    students: 'Студенты',
    room: 'Комната',
    slug: 'Слаг',
    teacher: 'Преподаватели',
    group: 'Группа',
    topic: 'Тема',
    content: 'Содержание',
    type: 'Тип',
    status: 'Статус',
    executed: 'Выполнено',
    guardian: 'Родитель',
    balanceBefore: 'Баланс до',
    balanceAfter: 'Баланс после',
    notExecutedYet: 'Еще не выполнено',
    subject: 'Предмет'
  },
  inputs: {
    label: {
      price: 'Цена',
      filter: 'Фильтр',
      name: 'Имя',
      surname: 'Фамилия',
      number: 'Номер',
      email: 'Электронная почта',
      gender: 'Пол',
      fatherName: 'Имя отчество',
      description: 'Описание',
      note: 'Заметка',
      phoneNumber: 'Номер телефона',
      dateOfBirth: 'Дата рождения',
      roles: 'Роли',
      role: 'Роль',
      marks: 'Оценки',
      password: 'Пароль',
      lostPassword: 'Забытый пароль',
      passwordConfirm: 'Подтверждение пароля',
      repeatPassword: 'Не совпадает с повторным вводом пароля',
      matchPassword: 'Не совпадает с новым паролем',
      socialMediaProfiles: 'Профили в социальных сетях',
      groupDP: 'Группа специальности',
      schoolAdmittionYear: 'Год поступления в школу',
      guardian: 'Родитель',
      relationship: 'Отношения',
      rooms: 'Пакеты',
      subject: 'Предмет',
      date: 'Дата',
      amount: 'Сумма',
      company: 'Компания',
      address: 'Адрес',
      manager: 'Менеджер',
      time: 'Время',
      size: 'Размер',
      search: 'Поиск',
      balance: 'Баланс',
      dateTime: 'Дата и время',
      isTought: 'Преподаватель',
      category: 'Категория',
      managers: 'Менеджеры',
      branch: 'Филиал',
      chiefManager: 'Главный менеджер',
      code: 'Код',
      fullName: 'Полное имя',
      status: 'Статус',
      package: 'Пакет',
      title: 'Название',
      priority: 'Приоритет',
      upperCategory: 'Верхняя категория',
      relatedTo: 'Связано с',
      cash: 'Наличные',
      online: 'Онлайн',
      paymentMethod: 'Способ оплаты',
      newPaymentMethod: 'Новый способ оплаты',
      realDate: 'Реальная дата',
      hiddenTransaction: 'Скрытая транзакция',
      newRealDate: 'Новая реальная дата',
      differentTime: 'Зарегистрировать оплату за другое время',
      teacher: 'Преподаватель',
      group: 'Группа',
      isForeign: 'Ин.ном.',
      packages: 'Пакеты',
      topic: 'Тема',
      room: 'Комната',
      isExtra: 'Дополнительный урок',
      groupCode: 'Код группы',
      content: 'Содержание',
      lesson: 'Урок',
      student: 'Ученика',
      weekdays: 'Дни недели',
      students: 'Ученики',
      createdAt: 'Создано в',
      periodicity: 'Периодичность',
      remindDuration: 'Продолжительность напоминания',
      dueDate: 'Срок',
      isIncome: 'Доход',
      subjects: 'Предметы',
      type: 'Тип',
      income: 'Доход',
      expense: 'Расход',
      recurrenceType: 'Выберите тип повторения',
      notDeletable: 'Нельзя удалить',
      createdBy: 'Создано',
      transactionType: 'Тип транзакции',
      balanceBefore: 'Баланс до',
      balanceAfter: 'Баланс после',
      patronymic: 'Отчество',
      rows: 'Строк на странице',
      teachers: 'Преподаватели',
      male: 'Мужчина',
      female: 'Женщина',
      unknown: 'Неизвестно',
      facebook: 'Фейсбук',
      instagram: 'Инстаграм',
      linkedin: 'Линкедин',
      zoom: 'Зум',
      facebookUrl: 'Ссылка на аккаунт в Facebook',
      instagramUrl: 'Ссылка на аккаунт в Instagram',
      linkedinUrl: 'Ссылка на аккаунт в LinkedIn',
      zoomUrl: 'Ссылка на аккаунт в Zoom',
      profilImage: 'Изображение профиля',
      changeProfilImage: 'Изменить изображение профиля',
      upload: 'Загрузить',
      currentPassword: 'Текущий пароль',
      passwordConfrim: 'Подтвердите пароль',
      myAccount: 'Моя учетная запись',
      aboutMe: 'Обо мне',
      changePassword: 'Изменить пароль',
      positions: 'Позиции',
      searchByCode: 'Поиск по коду',
      searchByName: 'Поиск по имени',
      searchByCodeAndTopic: 'Поиск по коду и теме',
      searchTransaction: 'Поиск по названию, категории, коду',
      recoverPassword: 'Bосстановить Пароль',
      emailForRecoverPassword:
        'Введите электронную почту, использованную при регистрации, чтобы сбросить пароль.',
      notExtra: 'Не дополнительный урок',
      searchLesson: 'Поиск по коду и теме',
      newAmount: 'Новая сумма',
      executeRecurrenceRegister: 'Зарегистрировать платеж для другого времени',
      executeRecurrenceLabel:
        'Если вы хотите изменить дату или сумму, используйте поля ниже. В противном случае игнорируйте.',
      archivedUsers: 'Архивированные пользователи',
      archivedGuardians: 'Архивированные родителя',
      archivedStudents: 'Архивированные студенты',
      archivedEmployees: 'Архивированные сотрудники',
      archivedGroups: 'Архивированные группы'
    },
    options: {
      admin: 'Администратор',
      student: 'Ученик',
      guardian: 'Родитель',
      father: 'Отчество',
      mother: 'Мать',
      male: 'Мужской',
      unknown: 'Неизвестно',
      female: 'Женский'
    }
  },
  yup: {
    required: {
      title: 'Требуется указать заголовок',
      surname: 'Фамилия обязательна',
      guardian: 'Родитель обязателен',
      number: 'Номер обязателен',
      amount: 'Требуется указать сумму',
      student: 'Требуется указать ученика',
      category: 'Категория обязательна',
      price: 'Цена обязательна',
      lesson: 'Требуется указать урок',
      group: 'Группа обязательна',
      teacher: 'Преподаватель обязателен',
      subject: 'Предмет обязателен',
      name: 'Требуется указать имя',
      email: 'Требуется указать адрес электронной почты',
      roles: 'Требуется указать роли',
      gender: 'Требуется указать пол',
      branch: 'Требуется указать отделение',
      address: 'Адрес обязателен',
      room: 'Требуется указать комнату',
      manager: 'Необходимо указать менеджера',
      relationship: 'Отношения обязательны',
      password: 'Пароль обязателен',
      repeatPassword: 'Повторите пароль обязателен',
      minPassword: 'Пароль должен содержать минимум 6 символов',
      matchPassword: 'Пароли должны совпадать',
      relatedToWhenCategoryIs:
        'Требуется указать связанное, когда указана категория',
      relatedTo: 'Требуется указать связанное',
      salary: 'Зарплата',
      validEmail:
        'Указанный адрес электронной почты должен быть действительным',
      transactionStudent: 'Ученик',
      schooladmissionyear: 'Год поступления в школу обязателен'
    },
    messages: {
      email: 'Указанный адрес электронной почты должен быть действительным',
      terms: 'Вы должны согласиться с нашими условиями и положениями',
      invalidCredentials: 'Неверные учетные данные, пожалуйста, войдите снова'
    }
  },
  buttons: {
    addParent: 'Добавить родителя',
    addGuardian: 'Добавить родителя',
    confirm: 'Подтвердить',
    cancel: 'Отмена',
    addStudent: 'Добавить ученика',
    addEmployee: 'Добавить сотрудника',
    addLesson: 'Добавить урок',
    addExam: 'Добавить экзамен',
    addSubject: 'Добавить предмет',
    addPackage: 'Добавить пакет',
    addBranch: 'Добавить филиал',
    editBranch: 'Редактировать филиал',
    addRoom: 'Добавить комнату',
    addTransaction: 'Добавить доход',
    addExpense: 'Добавить расход',
    add: 'Добавить',
    yes: 'Да',
    no: 'Нет',
    all: 'Все',
    edit: 'Редактировать',
    addGroup: 'Добавить группу',
    addLowerCategory: 'Добавить нижнюю категорию',
    editLesson: 'Редактировать урок',
    addUpperCategory: 'Добавить верхнюю категорию',
    addFeedback: 'Добавить отзыв',
    addRecurrence: 'Добавить повторение',
    editGroup: 'Редактировать группу',
    addTeacher: 'Добавить преподавателя',
    editRecurrence: 'Редактировать повторение',
    execute: 'Выполнить',
    managePermissions: 'Управление разрешениями',
    editRoom: 'Редактировать комнату',
    update: 'Обновить',
    submit: 'Отправить',
    sendMe: 'Отправить мне новый пароль',
    clickHere: 'Нажмите здесь',
    continueToLogin: 'Продолжить вход',
    addUser: 'Добавить пользователя'
  },
  alerts: {
    passwordReset:
      'Инструкции по сбросу пароля отправлены на вашу электронную почту'
  },
  tooltips: {
    view: 'Просмотр',
    edit: 'Редактировать',
    remove: 'Удалить',
    archiveUser: 'Aрхивировать  пользователя',

    archiveGuardian: 'Архивировать родителя',

    archiveStudent: 'Архивировать ученика',

    archiveLesson: 'Архивировать урок',
    archiveEmployee: 'Архивировать сотрудника',

    archiveExam: 'Архивировать экзамен',
    archiveSubject: 'Архивировать предмет',
    archiveRoom: 'Архивировать комнату',
    archiveBranch: 'Архивировать филиал',
    archiveGroup: 'Архивировать группу',
    unArchiveUser: 'Восстановить пользователя',
    unArchiveGuardian: 'Восстановить родителя',
    unArchiveStudent: 'Восстановить ученика',
    unArchiveEmployee: 'Восстановить сотрудника',
    unArchiveGroup: 'Восстановить группу',
    archiveUpperCategory: 'Архивировать верхнюю категорию',
    archiveLowerCategory: 'Архивировать нижнюю категорию',
    deleteGroup: 'Удалить группу',
    deleteExam: 'Удалить экзамен',
    deleteLesson: 'Удалить урок',
    deleteBranch: 'Удалить филиал',
    deleteSubject: 'Удалить предмет',
    deletePackage: 'Удалить пакет',
    deleteRoom: 'Удалить комнату',
    deletUpperCategory: 'Удалить верхнюю категорию',
    deleteLowerCategory: 'Удалить нижнюю категорию',
    deleteTransaction: 'Удалить транзакцию',
    deleteStudentFromGroup: 'Удалить ученика из группы',
    deleteTeacher: 'Удалить учителя',
    deleteGuardian: 'Вы уверены, что хотите удалить этого родителя',
    deleteStudent: 'Удалить студента',
    deleteEmployee: 'Вы уверены, что хотите удалить этого сотрудника',
    deleteRecurrence: 'Вы уверены, что хотите удалить это повторение',
    languageSwitcher: 'Переключатель языка',
    deleteUser: 'Удалить пользователя'
  },
  typography: {
    amount:
      'Если вы хотите изменить дату или сумму, используйте поля ввода ниже. В противном случае проигнорируйте.',
    email:
      'Проверьте свою электронную почту для получения дополнительных инструкций',
    currentMaintenance:
      'Сайт в настоящее время находится на техническом обслуживании',
    appologizeMaintenance: 'Приносим извинения за любые вызванные неудобства',
    present: 'Присутствует',
    absent: 'Отсутствует'
  },
  messages: {
    successfulProcess: 'Процесс успешно завершен',
    unSuccessfulProcess: 'Процесс не удался',
    unknownError: 'Неизвестная ошибка',
    success: 'Успех',
    archiveUser: 'Вы уверены, что хотите архивировать этого пользователя?',
    unArchiveUser: 'Вы уверены, что хотите восстановить этого пользователя?',
    unArchiveGuardian: 'Вы уверены, что хотите восстановить этого опекуна?',
    unArchiveStudent: 'Вы уверены, что хотите восстановить этого студента?',
    unArchiveEmployee: 'Вы уверены, что хотите восстановить этого сотрудника?',
    unArchiveGroup: 'Вы уверены, что хотите восстановить эту группу?',
    archiveGuardian: 'Вы уверены, что хотите архивировать данного родитель?',
    archiveStudent: 'Вы уверены, что хотите архивировать данного ученика?',
    archiveEmployee: 'Вы уверены, что хотите архивировать данного сотрудника?',
    archiveExam: 'Вы уверены, что хотите архивировать данный экзамен?',
    archiveSubject: 'Вы уверены, что хотите архивировать данную дисциплину?',
    archiveRoom: 'Архивировать комнату?',
    archiveBranch: 'Вы уверены, что хотите архивировать данный филиал?',
    archiveLowerCategory:
      'Вы уверены, что хотите архивировать данную нижнюю категорию?',
    archiveUpperCategory:
      'Вы уверены, что хотите архивировать данную верхнюю категорию?',
    archiveGroup: 'Вы уверены, что хотите архивировать  группу?',
    deleteGroup: 'Вы уверены, что хотите удалить данную группу?',
    notFound: 'Данные не найдены!',
    deleteExam: 'Вы уверены, что хотите удалить данный экзамен?',
    deleteUser: 'Вы уверены, что хотите удалить этого пользователя?',
    deleteLesson: 'Вы уверены, что хотите удалить данное занятие?',
    deleteBranch: 'Вы уверены, что хотите удалить данный филиал?',
    deleteSubject: 'Вы уверены, что хотите удалить данную дисциплину?',
    deletePackage: 'Вы уверены, что хотите удалить данный пакет?',
    deleteRoom: 'Вы уверены, что хотите удалить данную комнату?',
    deletUpperCategory:
      'Вы уверены, что хотите удалить данную верхнюю категорию?',
    deleteLowerCategory:
      'Вы уверены, что хотите удалить данную нижнюю категорию?',
    deleteTransaction: 'Вы уверены, что хотите удалить данную транзакцию?',
    deleteStudentFromGroup:
      'Вы уверены, что хотите удалить данного ученика из группы?',
    deleteTeacherFromGroup:
      'Вы уверены, что хотите удалить данного преподавател из группы?',
    deleteGuardian: 'Вы уверены, что хотите удалить данного родитель?',
    deleteStudent: 'Вы уверены, что хотите удалить данного ученика?',
    deleteEmployee: 'Вы уверены, что хотите удалить данного сотрудника?',
    deleteRecurrence: 'Вы уверены, что хотите удалить данное повторение?',
    wantToSignin: 'Хотите попробовать войти снова?',
    addStudentToGroup: 'Добавить студента в группу',
    addTeacherToGroup: 'Добавить преподавателя в группу'
  },

  modal: {
    title: {
      permissions: 'Управление разрешениями'
    },
    subtitle: {
      permissions:
        'Измените следующие переключатели, чтобы активировать или деактивировать разрешения'
    }
  },

  selectInput: {
    active: 'Aктивный',
    archived: 'Aрхивированный'
  }
};

export default ruJSON;
