import { AxiosResponse } from 'axios';

import getAuthToken from 'src/shared/utils/getAuthToken';
import axios from 'src/shared/utils/axios';

export const executeRecurrenceService = async ({
  recurrenceId,
  data
}): Promise<AxiosResponse> => {
  try {
    return await axios.post(
      `/api/v1/recurrences/${recurrenceId}/execute`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      }
    );
  } catch (e) {
    return e;
  }
};
