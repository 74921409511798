import { useContext, useState } from 'react';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Link } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLessons } from '../hooks/use-lessons';
import { useLessonsTable } from '../hooks/use-lessons-table';
import { TableContext } from 'src/shared/contexts/table-context';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import LessonSearch from '../components/lesson-search';

export const LessonsContainer = () => {
  const { t } = useTranslation();
  const { data } = useLessons();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { page, limit, setPage, setLimit } = useContext(TableContext);

  const [columns, options, routes] = useLessonsTable();
  const handleFilter = () => {
    setIsOpen((s) => !s);
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('lessons.name')}
      // action={
      //   <Link
      //     to="/dashboard/lessons/add-lesson"
      //     style={{ textDecoration: 'none' }}
      //   >
      //     <Button variant="contained">{t('buttons.addLesson')}</Button>
      //   </Link>
      // }
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px'
        }}
      >
        <Box
          onClick={handleFilter}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>{t('inputs.label.filter')}</Typography>
          {!isOpen ? (
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />
          )}
        </Box>
        {isOpen && <LessonSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data?.data}
          rowsCount={data?.total}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
};
