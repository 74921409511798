import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  styled,
  TextField,
  Typography
} from '@mui/material';
import * as Yup from 'yup';
import { ReactElement, Ref, forwardRef, useContext } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { useFormik } from 'formik';
import { OmInput } from 'src/shared/components';
import { OmCheckbox } from 'src/shared/components';
import moment from 'moment';
import { DATE_TYPES } from 'src/shared/costants/constants';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { executeRecurrenceService } from '../services';
import { LoadingButton } from '@mui/lab';

const DialogWrapper = styled(Dialog)(
  () => `
            .MuiDialog-paper {
              overflow: visible;
            }
      `
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
  // amount: Yup.string().required('amount is a required field')
});

export const ExecuteRecurrenceModal = ({ open, handleClose, id }) => {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: executeRecurrence, isLoading: isExecutingRecurrence } =
    useMutation('execute-recurrence', executeRecurrenceService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('recurrences-get-all');
          navigate('/dashboard/recurrences');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });
  const formik = useFormik({
    initialValues: {
      newAmount: 250,
      newRealDate: '12/12/2023',
      newPaymentMethod: 'cash',
      isGivenDate: false
    },
    onSubmit: (values) => {
      const recurrenceMapper = function (values) {
        const recurrence = {
          newAmount: values.newAmount,
          newRealDate: values.newRealDate,
          newPaymentMethod: values.newPaymentMethod
        };
        return recurrence;
      };
      const mappedRecurrence = recurrenceMapper(values);
      executeRecurrence({
        recurrenceId: id,
        data: mappedRecurrence
      });
    },
    validationSchema,
    enableReinitialize: true
  });
  const handleDate = (newValue: any) => {
    formik.setFieldValue(
      'newRealDate',
      moment(newValue).format(DATE_TYPES.mdyt)
    );
  };

  return (
    <DialogWrapper
      open={open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={3}
      >
        <Typography
          align="center"
          sx={{
            py: 2,
            px: 6
          }}
          variant="h4"
        >
          {t('inputs.label.executeRecurrenceLabel')}
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={3}
          sx={{
            p: 1
          }}
        >
          <Grid item xs={12}>
            <OmInput
              required
              formik={formik}
              type="number"
              label={t('inputs.label.newAmount')}
              name="newAmount"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              error={
                formik.touched.newPaymentMethod &&
                Boolean(formik.errors.newPaymentMethod)
              }
            >
              <FormLabel component="legend">
                {t('inputs.label.newPaymentMethod')}
              </FormLabel>
              <RadioGroup
                row
                aria-label="newPaymentMethod"
                name="newPaymentMethod"
                value={formik.values.newPaymentMethod}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="cash"
                  control={<Radio />}
                  label={`${t('inputs.label.cash')}`}
                />
                <FormControlLabel
                  value="online"
                  control={<Radio />}
                  label={`${t('inputs.label.online')}`}
                />
              </RadioGroup>
              {formik.touched.newPaymentMethod &&
                Boolean(formik.errors.newPaymentMethod) && (
                  <FormHelperText sx={{ ml: 0 }}>
                    {formik.errors.newPaymentMethod}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <OmCheckbox
              formik={formik}
              label={t('inputs.label.executeRecurrenceRegister')}
              name="isGivenDate"
            />
          </Grid>

          {formik.values.isGivenDate && (
            <Grid item xs={12}>
              <DateTimePicker
                value={formik.values.newRealDate}
                onChange={(date) => handleDate(date)}
                label={t('inputs.label.newRealDate')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="none"
                    variant="outlined"
                    fullWidth
                    name="newRealDate"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={handleClose}
          >
            {t('buttons.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            loading={isExecutingRecurrence}
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
            color="primary"
          >
            {t('buttons.execute')}
          </LoadingButton>
        </Box>
      </Box>
    </DialogWrapper>
  );
};
