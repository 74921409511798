import { useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { addAndExpenseTransactionMapper } from '../mappers';
import { useStudents } from 'src/modules/students/hooks/use-students';
import { useEmployees } from 'src/modules/employees/hooks/use-employees';
import useAuth from 'src/shared/hooks/useAuth';
import { useLowerCategoriesByUpperSlug } from 'src/modules/lower-categories/hooks/use-lower-categories-by-upper-slug';

export const useTransactionForm = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const transactionType =
    (location.pathname.includes('add-expense') && 'expense') ||
    (location.pathname.includes('add-transaction') && 'income');

  const { data: incomesUpperCategories } =
    useLowerCategoriesByUpperSlug('incomes');
  const { data: expensesUpperCategories } =
    useLowerCategoriesByUpperSlug('expenses');
  const { data: studentsData } = useStudents(false);
  const { data: employeesData } = useEmployees(false);
  const { handleMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const { user } = useAuth();

  const student = incomesUpperCategories?.data?.filter(
    (category) => category?.slug === 'student'
  )[0];
  const defaultCategory =
    transactionType === 'income' ? { ...student, id: student?._id } : '';

  const isSingleBranch =
    user?.branches?.length <= 1
      ? user.branches?.map((branch) => ({
          id: branch.id,
          name: branch.name
        }))[0]
      : '';

  const { mutate: addTransaction, isLoading: isSubmitting } = useMutation(
    'add-transaction',
    addAndUpdateGenericService,
    {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          formik.resetForm();
          navigate('/dashboard/transactions');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    }
  );

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required(t('yup.required.amount')),
    branch: Yup.object().required(t('yup.required.branch')),
    category: Yup.object().required(t('yup.required.category')),
    relatedTo: Yup.string().when('category.slug', {
      is: (value) =>
        transactionType === 'income' ? value === 'student' : value === 'salary',
      then: Yup.string()
        .required(
          `${t('yup.required.relatedToWhenCategoryIs')}  ${
            transactionType === 'income'
              ? t('yup.required.transactionStudent')
              : t('yup.required.salary')
          }`
        )
        .transform((value) => value.fullName),
      otherwise: Yup.string().transform((value) => value.id)
    })
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      amount: '',
      category: defaultCategory,
      description: '',
      hidden: false,
      realDate: null,
      paymentMethod: 'cash',
      relatedTo: '',
      branch: isSingleBranch,
      differentTime: false
    },
    onSubmit: (values) => {
      const mappedTransaction = addAndExpenseTransactionMapper(
        values,
        transactionType
      );
      addTransaction({ data: mappedTransaction, type: 'transactions' });
    },
    validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isSubmitting,
    incomesUpperCategories,
    expensesUpperCategories,
    studentsData,
    employeesData,
    transactionType,
    user
  };
};
