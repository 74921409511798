// @ts-nocheck
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export interface FormInputProps {
  name: string;
  control: any;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  setValue?: any;
  options?: any;
  type?: string;
  onWheel?: any;
  rows?: number;
}

export const FormInputText = ({
  name,
  control,
  label,
  required = false,
  type = 'text',
  disabled = false,
  onWheel = (e: any) => e.target.blur(),
  rows,
  autoFocus
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={(state) => {
        const {
          field: { onChange, onBlur, value },

          fieldState
        } = state;
        return (
          <TextField
            onBlur={onBlur}
            disabled={disabled}
            helperText={fieldState.error ? fieldState.error.message : null}
            size="small"
            required={required}
            error={!!fieldState.error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant="outlined"
            onWheel={onWheel}
            autoFocus={autoFocus}
            type={type}
            rows={rows}
            sx={{
              '& input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none'
              },
              ...(disabled && {
                '& .MuiInputBase-root': {
                  backgroundColor: 'rgba(34, 51, 84, 0.1)' // Change this to your desired color
                }
              })
            }}
          />
        );
      }}
    />
  );
};
