import { Subject } from '../pages/subject';
import { SubjectDetails } from '../pages/subject-details';
import { Subjects } from '../pages/subjects';

const subjectsRouter = {
  path: 'subjects',
  children: [
    {
      path: '',
      element: <Subjects />,
      children: [
        {
          path: 'add',
          element: <Subject />,
          children: []
        },
        {
          path: 'edit/:id',
          element: <Subject />
        }
      ]
    },

    {
      path: ':id',
      element: <SubjectDetails />
    }
  ]
};

export default subjectsRouter;
