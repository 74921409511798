import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { deleteGenericFromGroup } from '../services';
import { useTranslation } from 'react-i18next';

export const useDeleteTeacherFromGroup = () => {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: deleteTeacherMutate } = useMutation(
    'delete-teacher',
    deleteGenericFromGroup,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('groups');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    }
  );

  return {
    deleteTeacherMutate
  };
};
