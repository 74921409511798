import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { usersMapper } from '../mappers';
import { getUsers } from 'src/modules/user-services/services';

export const useUsers = function (paginated = true) {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const role = searchParams.get('role');
  const archived = searchParams.get('archived');

  const { page, limit } = useContext(TableContext);
  const { data, isLoading } = useQuery({
    queryKey: ['users-get-all', { page, limit, q, archived, paginated }, role],
    queryFn: getUsers,
    select: ({ data }) => {
      const mappedUsers = usersMapper(data);
      if (data?.status === 'success') {
        return mappedUsers;
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
