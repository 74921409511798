import { useContext } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteStudentFromGroup } from '../hooks/use-delete-student-from-group';

export const GroupStudentsActionButtons = ({ studentId }) => {
  const { t } = useTranslation();

  const { groupId } = useParams();

  const { dispatch } = useContext(ConfirmationModalContext);

  const { deleteStudentMutate } = useDeleteStudentFromGroup();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/students/edit-student/${studentId}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteStudent')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    deleteStudentMutate({
                      groupId,
                      ids: [studentId],
                      type: 'students'
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteStudentFromGroup'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'primary'
                }
              });
            }}
            color="primary"
          >
            <PersonRemoveAlt1Icon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
