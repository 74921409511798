import {
  IUser,
  IUserDetails,
  IUserDetailsAPI,
  TUsersRoot,
  TUsersRootAPI
} from '../type';

export function userMapper(value: IUserDetailsAPI): IUser {
  const user = {
    id: value?.id,
    code: value?.code,
    fullName: value?.fullName,
    email: value?.email,
    name: value?.name,
    roles: value?.roles,
    archived: value?.archived
  };

  return user;
}

export function userFormMapper(values: any): any {
  const user = {
    name: values?.name,
    surname: values?.surname,
    patronymic: values?.patronymic,
    description: values?.description,
    note: values?.note,
    phoneNumbers: values?.isForeign
      ? [values?.phoneNumbers].flat()
      : [`994${values?.phoneNumbers}`].flat(),
    email: values?.email,
    dateOfBirth: values?.dateOfBirth,
    roles: [...values?.roles?.map((item) => item?.id), 'employee'],
    gender: values?.gender
  };

  return user;
}

export const detailsUserMapper = function (
  data: IUserDetailsAPI
): IUserDetails {
  const user = {
    code: data?.code,
    name: data?.name,
    surname: data?.surname,
    patronymic: data?.patronymic,
    description: data?.description,
    phoneNumbers: data?.phoneNumbers,
    email: data?.email,
    dateOfBirth: data?.dateOfBirth,
    fullName: data?.fullName,
    gender: data?.gender,
    roles: data?.roles
  };
  return user;
};

export const usersMapper = function (data: TUsersRootAPI): TUsersRoot {
  const users = data?.data?.map((user) => userMapper(user));
  const total = data?.total;
  return { data: users, total: total };
};
