import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { useSubject } from './use-subject';
import { useForm } from 'react-hook-form';

interface IFormValues {
  name: string;
  isTought?: boolean;
}

export const useSubjectForm = function () {
  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: initialValues } = useSubject();

  const { mutate: executeService, isLoading: isSubmitting } = useMutation(
    'subject',
    addAndUpdateGenericService,
    {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('subjects-get-all');
          if (!id) navigate('/dashboard/subjects');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    }
  );

  const onSubmit = (data: IFormValues) => {
    executeService({
      data: {
        name: data.name,
        isTought: data?.isTought
      },
      type: 'subjects',
      genericId: id
    });
  };
  const schema = Yup.object().shape({
    name: Yup.string().required(t('yup.required.name'))
  });

  const defaultValues: IFormValues = {
    name: '',
    isTought: true
  };

  const methods = useForm<IFormValues>({
    defaultValues,
    values: initialValues
    // resolver: yupResolver(schema)
  });
  const { handleSubmit, control, setValue, watch } = methods;

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    setValue,
    watch,
    initialValues,
    isSubmitting,
    id
  };
};
