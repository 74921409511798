import { Button, Grid } from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import RemoveIcon from '@mui/icons-material/Remove';

export function TransactionsPageActions() {
  const { t } = useTranslation();
  return (
    <Grid container rowSpacing={{ xs: 1 }}>
      <Grid
        item
        sx={{
          mr: { xs: 1 }
        }}
      >
        <Button
          component={Link}
          to="/dashboard/transactions/add-expense"
          variant="contained"
          startIcon={<RemoveIcon fontSize="small" />}
        >
          {t('buttons.addExpense')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          to="/dashboard/transactions/add-transaction"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          add transaction
        </Button>
      </Grid>
    </Grid>
  );
}
