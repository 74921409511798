import { Outlet } from 'react-router';
import { TransactionsContainer } from '../containers/transactions-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Transactions() {
  return (
    <TableContextProvider>
      <TransactionsContainer />
      <Outlet />
    </TableContextProvider>
  );
}
