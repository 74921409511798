import { useTranslation } from 'react-i18next';
import { Button, Card, Grid } from '@mui/material';
import GenericTable from 'src/shared/components/generic-table';
import PageHeaderNew from 'src/shared/components/page-header-new';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Link } from 'react-router-dom';
import { useGroup } from '../hooks/use-group';
import { useGroupTeachersTable } from '../hooks/use-group-teachers-table';

export const TeachersTable = () => {
  const { t } = useTranslation();

  const [columns, options] = useGroupTeachersTable();
  const { data } = useGroup();

  return (
    <Grid item xs={12}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <PageHeaderNew title={t('teachers.name')} />
        </Grid>
        <Grid item>
          <Button
            sx={{
              mr: { xs: 1 }
            }}
            component={Link}
            to={`/dashboard/groups/${data?.id}/add-teacher`}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('buttons.addTeacher')}
          </Button>
        </Grid>
      </Grid>
      <Card>
        <GenericTable
          data={data?.teachers}
          columns={columns}
          options={options}
          loading
        />
      </Card>
    </Grid>
  );
};
