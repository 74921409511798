import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from 'src/shared/components/Logo';
import moment from 'moment';
import { DATE_TYPES } from 'src/shared/costants/constants';
import LoadingCircle from 'src/shared/components/Loading-circle';
import useAuth from 'src/shared/hooks/useAuth';

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`
);

const LogoWrapper = styled(Box)(
  () => `
    width: '52px'
`
);

const TransactionDetails = ({ data }) => {
  const { t }: { t: any } = useTranslation();
  const { can } = useAuth();

  const StyledGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `;

  return (
    <Container maxWidth="lg">
      <Card
        sx={{
          p: 4,
          m: 3
        }}
      >
        {!data ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '70vh'
            }}
          >
            <LoadingCircle />
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h1" gutterBottom>
                  {t('transactions.transaction')}
                </Typography>

                <Typography variant="h3" color="text.secondary">
                  {t('inputs.label.title')}: {data?.title}
                </Typography>

                <Typography variant="h3" color="text.secondary">
                  {t('inputs.label.code')}: #{data?.code}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" sx={{ px: 3 }}>
                <LogoWrapper>
                  <Logo />
                </LogoWrapper>
                <Typography
                  sx={{
                    py: 2
                  }}
                  variant="h4"
                >
                  {t('inputs.label.branch')}: {data?.branch?.name}
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                my: 4
              }}
            />
            <Grid
              container
              spacing={3}
              sx={{
                padding: 'auto 20px'
              }}
            >
              <StyledGrid item xs={12} sm={6}>
                <Typography variant="h5" fontWeight="normal">
                  {t('inputs.label.relatedTo')}:{' '}
                  <strong>{data?.relatedTo?.fullName}</strong>
                </Typography>

                <Typography variant="h5" fontWeight="normal">
                  {t('inputs.label.createdBy')}:{' '}
                  <strong>{data?.createdBy?.fullName}</strong>
                </Typography>

                <Typography variant="h5" fontWeight="normal">
                  {t('inputs.label.description')}:
                  <br />
                  <strong>{data?.description}</strong>
                </Typography>
              </StyledGrid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  spacing={4}
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                >
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {t('inputs.label.createdAt')}:
                    </Typography>
                    <Typography
                      sx={{
                        pb: 2
                      }}
                      variant="h5"
                    >
                      {moment(data?.createdAt).format(DATE_TYPES.mdy)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {t('inputs.label.realDate')}:
                    </Typography>
                    <Typography
                      sx={{
                        pb: 2
                      }}
                      variant="h5"
                    >
                      {moment(data?.realDate).format(DATE_TYPES.mdy)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              sx={{
                my: 4
              }}
            />
            {can('balance-after-before') && (
              <TableWrapper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('table.balanceBefore')}</TableCell>
                        <TableCell>
                          <Grid
                            sx={{
                              textAlign: 'center'
                            }}
                          >
                            {t('table.amount')}
                          </Grid>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Grid>{t('table.balanceAfter')}</Grid>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{data?.balanceBefore} ₼</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Grid>{data?.amount} ₼</Grid>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Grid>{data?.balanceAfter} ₼</Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableWrapper>
            )}
            <TableWrapper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('table.category')}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {t('table.paymentMethod')}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {t('table.type')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography noWrap>{data?.category?.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap sx={{ textAlign: 'center' }}>
                          <Grid>{data?.paymentMethod}</Grid>
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Grid
                          sx={{
                            background: `${data?.isIncome ? 'green' : 'red'}`,
                            textAlign: 'center',
                            padding: '4px 13px',
                            borderRadius: '6px',
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '600'
                          }}
                        >
                          {data?.isIncome ? 'income' : 'expense'}
                        </Grid>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TableWrapper>
          </>
        )}
      </Card>
    </Container>
  );
};

TransactionDetails.propTypes = {
  // @ts-ignore
  invoice: PropTypes.object
};

export default TransactionDetails;
