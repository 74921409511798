import { Autocomplete, Grid, TextField } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import useAuth from 'src/shared/hooks/useAuth';

function RecurrenceSearch() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const isSingleBranch =
    user?.branches?.length <= 1
      ? user.branches?.map((branch) => ({
          id: branch?.id,
          name: branch?.name
        }))[0]
      : '';
  const formik = useFormik({
    initialValues: {
      q: '',
      branch: isSingleBranch?.id || ''
    },
    onSubmit: () => {}
  });

  let q = formik.values.q;
  let branch = formik.values?.branch;

  const triggerSearch = useCallback(function (q, branch) {
    if (q.trim() !== '') {
      searchParams.set('q', q);
    } else {
      searchParams.delete('q');
    }
    if (branch.trim() !== '') {
      searchParams.set('branch', branch);
    } else {
      searchParams.delete('branch');
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(q, branch);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [q, branch]);

  const isBranch = user?.branches?.length <= 1;

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('inputs.label.searchTransaction')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('q')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          fullWidth
          disabled={isBranch}
          options={user?.branches || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('branch', newValue ? newValue.id : '')
          }
          value={
            user.branches?.find((branch) => {
              return branch.id === formik.values.branch;
            }) || null
          }
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => (
            <TextField fullWidth {...params} label={t('inputs.label.branch')} />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default RecurrenceSearch;
