import { AddEmployee } from '../pages/employee';
import { EmployeeDetails } from '../pages/employee-details';
import { Employees } from '../pages/employees';
import { ManagePermissions } from '../pages/manage-permissions';

const employeesRouter = {
  path: 'employees',
  children: [
    {
      path: '',
      element: <Employees />,
      children: []
    },
    {
      path: 'add-employee',
      element: <AddEmployee />,
      children: []
    },
    {
      path: ':employeeId',
      element: <EmployeeDetails />,
      children: [
        {
          path: 'permissions',
          element: <ManagePermissions />,
          children: []
        }
      ]
    },
    {
      path: 'edit-employee/:employeeId',
      element: <AddEmployee />,
      children: []
    }
  ]
};

export default employeesRouter;
