import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericServiceById } from 'src/shared/services/generic-services';
import { detailsGuardianMapper } from '../mappers';

export const useGuardian = () => {
  const { guardianId } = useParams();

  const { data, isLoading } = useQuery(
    ['users', guardianId],
    getGenericServiceById,
    {
      select: ({ data }): any => {
        const mappedDetailsGuardian = detailsGuardianMapper(data?.data);
        if (data?.status === 'success') {
          return mappedDetailsGuardian;
        }
      },
      onError: queryError
    }
  );

  return {
    data,
    isLoading
  };
};
