import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { UsersTableActionButtons } from '../components/users-table-action-buttons';
import { IUser } from '../type';

export const useUsersTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('users.name'),
      href: '/dashboard/users'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      // {
      //   id: 'code',
      //   align: 'left',
      //   label: t('table.code'),
      //   extructor: (user: IUser) => user.code
      // },
      {
        id: 'fullName',
        align: 'left',
        label: t('table.fullName'),
        extructor: (user: IUser) => user.fullName
      },

      {
        id: 'email',
        align: 'center',
        label: t('table.email'),
        extructor: (user: IUser) => user.email
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (user: IUser) => {
          return <UsersTableActionButtons user={user} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
