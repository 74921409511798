import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericService } from 'src/shared/services/generic-services';
import { TGroupsRoot } from '../types';
import { groupsMapper } from '../mappers';
import { TableContext } from 'src/shared/contexts/table-context';

export const useGroups = (paginated = true) => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const archived = searchParams.get('archived');

  const { page, limit } = useContext(TableContext);

  const { data } = useQuery({
    queryKey: [
      'groups-get-all',
      'groups',
      { page, limit, q, archived, paginated }
    ],
    queryFn: getGenericService,
    select: ({ data }: { data: TGroupsRoot }) => {
      const mappedGroups = groupsMapper(data);
      if (data?.status === 'success') {
        return mappedGroups;
      }
    },
    onError: queryError
  });

  return {
    data
  };
};
