import type { ReactNode } from 'react';

import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import RoofingIcon from '@mui/icons-material/Roofing';
import SubjectTwoToneIcon from '@mui/icons-material/SubjectTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'general.name',
    items: [
      // {
      //   name: 'dashboard.name',
      //   icon: AnalyticsIcon,
      //   link: '/dashboard/dashboard'
      // },
      {
        name: 'users.name',
        icon: GroupsIcon,
        link: '/dashboard/users'
      },

      {
        name: 'guardians.name',
        icon: EscalatorWarningIcon,
        link: '/dashboard/guardians'
      },
      {
        name: 'students.name',
        icon: PeopleIcon,
        link: '/dashboard/students'
      },
      {
        name: 'employees.name',
        icon: GroupsIcon,
        link: '/dashboard/employees'
      },
      // {
      //   name: 'exams.name',
      //   icon: InsightsIcon,
      //   link: '/dashboard/exams'
      // },
      {
        name: 'transactions.addTransaction',
        icon: PaymentsIcon,
        link: '/dashboard/transactions/add-transaction'
      },
      {
        name: 'transactions.addExpense',
        icon: PaymentsIcon,
        link: '/dashboard/transactions/add-expense'
      },
      {
        name: 'transactions.name',
        icon: CompareArrowsIcon,
        link: '/dashboard/transactions'
      },
      {
        name: 'groups.name',
        icon: GroupIcon,
        link: '/dashboard/groups'
      },

      // {
      //   name: 'feedbacks.name',
      //   icon: FeedbackIcon,
      //   link: '/dashboard/feedbacks'
      // },
      {
        name: 'recurrences.name',
        icon: CurrencyExchangeIcon,
        link: '/dashboard/recurrences'
      },
      {
        name: 'lessons.name',
        icon: MenuBookIcon,
        link: '/dashboard/lessons'
      }
    ]
  },
  ,
  {
    heading: 'managment.title',
    items: [
      {
        name: 'appManager.title',
        icon: ReceiptTwoToneIcon,
        link: '/accent-sidebar/management/invoices',
        items: [
          {
            name: 'branches.name',
            icon: AddBusinessIcon,
            link: '/dashboard/branches'
          },
          {
            name: 'subjects.name',
            icon: SubjectTwoToneIcon,
            link: '/dashboard/subjects'
          },
          {
            name: 'packages.name',
            icon: Inventory2SharpIcon,
            link: '/dashboard/packages'
          },
          {
            name: 'rooms.name',
            icon: RoofingIcon,
            link: '/dashboard/rooms'
          }
        ]
      }
    ]
  }
];

export default menuItems;
