import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/shared/store';
import axios from 'src/shared/utils/axios';
import objectArray from 'src/shared/utils/objectArray';

interface MailState {
  mails: {
    byId: Record<string, any>;
    allIds: string[];
  };
  tags: any[];
  sidebarOpen: boolean;
}

const initialState: MailState = {
  mails: {
    byId: {},
    allIds: []
  },
  tags: [],
  sidebarOpen: false
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    getTags(state: MailState, action: PayloadAction<{ tags: any[] }>) {
      const { tags } = action.payload;

      state.tags = tags;
    },
    getMails(state: MailState, action: PayloadAction<{ mails: any[] }>) {
      const { mails } = action.payload;

      state.mails.byId = objectArray(mails);
      state.mails.allIds = Object.keys(state.mails.byId);
    },
    getMail(state: MailState, action: PayloadAction<{ mail: any }>) {
      const { mail } = action.payload;

      state.mails.byId[mail.id] = mail;

      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    },
    openSidebar(state: MailState): void {
      state.sidebarOpen = true;
    },
    closeSidebar(state: MailState): void {
      state.sidebarOpen = false;
    }
  }
});

export const reducer = slice.reducer;

export const getTags = (): AppThunk => async (dispatch) => {
  const response = await axios.get<{ tags: any[] }>('/api/mailbox/tags');

  dispatch(slice.actions.getTags(response.data));
};

export const getMails =
  (params: {}): AppThunk =>
  async (dispatch) => {
    const response = await axios.get<{ mails: any[] }>('/api/mailbox/mails', {
      params
    });

    dispatch(slice.actions.getMails(response.data));
  };

export const getMail =
  (mailboxCategory: string): AppThunk =>
  async (dispatch) => {
    const response = await axios.get<{ mail: any }>('/api/mailbox/mail', {
      params: {
        mailboxCategory
      }
    });

    dispatch(slice.actions.getMail(response.data));
  };

export const openSidebar =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openSidebar());
  };

export const closeSidebar =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.closeSidebar());
  };

export default slice;
