import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTransaction } from '../hooks/use-transaction';
import TransactionDetails from '../components/transaction-details';

export const TransactionDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = useTransaction();

  return (
    <Grid container sx={{ p: 3 }}>
      <TransactionDetails data={data} />
    </Grid>
    // <PageWrapper
    //   routes={routes}
    //   title={`${t('transactions.transaction')}: ${
    //     data?.title ? data?.title : ''
    //   }`}
    // >
    //   <Helmet>
    //     <title>
    //       {`${t('transactions.transaction')} | ${
    //         data?.title ? data?.title : ''
    //       }`}
    //     </title>
    //   </Helmet>

    // </PageWrapper>
  );
};
