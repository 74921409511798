import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  formik: any;
  type?: string;
}

const ArchiveFilter = ({ formik, type }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="isArchived"
          name="isArchived"
          value={formik.values.archived}
          onChange={(event, newValue) => {
            formik.setFieldValue('archived', newValue);
          }}
          onBlur={formik.handleBlur}
        >
          <FormControlLabel
            value={''}
            control={<Radio />}
            label={`${t(`${type?.toLowerCase()}.name`)}`}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={`${t(`inputs.label.archived${type}`)}`}
          />
          <FormControlLabel
            value={'all'}
            control={<Radio />}
            label={`${t('buttons.all')}`}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default ArchiveFilter;
