import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IRecurrence } from '../types';
import { RecurrencesTableActionButtons } from '../components/recurrences-table-action-buttons';
import { ExecutionStatus } from '../components/execution-status';

export const useRecurrencesTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('recurrences.name'),
      href: '/dashboard/recurrences'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'title',
        align: 'left',
        label: t('table.title'),
        extructor: (recurrence) => recurrence?.title
      },
      {
        id: 'type',
        align: 'center',
        label: t('table.type'),
        extructor: (recurrence) => (recurrence?.isIncome ? 'income' : 'expense')
      },
      {
        id: 'amount',
        align: 'center',
        label: t('table.amount'),
        extructor: (recurrence) => recurrence?.amount
      },
      {
        id: 'status',
        align: 'center',
        label: t('table.status'),
        extructor: (recurrence: any) => {
          return <ExecutionStatus recurrence={recurrence} />;
        }
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (recurrence: IRecurrence) => {
          return <RecurrencesTableActionButtons recurrence={recurrence} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
