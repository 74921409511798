import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericServiceById } from 'src/shared/services/generic-services';
import { subjectDetailsMapper } from '../mappers';

export const useSubject = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ['subjects', id],
    getGenericServiceById,
    {
      onError: queryError,
      enabled: !!id,
      select: ({ data }) => {
        if (data?.status === 'success') {
          return { name: data?.data?.name, isTought: data?.data?.isTought };
        }
      }
    }
  );

  return {
    data,
    isLoading
  };
};
