import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OmInput, OmSelect } from 'src/shared/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { OmDesktopDatePicker } from 'src/shared/components/form-components/om-desktop-date-picker';
import LoadingCircle from 'src/shared/components/Loading-circle';

export const UserForm = function ({
  formik,
  isAddingAndEditingUser,
  isAdding,
  categoriesByRoles,
  data
}) {
  const { t } = useTranslation();

  const genders = [
    { id: 'male', name: t('inputs.options.male') },
    { id: 'female', name: t('inputs.options.female') },
    { id: 'unknown', name: t('inputs.options.unknown') }
  ];

  const roles = categoriesByRoles?.data?.map((item) => ({
    id: item?.slug,
    name: item?.title
  }));

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            autoSelect
            multiple
            options={roles || []}
            disablePortal
            filterSelectedOptions
            onChange={(_e, newValue) => {
              formik.setFieldValue('roles', newValue ? newValue : '');
              formik.setFieldValue('subjects', []);
            }}
            value={formik.values?.roles || null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.roles')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput formik={formik} label={t('inputs.label.name')} name="name" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.surname')}
            name="surname"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.fatherName')}
            name="patronymic"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.email')}
            name="email"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.phoneNumber')}
            name="phoneNumbers"
            type="number"
            onWheel={(e: any) => e.target.blur()}
            startAdornment={
              formik.values.isForeign ? (
                <></>
              ) : (
                <InputAdornment position="start">+994</InputAdornment>
              )
            }
            endAdornment={
              <Box display={'flex'}>
                <FormControlLabel
                  sx={{ flex: 1, whiteSpace: 'nowrap' }}
                  label={`${t('inputs.label.isForeign')}`}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        formik.setFieldValue('isForeign', e.target.checked)
                      }
                      checked={formik.values.isForeign}
                      name="isForeign"
                    />
                  }
                />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmSelect
            formik={formik}
            label={t('inputs.label.gender')}
            name="gender"
            options={genders || []}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmDesktopDatePicker
            formik={formik}
            label={t('inputs.label.dateOfBirth')}
            name="dateOfBirth"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.label.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isAddingAndEditingUser}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 220 } }}
        >
          {data ? t('buttons.update') : t('users.addUser')}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
