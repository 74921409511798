import { Outlet } from 'react-router';
import { BranchContainer } from '../containers/branch-container';

export const Branch = function () {
  return (
    <>
      <BranchContainer />
      <Outlet />
    </>
  );
};
