import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { Link } from 'react-router-dom';
import { useDeleteRecurrence } from '../hooks/use-make-delete-recurrence';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { ExecuteRecurrenceModal } from './recurrence-execute-form';

export const RecurrencesTableActionButtons: FC<{
  recurrence;
}> = ({ recurrence }) => {
  const { t } = useTranslation();
  const { deleteRecurrence } = useDeleteRecurrence();
  const { dispatch } = useContext(ConfirmationModalContext);
  const [openExecutePaymentModal, setOpenExecutePaymentModal] = useState(false);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.execute')} arrow>
          <IconButton
            onClick={() => {
              setOpenExecutePaymentModal(true);
            }}
            color="primary"
          >
            <AddTaskIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/recurrences/${recurrence.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/recurrences/edit-recurrence/${recurrence.id}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteRecurrence')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    deleteRecurrence({
                      type: 'recurrences',
                      genericId: recurrence.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteRecurrence'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" color="error" />
          </IconButton>
        </Tooltip>
      </Typography>
      <ExecuteRecurrenceModal
        open={openExecutePaymentModal}
        handleClose={() => {
          setOpenExecutePaymentModal(false);
        }}
        id={recurrence.id}
      />
    </>
  );
};
