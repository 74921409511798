import { AxiosResponse } from 'axios';

import getAuthToken from 'src/shared/utils/getAuthToken';
import axios from 'src/shared/utils/axios';

export const addGenericToGroup = async ({
  groupId,
  type,
  ids
}): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.patch(
      `/api/v1/groups/${groupId}/${type}`,
      { ids },
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      }
    );
    return newData;
  } catch (e) {
    return e;
  }
};

export const deleteGenericFromGroup = async ({ groupId, type, ids }) => {
  try {
    return await axios.delete(`/api/v1/groups/${groupId}/${type}`, {
      data: { ids },
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
