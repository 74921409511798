import { useContext } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteTeacherFromGroup } from '../hooks/use-delete-teacher-from-group';

export const GroupTeachersActionButtons = ({ teacherId }) => {
  const { t } = useTranslation();

  const { groupId } = useParams();

  const { dispatch } = useContext(ConfirmationModalContext);

  const { deleteTeacherMutate } = useDeleteTeacherFromGroup();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/employees/edit-employee/${teacherId}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteTeacher')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    deleteTeacherMutate({
                      groupId,
                      ids: [teacherId],
                      type: 'teachers'
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteTeacherFromGroup'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'primary'
                }
              });
            }}
            color="primary"
          >
            <PersonRemoveAlt1Icon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
