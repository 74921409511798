import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { OmInput } from 'src/shared/components';
import { useTranslation } from 'react-i18next';

export const LessonForm = function ({
  formik,
  isLoading,
  groupsData,
  teachersData,
  subjectsData,
  roomsData,
  data
}) {
  const { t } = useTranslation();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ maxWidth: '550px' }}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={groupsData?.data || []}
            onChange={(_e, newValue) => {
              formik.setFieldValue('group', newValue ? newValue : '');
            }}
            value={
              groupsData?.data?.find(
                (group) => group.id === formik.values.group?.id
              ) || null
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.group')}
              />
            )}
          />

          {!!formik.errors.group && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.group}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={teachersData?.data || []}
            onChange={(_e, newValue) =>
              formik.setFieldValue('teacher', newValue ? newValue.id : '')
            }
            value={
              teachersData?.data?.find(
                (teacher) => teacher.id === formik.values.teacher
              ) || null
            }
            getOptionLabel={(option) => `${option.fullName} (${option.code})`}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.teacher')}
              />
            )}
          />
          {!!formik.errors.teacher && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.teacher}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={subjectsData?.data || []}
            onChange={(_e, newValue) =>
              formik.setFieldValue('subject', newValue ? newValue.id : '')
            }
            value={
              subjectsData?.data?.find(
                (subject) => subject.id === formik.values?.subject
              ) || null
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.subject')}
              />
            )}
          />
          {!!formik.errors.subject && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.subject}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={roomsData?.data || []}
            onChange={(_e, newValue) =>
              formik.setFieldValue('room', newValue ? newValue.id : '')
            }
            value={
              roomsData?.data?.find((room) => room.id === formik.values.room) ||
              null
            }
            getOptionLabel={(option) =>
              `${option.number} (${option.branch.name})`
            }
            renderInput={(params) => (
              <TextField fullWidth {...params} label={t('inputs.label.room')} />
            )}
          />
          {!!formik.errors.room && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.room}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <OmInput
            formik={formik}
            label={t('inputs.label.topic')}
            name="topic"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControlLabel
            sx={{ flex: 1, whiteSpace: 'nowrap' }}
            label={`${t('inputs.label.isExtra')}`}
            control={
              <Checkbox
                onChange={(e) =>
                  formik.setFieldValue('isExtra', e.target.checked)
                }
                checked={formik.values.isExtra}
              />
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            fullWidth
            color="primary"
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {data ? t('buttons.update') : t('lessons.addLesson')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
