import { useTranslation } from 'react-i18next';
import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { RecurrenceForm } from '../components/recurrence-form';
import { useRecurrenceForm } from '../hooks/use-recurrence-form';
import { styled } from '@mui/material';
import LoadingCircle from 'src/shared/components/Loading-circle';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const RecurrenceContainer = () => {
  const { t } = useTranslation();

  const {
    formik,
    data,
    incomeCategories,
    expenseCategories,
    usersData,
    isSubmitting,
    recurrenceId,
    priorities,
    user
  } = useRecurrenceForm();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('recurrences.name'),
      href: '/dashboard/recurrences'
    },
    {
      label: t(
        `recurrences.${recurrenceId ? 'editRecurrence' : 'addRecurrence'}`
      ),
      href: '/dashboard/recurrences/add-recurrence'
    }
  ];

  if (!data && recurrenceId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        recurrenceId
          ? `${t('recurrences.recurrence')}: ${data?.id}`
          : t('recurrences.addRecurrence')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <RecurrenceForm
            formik={formik}
            usersData={usersData}
            isSubmitting={isSubmitting}
            recurrenceId={recurrenceId}
            priorities={priorities}
            incomeCategories={incomeCategories}
            expenseCategories={expenseCategories}
            user={user}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
