import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OmInput, OmSelect } from 'src/shared/components';
import { OmDesktopDatePicker } from 'src/shared/components/form-components/om-desktop-date-picker';
import useAuth from 'src/shared/hooks/useAuth';

export const EmployeeForm = function ({
  formik,
  isSubmitting,
  data,
  categoriesByRoles,
  categoriesByPositions,
  subjectsData,
  employeeId
}) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const subjects = subjectsData?.data?.map((item) => ({
    id: item?.id,
    name: item?.name
  }));

  const roles = categoriesByRoles?.data?.map((item) => ({
    id: item?.slug,
    name: item?.title
  }));

  const positions = categoriesByPositions?.data?.map((item) => ({
    id: item?._id,
    title: item?.title
  }));

  const isSubject = data
    ? formik.values?.roles?.some((item) => item?.id === 'teacher')
    : formik.values?.roles?.id === 'teacher';

  const genders = [
    { id: 'male', name: t('inputs.options.male') },
    { id: 'female', name: t('inputs.options.female') },
    { id: 'unknown', name: t('inputs.options.unknown') }
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <OmSelect
            disabled={user?.branches.length === 1}
            required
            formik={formik}
            label={t('inputs.label.branch')}
            name="registeredBranches"
            options={user?.branches}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            autoSelect
            multiple={data}
            options={roles || []}
            disablePortal
            filterSelectedOptions
            onChange={(_e, newValue) => {
              formik.setFieldValue('roles', newValue ? newValue : '');
              formik.setFieldValue('subjects', []);
            }}
            value={formik.values?.roles || null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField
                required={!data || formik.values?.roles.length === 0}
                fullWidth
                {...params}
                label={data ? t('inputs.label.roles') : t('inputs.label.role')}
              />
            )}
          />

          {!!formik.errors.roles && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.roles}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            fullWidth
            options={positions || []}
            onChange={(_e, newValue) => {
              formik.setFieldValue('positions', newValue ? newValue : []);
            }}
            value={formik.values.positions || null}
            filterSelectedOptions
            getOptionLabel={(option) => `${option?.title}`}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.positions')}
              />
            )}
          />

          {!!formik.errors.positions && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.positions}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.name')}
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.surname')}
            name="surname"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.fatherName')}
            name="patronymic"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.phoneNumber')}
            name="phoneNumbers"
            placeholder="(xx)xxxxxxx"
            type="number"
            onWheel={(e: any) => e.target.blur()}
            startAdornment={
              formik.values.isForeign ? (
                <></>
              ) : (
                <InputAdornment position="start">+994</InputAdornment>
              )
            }
            endAdornment={
              <Box display={'flex'}>
                <FormControlLabel
                  sx={{ flex: 1, whiteSpace: 'nowrap' }}
                  label={`${t('inputs.label.isForeign')}`}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        formik.setFieldValue('isForeign', e.target.checked)
                      }
                      checked={formik.values.isForeign}
                      name="isForeign"
                    />
                  }
                />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.email')}
            name="email"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmSelect
            required
            formik={formik}
            label={t('inputs.label.gender')}
            name="gender"
            options={genders || []}
          />
        </Grid>
        {isSubject && (
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              fullWidth
              multiple
              options={subjects || []}
              onChange={(_e, newValue) =>
                formik.setFieldValue('subjects', newValue ? newValue : [])
              }
              value={formik.values?.subjects || null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.label.subjects')}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <OmDesktopDatePicker
            formik={formik}
            label={t('inputs.label.dateOfBirth')}
            name="dateOfBirth"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            multiline
            rows={3}
            formik={formik}
            label={t('inputs.label.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t(`buttons.${employeeId ? 'update' : 'addEmployee'}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
