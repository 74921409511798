import { Student } from '../pages/student';
import { StudentDetails } from '../pages/student-details';
import { Students } from '../pages/students';

const studentsRouter = {
  path: 'students',
  children: [
    {
      path: '',
      element: <Students />,
      children: []
    },
    {
      path: 'add-student',
      element: <Student />,
      children: []
    },
    {
      path: ':studentId',
      element: <StudentDetails />,
      children: []
    },
    {
      path: 'edit-student/:studentId',
      element: <Student />,
      children: []
    }
  ]
};

export default studentsRouter;
