import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { GuardianForm } from '../components/guardian-form';
import { useGuardianForm } from '../hooks/use-guardian-form';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { styled } from '@mui/material';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const GuardianContainer = function () {
  const { t } = useTranslation();
  const { formik, isSubmitting, guardianId, data } = useGuardianForm();
  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('guardians.name'),
      href: '/dashboard/guardians'
    },
    {
      label: t(`guardians.${guardianId ? 'editGuardian' : 'addGuardian'}`),
      href: '/dashboard/parents/add-guardian'
    }
  ];

  if (!data && guardianId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        guardianId
          ? `${t('guardians.guardian')}: ${data?.code}`
          : t('guardians.addGuardian')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <GuardianForm
            guardianId={guardianId}
            formik={formik}
            isSubmitting={isSubmitting}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
