import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { useGroups } from 'src/modules/groups/hooks/use-groups';
import { useSubjects } from 'src/modules/subjects/hooks/use-subjects';
import { lessonFormMapper } from '../mappers';
import { useRooms } from 'src/modules/rooms/hooks/use-rooms';
import { useLesson } from './use-lesson';
import { useTeachers } from 'src/modules/employees/hooks/use-teachers';

export const useLessonForm = function () {
  const { t } = useTranslation();
  const { handleMessage } = useContext(AlertContext);
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: groupsData } = useGroups();
  const { data: teachersData } = useTeachers();
  const { data: subjectsData } = useSubjects();
  const { data: roomsData } = useRooms();
  const { data } = useLesson();
  const { pathname } = useLocation();
  const isAdding = pathname.includes('add');

  const { mutate: addAndEditLessonFormMutate, isLoading } = useMutation(
    'add-lesson',
    addAndUpdateGenericService,
    {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('lessons-get-all');
          if (isAdding) navigate('/dashboard/lessons');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    }
  );

  const validationSchema = Yup.object().shape({
    group: Yup.object().required(t('yup.required.group')),
    teacher: Yup.string().nullable().required(t('yup.required.teacher')),
    subject: Yup.string().nullable().required(t('yup.required.subject')),
    room: Yup.string().nullable().required(t('yup.required.room'))
  });
  const formik = useFormik({
    initialValues: {
      group: data?.group || '',
      teacher: data?.teacher || '',
      subject: data?.subject || '',
      topic: data?.topic || '',
      room: data?.room || '',
      isExtra: data?.isExtra || false
    },
    onSubmit: (values) => {
      const mappedLesson = lessonFormMapper(values);

      if (values) {
        if (lessonId) {
          addAndEditLessonFormMutate({
            type: 'lessons',
            genericId: lessonId,
            data: mappedLesson
          });
        } else {
          addAndEditLessonFormMutate({
            data: mappedLesson,
            type: 'lessons'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isLoading,
    isAdding,
    teachersData,
    groupsData,
    subjectsData,
    roomsData,
    data
  };
};
