import { Package } from '../pages/package';
import { PackageDetails } from '../pages/package-details';
import { Packages } from '../pages/packages';

const packagesRouter = {
  path: 'packages',
  children: [
    {
      path: '',
      element: <Packages />,
      children: []
    },
    {
      path: 'add-package',
      element: <Package />,
      children: []
    },
    {
      path: ':packageId',
      element: <PackageDetails />,
      children: []
    },
    {
      path: 'edit-package/:packageId',
      element: <Package />,
      children: []
    }
  ]
};

export default packagesRouter;
