import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { EmployeeForm } from '../components/employee-form';
import { useEmployeeForm } from '../hooks/use-employee-form';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { styled } from '@mui/material';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const EmployeeContainer = function () {
  const {
    formik,
    isSubmitting,
    data,
    categoriesByRoles,
    categoriesByPositions,
    employeeId,
    subjectsData
  } = useEmployeeForm();
  const { t } = useTranslation();
  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('employees.name'),
      href: '/dashboard/employees'
    },
    {
      label: t(`employees.${employeeId ? 'editEmployee' : 'addEmployee'}`),
      href: '/dashboard/parents/add-employee'
    }
  ];

  if (!data && employeeId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        employeeId
          ? `${t('employees.employee')}: ${data?.code}`
          : t('employees.addEmployee')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <EmployeeForm
            formik={formik}
            employeeId={employeeId}
            isSubmitting={isSubmitting}
            data={data}
            categoriesByRoles={categoriesByRoles}
            categoriesByPositions={categoriesByPositions}
            subjectsData={subjectsData}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
