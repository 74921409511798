import PageWrapper from 'src/shared/components/page-wrapper';
import { Button, Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGuardian } from '../hooks/use-guardian';
import { GuardianDetails } from '../components/guardian-details';
import { Link, useParams } from 'react-router-dom';

export const GuardianDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGuardian();
  const { guardianId } = useParams();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('guardians.name'),
      href: '/dashboard/guardians'
    },
    {
      label: t('guardians.guardian'),
      href: '/dashboard/guardian'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('guardians.guardian')}: ${data?.code ? data?.code : ''}`}
      action={
        <>
          <Link
            to={`/dashboard/guardians/edit-guardian/${guardianId}`}
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{ ml: 1 }} variant="contained">
              {t('buttons.edit')}
            </Button>
          </Link>
        </>
      }
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <GuardianDetails data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
