import { IRoom, IRoomDetails, IRoomDetailsAPI } from '../type';

export function roomFormMapper(values) {
  const room = {
    branch: values.branch.id,
    name: values.name,
    number: values.number,
    size: values.size
  };

  return room;
}

export function roomDetailsMapper(room: IRoomDetailsAPI): IRoomDetails {
  return {
    id: room?.id,
    name: room.name,
    branch: room.branch,
    number: room.number,
    size: room.size
  };
}

export function roomMapper(room: IRoomDetailsAPI): any {
  return {
    id: room?.id,
    branch: room.branch,
    number: room.number,
    size: room.size
  };
}

export function roomsMapper(data) {
  const rooms = {
    data: data?.data.map((group) => roomMapper(group)),
    total: data?.total
  };
  return rooms;
}
