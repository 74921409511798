import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { Link } from 'react-router-dom';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useMakeDeletePackage } from '../hooks/use-make-delete-package';

export const PackagesTableActionButtons: FC<{
  packageItem;
}> = ({ packageItem }) => {
  const { t } = useTranslation();
  const { makeDeletePackage } = useMakeDeletePackage();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/packages/${packageItem.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/packages/edit-package/${packageItem.id}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deletePackage')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeletePackage({
                      type: 'packages',
                      genericId: packageItem.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deletePackage'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
