import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import { addGenericToGroup } from '../services';
import { useTeachers } from 'src/modules/employees/hooks/use-teachers';
import { useTranslation } from 'react-i18next';

export const useAddTeacherModal = () => {
  const { groupId } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { handleMessage } = useContext(AlertContext);

  const { data: teachersData } = useTeachers(false);

  const { mutate: addTeacherToGroupMutate, isLoading: isAddingTeacher } =
    useMutation('add-teacher-to-group', addGenericToGroup, {
      onSuccess: (e: any) => {
        if (e.status === 'success') {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('groups');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    // teacherId: Yup.string().required('Teacher is a required field')
  });

  const formik = useFormik({
    initialValues: {
      groupId: groupId || '',
      ids: []
    },
    onSubmit: (values) => {
      addTeacherToGroupMutate({
        groupId: values.groupId,
        ids: values.ids,
        type: 'teachers'
      });
    },
    validationSchema,
    enableReinitialize: true
  });
  return {
    teachersData,
    formik,
    isAddingTeacher
  };
};
