import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Link } from 'react-router-dom';
import { useMakeDeleteRoom } from '../hooks/use-make-delete-room';

export const RoomsTableActionButtons: FC<{
  room;
}> = ({ room }) => {
  const { t } = useTranslation();
  const { makeDeleteRoom } = useMakeDeleteRoom();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/rooms/${room.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            color="primary"
            component={Link}
            to={`/dashboard/rooms/edit-room/${room.id}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteRoom')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteRoom({
                      type: 'rooms',
                      genericId: room.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteRoom'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
