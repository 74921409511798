import { useContext } from 'react';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Link } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSubjects } from '../hooks/use-subjects';
import { useSubjectsTable } from '../hooks/use-subjects-table';
import { TableContext } from 'src/shared/contexts/table-context';
import GenericTableV2 from 'src/shared/components/generic-table-v2';

export const SubjectsContainer = () => {
  const { t } = useTranslation();
  const { data } = useSubjects();
  const { page, limit, setPage, setLimit } = useContext(TableContext);

  const [columns, options, routes] = useSubjectsTable();

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('subjects.name')}
      action={
        <Link to="/dashboard/subjects/add" style={{ textDecoration: 'none' }}>
          <Button variant="contained">{t('buttons.addSubject')}</Button>
        </Link>
      }
    >
      {/* <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px'
        }}
      >
        <Box
          onClick={handleFilter}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>{t('inputs.label.filter')}</Typography>
          {!isOpen ? (
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />
          )}
        </Box>
        {isOpen && <SubjectSearch />}
      </Card> */}
      <Card>
        <GenericTableV2
          rows={data?.data}
          rowsCount={data?.total}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
};
