import { Typography, Switch } from '@mui/material';

export const ManagePermissionsActionButtons = ({
  permission,
  formik
}: {
  permission;
  formik;
}) => {
  const handleTogglePermission = () => {
    let { permissions } = formik.values;
    if (permissions.includes(permission._id)) {
      permissions = permissions.filter((perStrg: string) => {
        return perStrg !== permission._id;
      });
    } else {
      permissions = [...permissions, permission._id];
    }

    formik.setFieldValue('permissions', permissions);
  };

  const isChecked = formik.values.permissions?.includes(permission._id);

  return (
    <Typography noWrap>
      <Switch checked={isChecked} onChange={handleTogglePermission} />
    </Typography>
  );
};
