import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { DATE_TYPES } from 'src/shared/costants/constants';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { weekdaysMaker } from 'src/shared/utils/weekdays-format';
import { LessonsTableActionButtons } from '../components/lessons-table-action-buttons';
import { ILesson } from '../types';

export const useLessonsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('lessons.name'),
      href: '/dashboard/lessons'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      // {
      //   id: 'code',
      //   align: 'left',
      //   label: t('table.code'),
      //   extructor: (lesson: ILesson) => lesson?.code
      // },

      {
        id: 'teacher',
        align: 'left',
        label: t('table.topic'),
        extructor: (lesson: ILesson) => lesson?.topic
      },
      {
        id: 'weekdays',
        align: 'center',
        label: t('table.group'),
        extructor: (lesson: ILesson) =>
          `${lesson?.group?.name} (${weekdaysMaker(lesson?.group?.weekdays)})`
      },
      {
        id: 'teacher',
        align: 'center',
        label: t('table.teacher'),
        extructor: (lesson: ILesson) => lesson?.teacher?.fullName
      },
      {
        id: 'topic',
        align: 'center',
        label: t('table.subject'),
        extructor: (lesson: ILesson) => lesson?.subject?.name
      },
      {
        id: 'date',
        align: 'center',
        label: t('table.date'),
        extructor: (lesson: ILesson) =>
          moment(lesson.date).format(DATE_TYPES.mdy)
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (lesson: ILesson) => {
          return <LessonsTableActionButtons lesson={lesson} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
