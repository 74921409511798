import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IPackage } from '../type';
import { PackagesTableActionButtons } from '../components/packages-table-action-buttons';

export const usePackagesTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('packages.name'),
      href: '/dashboard/packages'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (pack) => pack.name
      },
      {
        id: 'price',
        align: 'center',
        label: t('table.price'),
        extructor: (pack) => pack.price
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (packageItem: IPackage) => {
          return <PackagesTableActionButtons packageItem={packageItem} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
