import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DetailsTable({ data: students, type }) {
  const { t } = useTranslation();

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('inputs.label.fullName')}</TableCell>
            {type === 'present' && (
              <TableCell>{t('inputs.label.marks')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {students?.map((student: any) => (
            <TableRow key={student?.id}>
              <TableCell component="th" scope="row">
                {student?.fullName}
              </TableCell>
              {type === 'present' && (
                <TableCell component="th" scope="row">
                  {student?.mark}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

DetailsTable.propTypes = {
  classes: PropTypes.object
};

export default DetailsTable;
