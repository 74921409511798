import { Outlet } from 'react-router';
import { EmployeeDetailsContainer } from '../containers/employee-details-container';

export const EmployeeDetails = () => {
  return (
    <>
      <EmployeeDetailsContainer />
      <Outlet />
    </>
  );
};
