import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { UserForm } from '../components/user-form';
import { useUserForm } from '../hooks/use-user-form';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { styled } from '@mui/material';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const UserContainer = function () {
  const { t } = useTranslation();
  const { formik, isAddingAndEditingUser, isAdding, categoriesByRoles, data } =
    useUserForm();
  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('users.name'),
      href: '/dashboard/users'
    },
    {
      label: data ? t('users.editUser') : t('users.addUser'),
      href: '/dashboard/parents/add-user'
    }
  ];

  if (!data && !isAdding) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={data ? t('users.editUser') : t('users.addUser')}
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <UserForm
            data={data}
            formik={formik}
            isAdding={isAdding}
            categoriesByRoles={categoriesByRoles}
            isAddingAndEditingUser={isAddingAndEditingUser}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
