import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBranch } from '../hooks/use-branch';
import { BranchDetails } from '../components/branch-details';

export const BranchDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useBranch();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('branches.name'),
      href: '/dashboard/branches'
    },
    {
      label: t('branches.branch'),
      href: '/dashboard/branch'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('branches.branch')}: ${data?.name ? data?.name : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <BranchDetails data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
