import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/shared/components/SuspenseLoader';
import Guest from 'src/shared/components/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/modules/auth/pages/login')));
const RecoverPassword = Loader(
  lazy(() => import('src/modules/auth/pages/recover-password'))
);

const accountRoutes = [
  {
    path: '/',
    element: (
      <Guest>
        <Login />
      </Guest>
    )
  },
  {
    path: 'login',
    element: (
      <Guest>
        <Login />
      </Guest>
    )
  },
  {
    path: 'recover-password',
    element: (
      <Guest>
        <RecoverPassword />
      </Guest>
    )
  }
];

export default accountRoutes;
