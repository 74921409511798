import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { getUsersForRoleService } from 'src/modules/user-services/services';

export const useManagers = function (paginated = true) {
  const { page, limit } = useContext(TableContext);

  const { data, isLoading } = useQuery({
    queryKey: ['managers-get-all', 'manager', { page, limit, paginated }],
    queryFn: getUsersForRoleService,
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
