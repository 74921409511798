import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import { Link } from 'react-router-dom';
import { useDeleteBranch } from '../hooks/use-delete-branch';
export const BranchesTableActionButtons: FC<{
  branch;
}> = ({ branch }) => {
  const { t } = useTranslation();
  const { deleteBranch } = useDeleteBranch();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/branches/${branch.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/branches/edit-branch/${branch.id}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteBranch')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    deleteBranch({
                      type: 'branches',
                      genericId: branch.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteBranch'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
