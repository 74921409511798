import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { GroupStudentsActionButtons } from '../components/group-students-action-buttons';
import { IStudent } from 'src/modules/students/type';

export const useGroupStudentsTable = (): [IGenericTableColumns, IOptions] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      // {
      //   id: 'code',
      //   align: 'left',
      //   label: t('table.code'),
      //   extructor: (student: IStudent) => student?.code
      // },
      {
        id: 'fullName',
        align: 'left',
        label: t('table.fullName'),
        extructor: (student: IStudent) => student?.fullName
      },
      {
        id: 'email',
        align: 'center',
        label: t('table.email'),
        extructor: (student: IStudent) => student?.email
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (student: IStudent) => {
          return <GroupStudentsActionButtons studentId={student?.id} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options];
};
