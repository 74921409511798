const enJSON = {
  languageSwitcher: {
    title: 'Language',
    switchLanguage: 'Switch language'
  },
  login: { title: 'Login', ourTeam: 'Our team' },
  signin: {
    title: 'Sign in',
    label: 'Fill in the fields below to sign into your account. '
  },
  popover: {
    profile: 'Profile',
    signOut: 'Sign out'
  },
  general: {
    name: 'General'
  },
  managment: { title: 'Management' },
  appManager: { title: 'App manager' },
  dashboard: {
    name: 'Dashboard'
  },
  teachers: {
    name: 'Teachers'
  },
  transactions: {
    name: 'Transactions',
    addTransaction: 'Add transaction',
    addExpense: 'Add expense',
    transaction: 'Transaction'
  },
  home: {
    name: 'Home'
  },
  branches: {
    name: 'Branches',
    addBranch: 'Add branch',
    editBranch: 'Edit branch',
    branch: 'Branch'
  },
  parents: {
    name: 'Parents',
    addParent: 'Add parent'
  },
  users: {
    name: 'Users',
    addUser: 'Add user',
    user: 'User',
    editUser: 'Edit user'
  },
  guardians: {
    name: 'Guardians',
    addGuardian: 'Add guardian',
    guardian: 'Guardian',
    editGuardian: 'Edit guardian'
  },
  students: {
    name: 'Students',
    addStudent: 'Add student',
    student: 'Student',
    editStudent: 'Edit student'
  },
  groups: {
    name: 'Groups',
    addGroup: 'Add group',
    group: 'Group',
    editGroup: 'Edit group'
  },
  employees: {
    name: 'Employees',
    addEmployee: 'Add employee',
    employee: 'Employee',
    editEmployee: 'Edit employee'
  },
  managePermissions: {
    name: 'Manage permissions'
  },
  lessons: {
    name: 'Lessons',
    addLesson: 'Add lesson',
    editLesson: 'Edit lesson',
    lesson: 'Lesson'
  },
  exams: {
    name: 'Exams',
    addExam: 'Add exam',
    exam: 'Exam',
    editExam: 'Edit exam'
  },
  feedbacks: {
    name: 'Feedbacks',
    addFeedback: 'Add feedback',
    feedback: 'Feedback',
    editFeedback: 'Edit feedback'
  },
  recurrences: {
    name: 'Recurrences',
    addRecurrence: 'Add recurrence',
    recurrence: 'Recurrence',
    editRecurrence: 'Edit recurrence'
  },
  subjects: {
    name: 'Subjects',
    addSubject: 'Add subject',
    subject: 'Subject',
    editSubject: 'Edit subject'
  },
  packages: {
    name: 'Packages',
    addPackage: 'Add package',
    package: 'Package',
    editPackage: 'Edit package'
  },
  rooms: {
    name: 'Rooms',
    addRoom: 'Add room',
    room: 'Room',
    editRoom: 'Edit room'
  },
  upperCategories: {
    name: 'Upper categories',
    addUpperCategory: 'Add upper category',
    upperCategory: 'Upper category',
    editUpperCategory: 'Edit upper category'
  },
  lowerCategories: {
    name: 'Lower categories',
    addLowerCategory: 'Add lower category',
    lowerCategory: 'Lower category',
    editLowerCategory: 'Edit lower category'
  },
  maintenance: {
    name: 'Maintenance'
  },
  addTransaction: {
    name: 'Add transaction'
  },

  table: {
    fullName: 'Full name',
    rowsOrder: '№',
    code: 'Code',
    email: 'Email',
    actions: 'Actions',
    date: 'Date',
    name: 'Name',
    time: 'Time',
    isTought: 'Thought',
    price: 'Price',
    subjects: 'Subjects',
    branch: 'Branch',
    number: 'Number',
    size: 'Size',
    category: 'Category',
    paymentMethod: 'Payment method',
    teacherCode: 'Teacher code',
    title: 'Title',
    amount: 'Amount',
    schoolAmount: 'School amount',
    executor: 'Executor',
    company: 'Company',
    address: 'Address',
    manager: 'Manager',
    room: 'Room',
    slug: 'Slug',
    teacher: 'Teacher',
    group: 'Group',
    topic: 'Topic',
    content: 'Content',
    type: 'Type',
    status: 'Status',
    students: 'Students',
    executed: 'Executed',
    guardian: 'Guardian',
    balanceBefore: 'Balance before',
    balanceAfter: 'Balance after',
    notExecutedYet: 'Not executed yet',
    subject: 'Subject'
  },
  inputs: {
    label: {
      price: 'Price',
      filter: 'Filter',
      name: 'Name',
      surname: 'Surname',
      number: 'Number',
      email: 'Email',
      gender: 'Gender',
      fatherName: 'Father name',
      description: 'Description',
      note: 'Note',
      phoneNumber: 'Phone number',
      dateOfBirth: 'Date of birth',
      roles: 'Roles',
      role: 'Role',
      password: 'Password',
      lostPassword: 'Lost password',
      passwordConfirm: 'Password confirm',
      repeatPassword: 'Does not match the repeat password',
      matchPassword: 'Does not match the the new password',
      socialMediaProfiles: 'Socila media profiles',
      groupDP: 'GroupDP',
      schoolAdmittionYear: 'School admittion year',
      guardian: 'Guardian',
      relationship: 'Relationship',
      rooms: 'Packages',
      subject: 'Subject',
      date: 'Date',
      amount: 'Amount',
      company: 'Company',
      address: 'Address',
      manager: 'Manager',
      search: 'Search',
      time: 'Time',
      size: 'Size',
      searchByCode: 'Search by code',
      searchByName: 'Search by name',
      searchTransaction: 'Search by title, category, code',
      searchByCodeAndTopic: 'Search by code and topic',
      balance: 'Balance',
      dateTime: 'Date & time',
      isTought: 'Tought',
      category: 'Category',
      managers: 'Managers',
      branch: 'Branch',
      chiefManager: 'Chief manager',
      code: 'Code',
      fullName: 'Full name',
      status: 'Status',
      title: 'Title',
      priority: 'Priority',
      upperCategory: 'Upper category',
      relatedTo: 'Related to ',
      cash: 'Cash',
      online: 'Online',
      paymentMethod: 'Payment method',
      newPaymentMethod: 'New payment method',
      realDate: 'Real date',
      hiddenTransaction: 'Hidden transaction',
      newRealDate: 'New real date',
      differentTime: 'Register payment for a different time',
      teacher: 'Teacher',
      group: 'Group',
      isForeign: 'Foreign',
      packages: 'Packages',
      topic: 'Topic',
      room: 'Room',
      groupCode: 'Group code',
      content: 'Content',
      lesson: 'Lesson',
      student: 'Student',
      weekdays: 'Weekdays',
      students: 'Students',
      periodicity: 'Periodicity',
      remindDuration: 'Remind duration',
      dueDate: 'Due date',
      isIncome: 'Income',
      subjects: 'Subjects',
      type: 'Type',
      income: 'Income',
      expense: 'Expense',
      recurrenceType: 'Choose your recurrence type',
      notDeletable: 'Not deletable',
      createdBy: 'Created by',
      transactionType: 'Transaction type',
      balanceBefore: 'Balance before',
      balanceAfter: 'Balance after',
      patronymic: 'Patronymic',
      rows: 'Rows Per Page',
      teachers: 'Teachers',
      male: 'Male',
      searchLesson: 'Search by code and topic',
      female: 'Female',
      unknown: 'Unknown',
      facebook: 'Facebook',
      instagram: 'Instagram',
      linkedin: 'Linkedin',
      zoom: 'Zoom',
      facebookUrl: 'Facebook account url',
      instagramUrl: 'Instagram account url',
      linkedinUrl: 'Linkedin account url',
      zoomUrl: 'Zoom account url',
      profilImage: 'Profile Image',
      changeProfilImage: 'Change your profile image',
      upload: 'Upload',
      currentPassword: 'Current password',
      myAccount: 'My account',
      aboutMe: 'About me',
      changePassword: 'Change password',
      positions: 'Positions',
      recoverPassword: 'Recover password',
      emailForRecoverPassword:
        'Enter the email used for registration to reset your password.',
      package: 'Package',
      isExtra: 'Extra lesson',
      notExtra: 'Not extra lesson',
      newAmount: 'New amount',
      executeRecurrenceRegister: 'Register payment for a different time',
      executeRecurrenceLabel:
        'If you want to change date or amount, please use inputs below. Otherwise ignore.',
      archivedUsers: 'Archived users',
      archivedGuardians: 'Archived guardians',
      archivedStudents: 'Archived students',
      archivedEmployees: 'Archived employees',
      archivedGroups: 'Archived groups'
    },
    options: {
      admin: 'Admin',
      student: 'Student',
      guardian: 'Guardian',
      father: 'Father',
      mother: 'Mother',
      male: 'Male',
      female: 'Female',
      unknown: 'Unknown'
    }
  },
  yup: {
    required: {
      title: 'Title is required',
      number: 'Number is required',
      guardian: 'Guardian is required',
      address: 'Address is required',
      category: 'Category is required',
      surname: 'Surname is required',
      amount: 'Amount is required',
      student: 'Student is required',
      group: 'Group is required',
      teacher: 'Teacher is required',
      subject: 'Subject is required',
      price: 'Price is required',
      lesson: 'Lesson is required',
      name: 'Name is required',
      email: 'The email field is required',
      roles: 'Roles is required',
      gender: 'Gender is required',
      relatedTo: 'RelatedTo is required',
      branch: 'Branch is required',
      room: 'Room is required',
      manager: 'Manager is required',
      relationship: 'Relationship is required',
      password: 'Password is required',
      repeatPassword: 'Repeat password is required',
      minPassword: 'Password must be at least 6 characters',
      minPhoneNumber: 'Phonenumber must be min 9 numbers',
      maxPhoneNumber: 'Phonenumber must be max 9 numbers',
      validPhoneNumber: 'Phone number is not valid',
      matchPassword: 'Passwords must match',
      validEmail: 'The email provided should be a valid email address',
      relatedToWhenCategoryIs: 'Related to is required when category is ',
      salary: 'Salary',
      transactionStudent: 'Student',
      schoolAdmittionYear: 'School admition year is required'
    },
    messages: {
      email: 'The email provided should be a valid email address',
      terms: 'You must agree to our terms and conditions',
      invalidCredentials: 'Invalid credentials, please log in again'
    }
  },
  buttons: {
    addParent: 'Add parent',
    addGuardian: 'Add guardian',
    confirm: 'Confirm',
    cancel: 'Cancel',
    addStudent: 'Add student',
    addEmployee: 'Add employee',
    addLesson: 'Add lesson',
    addExam: 'Add exam',
    addSubject: 'Add subject',
    addPackage: 'Add package',
    addBranch: 'Add branch',
    editBranch: 'Edit branch',
    addRoom: 'Add room',
    addTransaction: 'Add transaction',
    addExpense: 'Add expense',
    add: 'Add',
    edit: 'Edit',
    addGroup: 'Add group',
    addLowerCategory: 'Add lower category',
    editLesson: 'Edit lesson',
    addUpperCategory: 'Add upper category',
    addFeedback: 'Add feedback',
    addRecurrence: 'Add recurrence',
    editGroup: 'Edit group',
    addTeacher: 'Add teacher',
    editRecurrence: 'Edit recurrence ',
    execute: 'Execute',
    managePermissions: 'Manage permissions',
    editRoom: 'Edit room',
    update: 'Update',
    submit: 'Submit',
    sendMe: 'Send me a new password',
    clickHere: 'Click here',
    addUser: 'Add user',
    yes: 'Yes',
    no: 'No',
    all: 'All',
    continueToLogin: 'Continue to login'
  },
  alerts: {
    passwordReset: 'He password reset instructions have been sent to your email'
  },
  tooltips: {
    view: 'View',
    edit: 'Edit',
    remove: 'Remove',
    archiveUser: 'Archive user',
    unArchiveUser: 'Unarchive user',
    archiveGuardian: 'Archive guardian',
    unArchiveGuardian: 'Unarchive guardian',
    archiveStudent: 'Archive student',
    unArchiveStudent: 'Unarchive student',
    archiveLesson: 'Archive lesson',
    archiveEmployee: 'Archive employee',
    unArchiveEmployee: 'Unarchive employee',
    archiveExam: 'Archive exam',
    archiveSubject: 'Archive subject',
    archiveRoom: 'Archive room',
    archiveBranch: 'Archive branch',
    validEmail: 'Указанный адрес электронной почты должен быть действительным',
    archiveGroup: 'Archive group',
    unArchiveGroup: 'Unarchive group',
    archiveUpperCategory: 'Archive upper typography',
    archiveLowerCategory: 'Archive lower category',
    deleteGroup: 'Delete group',
    deleteExam: 'Delete exam',
    deleteLesson: 'Delete lesson',
    deleteBranch: 'Delete branch',
    deleteSubject: 'Delete subject',
    deletePackage: 'Delete package',
    deleteRoom: 'Delete room',
    deletUpperCategory: 'Delete upper category',
    deleteLowerCategory: 'Delete lower category',
    deleteTransaction: 'Delete transaction',
    deleteStudent: 'Delete student',
    deleteEmployee: 'Delete employee',
    deleteTeacher: 'Delete teacher',
    deleteGuardian: 'Delete guardian',
    deleteUser: 'Delete user',
    deleteRecurrence: 'Delete recurrence',
    permissions: 'Permissions',
    execute: 'Execute',
    languageSwitcher: 'Language switcher'
  },
  typography: {
    amount:
      'If you want to change date or amount, please use inputs below.Otherwise ignore.',
    email: 'Check your email for further instructions',
    currentMaintenance: 'The site is currently down for maintenance',
    appologizeMaintenance: 'We apologize for any inconveniences caused',
    present: 'Present',
    absent: 'Absent'
  },
  messages: {
    successfulProcess: 'Process was successful',
    unSuccessfulProcess: 'Process was not successful',
    unknownError: 'Unknown error',
    success: 'Success',
    error: 'Error',
    archiveUser: 'Are you sure archive this user?',
    unArchiveUser: 'Are you sure unarchive this user?',
    archiveGuardian: 'Are you sure archive this guardian?',
    unArchiveGuardian: 'Are you sure unarchive this guardian?',
    archiveStudent: 'Are you sure archive this student?',
    unArchiveStudent: 'Are you sure unarchive this student?',
    archiveEmployee: 'Are you sure archive this employee?',
    unArchiveEmployee: 'Are you sure unarchive this employee?',
    archiveExam: 'Are you sure archive this exam',
    archiveSubject: 'Are you sure archive this subject',
    archiveRoom: 'Archive room',
    archiveBranch: 'Are you sure archive this branch',
    archiveLowerCategory: 'Are you sure archive this lower category',
    archiveUpperCategory: 'Are you sure archive this upper category',
    archiveGroup: 'Are you sure to archive this group?',
    unArchiveGroup: 'Are you sure to unarchive this group?',
    deleteGroup: 'Are you sure to delete this group',
    notFound: 'Data not found!',
    deleteExam: 'Are you sure to delete this exam',
    deleteUser: 'Are you sure to delete this user',
    deleteLesson: 'Are you sure to delete this lesson',
    deleteBranch: 'Are you sure to delete this branch',
    deleteSubject: 'Are you sure to delete this subject',
    deletePackage: 'Are you sure to delete this package',
    deleteRoom: 'Are you sure to delete this room',
    deletUpperCategory: 'Are you sure to delete this upper category',
    deleteLowerCategory: 'Are you sure to delete this lower category',
    deleteTransaction: 'Are you sure to delete this transaction',
    deleteStudentFromGroup: 'Are you sure to delete this student from group',
    deleteTeacherFromGroup: 'Are you sure to delete this teacher from group',
    deleteGuardian: 'Are you sure to delete this guardian',
    deleteStudent: 'Are you sure to delete this student',
    deleteEmployee: 'Are you sure to delete this employee',
    deleteRecurrence: 'Are you sure to delete this recurrence',
    wantToSignin: 'Want to try to sign in again?',
    addStudentToGroup: '  Add student to group',
    addTeacherToGroup: '  Add student to group'
  },
  modal: {
    title: {
      permissions: 'Manage Permissions'
    },
    subtitle: {
      permissions:
        'Change following switch buttons to activate or deactivate permissions'
    }
  },
  selectInput: {
    active: 'Active',
    achived: 'Achived'
  }
};

export default enJSON;
