import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FC } from 'react';
import cuid from 'cuid';

interface PageHeaderNewProps {
  title: string;
  description?: string;
  routes?: any[];
}

const PageHeaderNew: FC<PageHeaderNewProps> = ({
  title,
  description,
  children,
  routes
}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ pb: { xs: 1, sm: 2 } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        rowSpacing={{ xs: 2 }}
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle2">{description}</Typography>
          {routes && (
            <Breadcrumbs maxItems={3} aria-label="breadcrumb">
              {routes.map((route, i) =>
                i !== routes.length - 1 ? (
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={route.href}
                    key={cuid()}
                  >
                    {route.label}
                  </Link>
                ) : (
                  <Typography
                    variant="subtitle1"
                    key={cuid()}
                    sx={{ opacity: '80%' }}
                  >
                    {route.label}
                  </Typography>
                )
              )}
            </Breadcrumbs>
          )}
        </Grid>
        {children && <Grid item>{children}</Grid>}
      </Grid>
    </Grid>
  );
};

export default PageHeaderNew;
