import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { useRoom } from './use-room';
import { roomFormMapper } from '../mappers';
import useAuth from 'src/shared/hooks/useAuth';

export const useRoomForm = function () {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roomId } = useParams();
  const { data } = useRoom();
  const { user } = useAuth();

  const { mutate: addAndEditRoomFormMutate, isLoading: isSubmitting } =
    useMutation('add-room', addAndUpdateGenericService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('rooms-get-all');
          if (!roomId) navigate('/dashboard/rooms');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const isSingleBranch =
    user?.branches?.length <= 1
      ? user.branches?.map((branch) => ({
          id: branch.id,
          name: branch.name
        }))[0]
      : '';

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.required.name')),
    number: Yup.string().required(t('yup.required.number')),
    branch: Yup.object().required(t('yup.required.branch'))
  });

  const formik = useFormik({
    initialValues: {
      branch: data?.branch || isSingleBranch,
      name: data?.name || '',
      number: data?.number || '',
      size: data?.size || ''
    },
    onSubmit: (values) => {
      const mappedRoom = roomFormMapper(values);
      if (values) {
        if (roomId) {
          addAndEditRoomFormMutate({
            type: 'rooms',
            genericId: roomId,
            data: mappedRoom
          });
        } else {
          addAndEditRoomFormMutate({
            data: mappedRoom,
            type: 'rooms'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isSubmitting,
    roomId,
    data,
    user
  };
};
