import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericServiceById } from 'src/shared/services/generic-services';
import { roomDetailsMapper } from '../mappers';

export const useRoom = () => {
  const { roomId } = useParams();
  const { data, isLoading } = useQuery(
    ['rooms', roomId],
    getGenericServiceById,
    {
      select: ({ data }) => {
        const room = roomDetailsMapper(data?.data);
        if (data?.status === 'success') {
          return room;
        }
      },
      onError: queryError
    }
  );

  return {
    data,
    isLoading
  };
};
