import {
  Grid,
  Box,
  InputAdornment,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OmInput } from 'src/shared/components';
import { OmDesktopDatePicker } from 'src/shared/components/form-components/om-desktop-date-picker';
import SubmitButton from 'src/shared/components/Submit-button';
import Manat from 'src/assets/icon/manat';
import useAuth from 'src/shared/hooks/useAuth';

export const TransactionForm = ({
  formik,
  isSubmitting,
  isAddTransaction,
  incomesUpperCategories,
  expensesUpperCategories,
  studentsData,
  employeesData,
  transactionType,
  user
}) => {
  const { t } = useTranslation();
  const { can } = useAuth();

  const incomes = incomesUpperCategories?.data?.map((item) => ({
    id: item?._id,
    title: item?.title,
    slug: item?.slug
  }));
  const expenses = expensesUpperCategories?.data?.map((item) => ({
    id: item?._id,
    title: item?.title,
    slug: item?.slug
  }));

  const categorySlug = formik.values?.category?.slug;
  const categoryData =
    categorySlug === 'student'
      ? studentsData?.data
      : categorySlug === 'salary'
      ? employeesData?.data
      : null;

  const isBranch = user?.branches?.length <= 1;
  const isTransaction = transactionType === 'income';
  const isTitle = !(transactionType === 'income' && categorySlug === 'student');

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              disabled={isBranch}
              options={user?.branches || []}
              value={formik.values.branch || null}
              onChange={(_e, newValue) => {
                formik.setFieldValue('branch', newValue ? newValue : '');
              }}
              getOptionLabel={(option) => `${option.name} `}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              renderInput={(params) => (
                <TextField
                  fullWidth
                  name="branch"
                  {...params}
                  label={t('inputs.label.branch')}
                />
              )}
            />
            {formik.touched.branch && formik.errors.branch && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943'
                }}
              >
                {formik.errors.branch}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={(isTransaction ? incomes : expenses) || []}
              onChange={(_e, newValue) => {
                formik.setFieldValue('category', newValue ? newValue : '');
              }}
              value={
                (isAddTransaction ? incomes : expenses)?.find(
                  (category) => category.id === formik.values?.category?._id
                ) || formik.values.category
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.title || ''}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.label.category')}
                />
              )}
            />
            {!!formik.errors.category && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943'
                }}
              >
                {formik.errors.category}
              </Typography>
            )}
          </Grid>

          {isTitle && (
            <Grid item xs={12}>
              <OmInput
                formik={formik}
                label={t('inputs.label.title')}
                name="title"
              />
            </Grid>
          )}

          {(categorySlug === 'student' || categorySlug === 'salary') && (
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                options={categoryData || []}
                onChange={(_e, newValue) => {
                  formik.setFieldValue('relatedTo', newValue ? newValue : '');
                }}
                value={formik.values.relatedTo || null}
                getOptionLabel={(option) =>
                  `${option.fullName} (${option.code}) `
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    {...params}
                    label={t('inputs.label.relatedTo')}
                  />
                )}
              />

              {!!formik.errors.relatedTo && (
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: '#FF1943'
                  }}
                >
                  {formik.errors.relatedTo}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <OmInput
              required
              formik={formik}
              label={t('inputs.label.amount')}
              name="amount"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              endAdornment={
                <InputAdornment position="end">
                  <Manat />
                </InputAdornment>
              }
            />
          </Grid>

          {can('see-hidden-button') && (
            <Grid item xs={12}>
              <Box display={'flex'} flexDirection={'column'}>
                <FormControlLabel
                  sx={{ flex: 1, whiteSpace: 'nowrap' }}
                  label={`${t('inputs.label.hiddenTransaction')}`}
                  control={
                    <Checkbox
                      onChange={formik.handleChange}
                      name="hidden"
                      id="hidden"
                    />
                  }
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              error={
                formik.touched.paymentMethod &&
                Boolean(formik.errors.paymentMethod)
              }
            >
              <FormLabel component="legend">
                {t('inputs.label.paymentMethod')}
              </FormLabel>
              <RadioGroup
                row
                aria-label="paymentMethod"
                name="paymentMethod"
                value={formik.values.paymentMethod}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="cash"
                  control={<Radio />}
                  label={`${t('inputs.label.cash')}`}
                />
                <FormControlLabel
                  value="online"
                  control={<Radio />}
                  label={`${t('inputs.label.online')}`}
                />
              </RadioGroup>
              {formik.touched.paymentMethod &&
                Boolean(formik.errors.paymentMethod) && (
                  <FormHelperText sx={{ ml: 0 }}>
                    {formik.errors.paymentMethod}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ flex: 1, whiteSpace: 'nowrap', marginBottom: '1rem' }}
              label={String(t('inputs.label.differentTime'))}
              control={
                <Checkbox
                  onChange={formik.handleChange}
                  name="differentTime"
                  id="differentTime"
                />
              }
            />
            {formik.values.differentTime && (
              <OmDesktopDatePicker
                formik={formik}
                label={String(t('inputs.label.realDate'))}
                name="realDate"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.label.description')}
              name="description"
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <SubmitButton
              load={isSubmitting}
              sx={{ width: { xs: '100%', md: 200 }, mt: 2 }}
            >
              {isAddTransaction
                ? t('buttons.addTransaction')
                : t('buttons.addExpense')}
            </SubmitButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
