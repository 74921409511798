import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { useSearchParams } from 'react-router-dom';
import { employeesMapper } from '../mappers';
import { getUsersForRoleService } from 'src/modules/user-services/services';

export const useEmployees = function (paginated = true) {
  const [searchParams] = useSearchParams();
  const { page, limit } = useContext(TableContext);

  const queryParams = {
    q: searchParams.get('q'),
    archived: searchParams.get('archived')
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      'employees-get-all',
      'employee',
      { page, limit, paginated, ...queryParams }
    ],
    queryFn: getUsersForRoleService,
    select: ({ data }) => {
      const mappedEmployees = employeesMapper(data);
      if (data?.status === 'success') {
        return mappedEmployees;
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
