import { Outlet } from 'react-router';
import { PackagesContainer } from '../containers/packages-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Packages() {
  return (
    <TableContextProvider>
      <PackagesContainer />
      <Outlet />
    </TableContextProvider>
  );
}
