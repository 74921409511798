import { muiDateToString } from 'src/shared/utils/helpers';

export const addAndExpenseTransactionMapper = function (
  values: any,
  type
): any {
  const add = {
    title: values.title === '' ? values.relatedTo?.name : values.title,
    amount: Number(values.amount),
    description: values.description,
    category: values.category?.id,
    isIncome: true,
    hidden: values.hidden,
    realDate: muiDateToString(values.realDate),
    paymentMethod: values.paymentMethod,
    branch: values.branch?.id,
    relatedTo: values.relatedTo.id
  };

  const expense = {
    ...add,
    isIncome: false,
    realDate: muiDateToString(values.realDate)
  };

  return type === 'income' ? add : expense;
};
