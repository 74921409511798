import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { weekdaysMaker } from 'src/shared/utils/weekdays-format';

export const GroupDetailsForm = ({ data }: { data: any }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px'
              }}
            >
              {t('inputs.label.name')}: {data?.name}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px'
              }}
            >
              {t('inputs.label.groupCode')}: {data?.code}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px'
              }}
            >
              {t('inputs.label.branch')}: {data?.branch?.name}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px'
              }}
            >
              {t('inputs.label.weekdays')}: {data?.weekdays.join('-')}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
