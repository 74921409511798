import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  Edit as EditIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useMakeDeleteUser } from '../hooks/use-make-delete-user';
import { useArchiveUser } from '../hooks/use-archive-user';

const roleToPath = {
  student: '/dashboard/students/edit-student',
  employee: '/dashboard/employees/edit-employee',
  guardian: '/dashboard/guardians/edit-guardian'
};

export const UsersTableActionButtons: FC<{
  user;
}> = ({ user }) => {
  const { t } = useTranslation();
  const { makeDeleteUser } = useMakeDeleteUser();
  const { archiveUser } = useArchiveUser();
  const { dispatch } = useContext(ConfirmationModalContext);

  const [url, setUrl] = useState(`/dashboard/users/edit-user/${user?.id}`);

  useEffect(() => {
    const role = user?.roles.find((role) => role in roleToPath);
    if (role) {
      setUrl(`${roleToPath[role]}/${user?.id}`);
    }
  }, [user]);

  return (
    <>
      <Typography noWrap>
        {/* <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/users/${user.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton component={Link} to={url} color="primary">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={t(
            `${
              user.archived ? 'tooltips.unArchiveUser' : 'tooltips.archiveUser'
            }`
          )}
          arrow
        >
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    archiveUser({
                      type: 'users',
                      genericId: user.id,
                      isArchived: user.archived
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t(
                    `${
                      user.archived
                        ? 'messages.unArchiveUser'
                        : 'messages.archiveUser'
                    }`
                  ),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'primary'
                }
              });
            }}
            color="primary"
          >
            {user.archived ? (
              <UnarchiveOutlinedIcon fontSize="small" />
            ) : (
              <ArchiveOutlinedIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteUser')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteUser({
                      type: 'users',
                      genericId: user.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteUser'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
