import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericService } from 'src/shared/services/generic-services';
import { TableContext } from 'src/shared/contexts/table-context';

export const useRecurrences = (paginated = true) => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const branch = searchParams.get('branch');

  const { page, limit } = useContext(TableContext);

  const { data, isLoading } = useQuery({
    queryKey: [
      'recurrences-get-all',
      'recurrences',
      { page, limit, q, branch, paginated }
    ],
    queryFn: getGenericService,
    select: ({ data }: { data: any }) => {
      if (data?.status === 'success') {
        return data;
      }
    },
    onError: queryError
  });

  return {
    data,
    isLoading
  };
};
