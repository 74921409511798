import { Branch } from '../pages/branch';
import { BranchDetails } from '../pages/branch-details';
import { Branches } from '../pages/branches';

const branchesRouter = {
  path: 'branches',
  children: [
    {
      path: '',
      element: <Branches />,
      children: []
    },
    {
      path: ':branchId',
      element: <BranchDetails />
    },
    {
      path: 'add-branch',
      element: <Branch />,
      children: []
    },
    {
      path: 'edit-branch/:branchId',
      element: <Branch />
    }
  ]
};

export default branchesRouter;
