import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  LaunchTwoTone as LaunchTwoToneIcon,
  Edit as EditIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon
} from '@mui/icons-material';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { Link } from 'react-router-dom';
import { useMakeDeleteGuardian } from '../hooks/use-make-delete-guardian';
import { useArchiveGuardian } from '../hooks/use-archive-guardian';

export const GuardiansTableActionButtons: FC<{
  guardian;
}> = ({ guardian }) => {
  const { t } = useTranslation();
  const { makeDeleteGuardian } = useMakeDeleteGuardian();
  const { archiveGuardian } = useArchiveGuardian();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/guardians/${guardian.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/guardians/edit-guardian/${guardian.id}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={t(
            `${
              guardian.archived
                ? 'tooltips.unArchiveGuardian'
                : 'tooltips.archiveGuardian'
            }`
          )}
          arrow
        >
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    archiveGuardian({
                      type: 'users',
                      genericId: guardian.id,
                      isArchived: guardian.archived
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t(
                    `${
                      guardian.archived
                        ? 'messages.unArchiveGuardian'
                        : 'messages.archiveGuardian'
                    }`
                  ),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'primary'
                }
              });
            }}
            color="primary"
          >
            {guardian.archived ? (
              <UnarchiveOutlinedIcon fontSize="small" />
            ) : (
              <ArchiveOutlinedIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteGuardian')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteGuardian({
                      type: 'users',
                      genericId: guardian.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteGuardian'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
