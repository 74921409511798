import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useBranchForm } from '../hooks/use-branch-form';
import { BranchForm } from '../components/branch-form';
import { styled } from '@mui/material';
import LoadingCircle from 'src/shared/components/Loading-circle';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const BranchContainer = function () {
  const { t } = useTranslation();
  const { formik, isSubmitting, managersData, branchId, data } =
    useBranchForm();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('branches.name'),
      href: '/dashboard/branches'
    },
    {
      label: t(`branches.${branchId ? 'editBranch' : 'addBranch'}`),
      href: '/dashboard/branches/add-branch'
    }
  ];

  if (!data && branchId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        branchId
          ? `${t('branches.branch')}: ${data?.id}`
          : t('branches.addBranch')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <BranchForm
            formik={formik}
            isSubmitting={isSubmitting}
            branchId={branchId}
            managersData={managersData}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
