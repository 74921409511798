import { Room } from '../pages/room';
import { RoomDetails } from '../pages/room-details';
import { Rooms } from '../pages/rooms';

const roomsRouter = {
  path: 'rooms',
  children: [
    {
      path: '',
      element: <Rooms />,
      children: []
    },
    {
      path: 'add-room',
      element: <Room />,
      children: []
    },
    {
      path: ':roomId',
      element: <RoomDetails />
    },
    {
      path: 'edit-room/:roomId',
      element: <Room />
    }
  ]
};

export default roomsRouter;
