import { Outlet } from 'react-router';
import { GroupDetailsContainer } from '../containers/group-details-container';

export const GroupDetails = () => {
  return (
    <>
      <Outlet />
      <GroupDetailsContainer />
    </>
  );
};
