import { FC, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  LaunchTwoTone as LaunchTwoToneIcon,
  Edit as EditIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon
} from '@mui/icons-material';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useMakeDeleteGroup } from '../hooks/use-make-delete-group';
import { useArchiveGroup } from '../hooks/use-archive-group';

export const GroupActionButtons: FC<{ group: any }> = ({ group }) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(ConfirmationModalContext);

  const { makeDeleteGroup } = useMakeDeleteGroup();
  const { archiveGroup } = useArchiveGroup();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/groups/${group.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/groups/edit/${group.id}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={t(
            `${
              group.archived
                ? 'tooltips.unArchiveGroup'
                : 'tooltips.archiveGroup'
            }`
          )}
          arrow
        >
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    archiveGroup({
                      type: 'groups',
                      genericId: group.id,
                      isArchived: group.archived
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t(
                    `${
                      group.archived
                        ? 'messages.unArchiveGroup'
                        : 'messages.archiveGroup'
                    }`
                  ),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'primary'
                }
              });
            }}
            color="primary"
          >
            {group.archived ? (
              <UnarchiveOutlinedIcon fontSize="small" />
            ) : (
              <ArchiveOutlinedIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteGroup')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteGroup({
                      type: 'groups',
                      genericId: group.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteGroup'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
