// @ts-nocheck
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React from 'react';

import { Controller } from 'react-hook-form';
interface FormInputProps {
  name: string;
  control: any;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  setValue?: any;
  options?: any;
}

export const FormCheckbox: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  disabled = false
}) => {
  return (
    <FormControl size={'small'} variant={'outlined'} fullWidth>
      {/* <FormLabel component="legend">{label}</FormLabel> */}
      <FormControlLabel
        control={
          <Controller
            name={name}
            render={(state) => {
              const {
                field: { onChange, value }
              } = state;
              return (
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  disabled={disabled}
                />
              );
            }}
            control={control}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormControl>
  );
};
