import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { usePackageForm } from '../hooks/use-package-form';
import { PackageForm } from '../components/package-form';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { styled } from '@mui/material';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const PackageContainer = function () {
  const { t } = useTranslation();
  const { formik, data, packageId, isSubmitting, subjectsData } =
    usePackageForm();
  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('packages.name'),
      href: '/dashboard/packages'
    },
    {
      label: t(`packages.${packageId ? 'editPackage' : 'addPackage'}`),
      href: '/dashboard/packages/add-package'
    }
  ];

  if (!data && packageId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        packageId
          ? `${t('packages.package')}: ${data?.id}`
          : t('packages.addPackage')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <PackageForm
            formik={formik}
            subjectsData={subjectsData}
            isSubmitting={isSubmitting}
            packageId={packageId}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
