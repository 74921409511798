import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { OmInput } from 'src/shared/components';
import { OmDesktopDatePicker } from 'src/shared/components/form-components/om-desktop-date-picker';
import useAuth from 'src/shared/hooks/useAuth';

export const RecurrenceForm = function ({
  formik,
  usersData,
  isSubmitting,
  priorities,
  incomeCategories,
  recurrenceId,
  expenseCategories,
  user
}) {
  const { t } = useTranslation();
  const { can } = useAuth();
  const isIncome = formik.values.isIncome === 'income';
  const recurrenceCategories = isIncome
    ? incomeCategories?.data
    : expenseCategories?.data;

  const isBranch = user?.branches?.length <= 1;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              disabled={isBranch}
              options={user?.branches || []}
              value={formik.values.branch || null}
              onChange={(_e, newValue) => {
                formik.setFieldValue('branch', newValue ? newValue : '');
              }}
              getOptionLabel={(option) => `${option.name} `}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.label.branch')}
                />
              )}
            />
            {formik.touched.branch && formik.errors.branch && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943'
                }}
              >
                {formik.errors.branch}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              error={
                formik.touched.paymentMethod &&
                Boolean(formik.errors.paymentMethod)
              }
            >
              <FormLabel component="legend">
                {t('inputs.label.recurrenceType')}
              </FormLabel>
              <RadioGroup
                row
                aria-label="isIncome"
                name="isIncome"
                value={formik.values.isIncome}
                onChange={(event, newValue) => {
                  formik.setFieldValue('isIncome', newValue);
                  formik.setFieldValue('category', null);
                }}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="income"
                  control={<Radio />}
                  label={`${t('inputs.label.income')}`}
                />
                <FormControlLabel
                  value="expense"
                  control={<Radio />}
                  label={`${t('inputs.label.expense')}`}
                />
              </RadioGroup>
              {formik.touched.paymentMethod &&
                Boolean(formik.errors.paymentMethod) && (
                  <FormHelperText sx={{ ml: 0 }}>
                    {formik.errors.paymentMethod}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <OmInput
              required
              formik={formik}
              label={t('inputs.label.title')}
              name="title"
            />
          </Grid>
          <Grid item xs={12}>
            <OmInput
              required
              formik={formik}
              label={t('inputs.label.amount')}
              name="amount"
              type="number"
              onWheel={(e: any) => e.target.blur()}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={recurrenceCategories || []}
              onChange={(_e, newValue) =>
                formik.setFieldValue('category', newValue ? newValue : '')
              }
              value={formik.values.category || null}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  {...params}
                  label={t('inputs.label.category')}
                />
              )}
            />
            {formik.touched.category && formik.errors.category && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943'
                }}
              >
                {formik.errors.category}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={usersData?.data || []}
              onChange={(_e, newValue) => {
                formik.setFieldValue('relatedTo', newValue ? newValue : '');
              }}
              value={formik.values.relatedTo || null}
              getOptionLabel={(option) =>
                `${option?.fullName}(${option?.code})`
              }
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  {...params}
                  label={t('inputs.label.relatedTo')}
                />
              )}
            />
            {formik.touched.relatedTo && formik.errors.relatedTo && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943'
                }}
              >
                {formik.errors.relatedTo}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id="priority"
              options={priorities || []}
              filterSelectedOptions
              onChange={(_e, newValue) => {
                formik.setFieldValue('priority', newValue ? newValue : '');
              }}
              value={formik.values.priority || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option?.name || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('inputs.label.priority')}
                  error={
                    formik.touched.priority && Boolean(formik.errors.priority)
                  }
                />
              )}
            />
            {!!formik.errors.priority && formik.touched.priority && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943'
                }}
              >
                {formik.errors.priority}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <OmInput
              formik={formik}
              label={t('inputs.label.periodicity')}
              name="periodicity"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <OmInput
              formik={formik}
              label={t('inputs.label.remindDuration')}
              name="remindDuration"
              type="number"
              onWheel={(e: any) => e.target.blur()}
            />
          </Grid>

          <Grid item xs={12}>
            <OmDesktopDatePicker
              formik={formik}
              label={t('inputs.label.dueDate')}
              name="dueDate"
            />
          </Grid>

          {can('see-hidden-transaction') && (
            <Grid item xs={12}>
              <Box display={'flex'} flexDirection={'column'}>
                <FormControlLabel
                  sx={{ flex: 1, whiteSpace: 'nowrap' }}
                  label={`${t('inputs.label.hiddenTransaction')}`}
                  control={
                    <Checkbox
                      onChange={formik.handleChange}
                      name="hidden"
                      id="hidden"
                    />
                  }
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              error={
                formik.touched.paymentMethod &&
                Boolean(formik.errors.paymentMethod)
              }
            >
              <FormLabel component="legend">
                {t('inputs.label.paymentMethod')}
              </FormLabel>
              <RadioGroup
                row
                aria-label="paymentMethod"
                name="paymentMethod"
                value={formik.values.paymentMethod}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="cash"
                  control={<Radio />}
                  label={`${t('inputs.label.cash')}`}
                />
                <FormControlLabel
                  value="online"
                  control={<Radio />}
                  label={`${t('inputs.label.online')}`}
                />
              </RadioGroup>
              {formik.touched.paymentMethod &&
                Boolean(formik.errors.paymentMethod) && (
                  <FormHelperText sx={{ ml: 0 }}>
                    {formik.errors.paymentMethod}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.label.description')}
              name="description"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            fullWidth
            color="primary"
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {t(`buttons.${recurrenceId ? 'update' : 'addRecurrence'}`)}
          </LoadingButton>
        </Grid>
      </Box>
    </Grid>
  );
};
