import { Outlet } from 'react-router';
import { EmployeeContainer } from '../containers/employee-container';

export const AddEmployee = function () {
  return (
    <>
      <EmployeeContainer />
      <Outlet />
    </>
  );
};
