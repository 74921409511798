const azJSON = {
  languageSwitcher: {
    title: 'Dil',
    switchLanguage: 'Dili dəyiş'
  },
  login: {
    title: 'Daxil ol',
    ourTeam: 'Bizim komandamız'
  },
  signin: {
    title: 'Giriş et',
    label: 'Hesabınıza daxil olmaq üçün aşağıdakı sahələri doldurun.'
  },
  popover: {
    profile: 'Profil',
    signOut: 'Çıxış et'
  },
  general: {
    name: 'Ümumi'
  },
  managment: { title: 'İdarə' },
  appManager: { title: 'Tətbiqin idarə edilməsi' },
  dashboard: {
    name: 'İdarə paneli'
  },
  teachers: {
    name: 'Müəllimlər'
  },
  transactions: {
    name: 'Əməliyyatlar',
    addTransaction: 'Əməliyyat əlavə et',
    addExpense: 'Xərc əlavə et',
    transaction: 'Əməliyyat'
  },
  home: {
    name: 'Əsas səhifə'
  },
  branches: {
    name: 'Filiallar',
    addBranch: 'Filial əlavə et',
    editBranch: 'Filialı redaktə et',
    branch: 'Filial'
  },
  parents: {
    name: 'Ürəkliklər',
    addParent: 'Ürəklik əlavə et'
  },
  users: {
    name: 'İstifadəçilər',
    addUser: 'İstifadəçi əlavə et',
    user: 'İstifadəçi',
    editUser: 'İstifadəçini redaktə et'
  },
  guardians: {
    name: 'Valideynlər',
    addGuardian: 'Valideyn əlavə et',
    guardian: 'Valideyn',
    editGuardian: 'Valideyni redaktə et'
  },
  students: {
    name: 'Tələbələr',
    addStudent: 'Tələbə əlavə et',
    student: 'Tələbə',
    editStudent: 'Tələbəni redaktə et'
  },
  groups: {
    name: 'Qruplar',
    addGroup: 'Qrup əlavə et',
    group: 'Qrup',
    editGroup: 'Qrupu redaktə et'
  },
  employees: {
    name: 'Əməkdaşlar',
    addEmployee: 'Əməkdaş əlavə et',
    employee: 'Əməkdaş',
    editEmployee: 'Əməkdaşı redaktə et'
  },
  managePermissions: {
    name: 'İcazələri idarə et'
  },
  lessons: {
    name: 'Dərslər',
    addLesson: 'Dərs əlavə et',
    editLesson: 'Dərsi redaktə et',
    lesson: 'Dərs'
  },
  exams: {
    name: 'İmtahanlar',
    addExam: 'İmtahan əlavə et',
    exam: 'İmtahan',
    editExam: 'İmtahanı redaktə et'
  },
  feedbacks: {
    name: 'Feedbacklər',
    addFeedback: 'Feedback əlavə et',
    feedback: 'Feedback',
    editFeedback: 'Feedbacki redaktə et'
  },
  recurrences: {
    name: 'Təkrarlar',
    addRecurrence: 'Təkrar əlavə et',
    recurrence: 'Təkrar',
    editRecurrence: 'Təkrarı redaktə et'
  },
  subjects: {
    name: 'Mövzular',
    addSubject: 'Mövzu əlavə et',
    subject: 'Mövzu',
    editSubject: 'Mövzunu redaktə et'
  },
  packages: {
    name: 'Paketlər',
    addPackage: 'Paket əlavə et',
    package: 'Paket',
    editPackage: 'Paketi redaktə et'
  },
  rooms: {
    name: 'Otaqlar',
    addRoom: 'Otaq əlavə et',
    room: 'Otaq',
    editRoom: 'Otaqı redaktə et'
  },
  upperCategories: {
    name: 'Üst kateqoriyalar',
    addUpperCategory: 'Üst kateqoriya əlavə et',
    upperCategory: 'Üst kateqoriya',
    editUpperCategory: 'Üst kateqoriyanı redaktə et'
  },
  lowerCategories: {
    name: 'Alt kateqoriyalar',
    addLowerCategory: 'Alt kateqoriya əlavə et',
    lowerCategory: 'Alt kateqoriya',
    editLowerCategory: 'Alt kateqoriyanı redaktə et'
  },
  maintenance: {
    name: 'Texniki dəstək'
  },
  addTransaction: {
    name: 'Əməliyyat əlavə et'
  },

  table: {
    fullName: 'Tam ad',
    rowsOrder: '№',
    code: 'Kod',
    email: 'E-poçt',
    actions: 'Əməliyyatlar',
    date: 'Tarix',
    name: 'Ad',
    time: 'Zaman',
    isTought: 'Tələbə',
    price: 'Qiymət',
    subjects: 'Mövzular',
    branch: 'Filial',
    number: 'Nömrə',
    size: 'Həcm',
    category: 'Kateqoriya',
    paymentMethod: 'Ödəniş metod',
    teacherCode: 'Müəllim kodu',
    title: 'Başlıq',
    amount: 'Miqdar',
    schoolAmount: 'Məktəb miqdarı',
    executor: 'İcraçı',
    company: 'Şirkət',
    address: 'Ünvan',
    manager: 'Menecer',
    room: 'Otaq',
    slug: 'Slug',
    teacher: 'Müəllim',
    group: 'Qrup',
    topic: 'Mövzu',
    content: 'Məzmun',
    type: 'Növ',
    status: 'Status',
    students: 'Tələbələr',
    executed: 'İcra olunub',
    guardian: 'Vəli',
    balanceBefore: 'Əvvəlki balans',
    balanceAfter: 'Sonrakı balans',
    notExecutedYet: 'Hələ icra edilməyib',
    subject: 'Mövzu'
  },

  inputs: {
    label: {
      price: 'Qiymət',
      filter: 'Filter',
      name: 'Ad',
      surname: 'Soyad',
      number: 'Nömrə',
      email: 'E-poçt',
      gender: 'Cins',
      fatherName: 'Ata adı',
      description: 'Təsvir',
      note: 'Qeyd',
      phoneNumber: 'Telefon nömrəsi',
      dateOfBirth: 'Doğum tarixi',
      roles: 'Rollar',
      role: 'Rol',
      password: 'Şifrə',
      lostPassword: 'Şifrəni unutdum',
      passwordConfirm: 'Şifrəni təsdiqlə',
      repeatPassword: 'Şifrə uyğun gəlmir',
      matchPassword: 'Yeni şifrə ilə uyğun gəlmir',
      socialMediaProfiles: 'Sosial media profiləri',
      groupDP: 'QrupDP',
      schoolAdmittionYear: 'Məktəbə qəbul il',
      guardian: 'Valideyin',
      relationship: 'Əlaqə',
      rooms: 'Paketlər',
      subject: 'Mövzu',
      date: 'Tarix',
      amount: 'Miqdar',
      company: 'Şirkət',
      address: 'Ünvan',
      manager: 'Menecer',
      search: 'Axtar',
      time: 'Zaman',
      size: 'Ölçü',
      searchByCode: 'Koda görə axtar',
      searchByName: 'Ada görə axtar',
      searchTransaction: 'Başlığa, kateqoriyaya, koda görə axtar',
      searchByCodeAndTopic: 'Koda və mövzuya görə axtar',
      balance: 'Balans',
      dateTime: 'Tarix və zaman',
      isTought: 'Təlim edilir',
      category: 'Kateqoriya',
      managers: 'Menecerlər',
      branch: 'Filial',
      chiefManager: 'Baş menecer',
      code: 'Kod',
      fullName: 'Tam ad',
      status: 'Status',
      title: 'Başlıq',
      priority: 'Prioritet',
      upperCategory: 'Üst kateqoriya',
      relatedTo: 'Əlaqəli',
      cash: 'Nağd',
      online: 'Onlayn',
      paymentMethod: 'Ödəniş metod',
      newPaymentMethod: 'Yeni ödəniş metod',
      realDate: 'Həqiqi tarix',
      hiddenTransaction: 'Gizli əməliyyat',
      newRealDate: 'Yeni həqiqi tarix',
      differentTime: 'Fərqli bir zaman üçün ödəniş qeydiyyatına girişin',
      teacher: 'Müəllim',
      group: 'Qrup',
      isForeign: 'Xarici',
      packages: 'Paketlər',
      topic: 'Mövzu',
      room: 'Otaq',
      groupCode: 'Qrup kodu',
      content: 'Məzmun',
      lesson: 'Dərs',
      student: 'Tələbə',
      weekdays: 'Həftə günü',
      students: 'Tələbələr',
      periodicity: 'Dövrəlilik',
      remindDuration: 'Xatırlatma müddəti',
      dueDate: 'Bitmə tarixi',
      isIncome: 'Gəlir',
      subjects: 'Mövzular',
      type: 'Növ',
      income: 'Gəlir',
      expense: 'Xərclər',
      recurrenceType: 'Təkrar növünü seçin',
      notDeletable: 'Silinə bilməz',
      createdBy: 'Yaradan',
      transactionType: 'Əməliyyat növü',
      balanceBefore: 'Əvvəlki balans',
      balanceAfter: 'Sonrakı balans',
      patronymic: 'Ata adı',
      rows: 'Səhifədə sətirlər',
      teachers: 'Müəllimlər',
      male: 'Kişi',
      searchLesson: 'Koda və mövzuya görə axtar',
      female: 'Qadın',
      unknown: 'Bilinmir',
      facebook: 'Facebook',
      instagram: 'Instagram',
      linkedin: 'Linkedin',
      zoom: 'Zoom',
      facebookUrl: 'Facebook hesabının urlsi',
      instagramUrl: 'Instagram hesabının urlsi',
      linkedinUrl: 'Linkedin hesabının urlsi',
      zoomUrl: 'Zoom hesabının urlsi',
      profilImage: 'Profil Şəkli',
      changeProfilImage: 'Profil şəklinizi dəyişin',
      upload: 'Yüklə',
      currentPassword: 'Cari şifrə',
      myAccount: 'Mənim hesabım',
      aboutMe: 'Haqqımda',
      changePassword: 'Şifrəni dəyişdir',
      positions: 'Vəzifələr',
      recoverPassword: 'Şifrəni bərpa edin',
      emailForRecoverPassword:
        'Şifrəni yeniləmək üçün qeydiyyatda istifadə olunan e-poçtu daxil edin.',
      package: 'Paket',
      isExtra: 'Əlavə dərs',
      notExtra: 'Əlavə dərs deyil',
      newAmount: 'Yeni miqdar',
      executeRecurrenceRegister: 'Fərqli bir vaxt üçün ödəniş qeydiyyatı',
      executeRecurrenceLabel:
        'Əgər tarixi və ya miqdarı dəyişdirmək istəyirsinizsə, xahiş edirik aşağıdakı daxil edilənlərdən istifadə edin. Qalan hallarda nəzəriyyəyə alın.',
      archivedUsers: 'Arxiv olunmuş istifadəçilər',
      archivedGuardians: 'Arxiv olunmuş valideynlər',
      archivedStudents: 'Arxiv olunmuş tələbələr',
      archivedEmployees: 'Arxiv olunmuş əməkdaşlar',
      archivedGroups: 'Arxiv olunmuş qruplar'
    },
    options: {
      admin: 'İdarəçi',
      student: 'Tələbə',
      guardian: 'Valideyin',
      father: 'Ata',
      mother: 'Ana',
      male: 'Kişi',
      female: 'Qadın',
      unknown: 'Bilinmir'
    }
  },
  yup: {
    required: {
      title: 'Başlıq tələb olunur',
      number: 'Nömrə tələb olunur',
      guardian: 'Valideyin tələb olunur',
      address: 'Ünvan tələb olunur',
      category: 'Kateqoriya tələb olunur',
      surname: 'Soyad tələb olunur',
      amount: 'Miqdar tələb olunur',
      student: 'Tələbə tələb olunur',
      group: 'Qrup tələb olunur',
      teacher: 'Müəllim tələb olunur',
      subject: 'Mövzu tələb olunur',
      price: 'Qiymət tələb olunur',
      lesson: 'Dərs tələb olunur',
      name: 'Ad tələb olunur',
      email: 'E-poçt sahəsi tələb olunur',
      roles: 'Rollar tələb olunur',
      gender: 'Cins tələb olunur',
      relatedTo: 'Əlaqəli olduğu sahə tələb olunur',
      branch: 'Filial tələb olunur',
      room: 'Otaq tələb olunur',
      manager: 'Menecer tələb olunur',
      relationship: 'Əlaqə tələb olunur',
      password: 'Şifrə tələb olunur',
      repeatPassword: 'Şifrəni təkrar daxil edin',
      minPassword: 'Şifrə ən azı 6 simvoldan ibarət olmalıdır',
      minPhoneNumber: 'Telefon nömrəsi minimum 9 rəqəmli olmalıdır',
      maxPhoneNumber: 'Telefon nömrəsi maksimum 9 rəqəmli olmalıdır',
      validPhoneNumber: 'Telefon nömrəsi doğru deyil',
      matchPassword: 'Şifrələr uyğun gəlməlidir',
      validEmail: 'Daxil edilmiş e-poçt ünvanı düzgün olmalıdır',
      relatedToWhenCategoryIs:
        'Kateqoriya dəyəri ilə əlaqədə olduğunda əlaqəli sahə tələb olunur',
      salary: 'Maaş',
      transactionStudent: 'Tələbə',
      schoolAdmittionYear: 'Məktəbə qəbul il tələb olunur'
    },
    messages: {
      email: 'Daxil edilmiş e-poçt ünvanı düzgün olmalıdır',
      terms: 'Şərtlərimizə və qaydalarımıza razı olmalısınız',
      invalidCredentials: 'Yanlış məlumatlar, xahiş edirik yenidən daxil olun'
    }
  },
  buttons: {
    addParent: 'Vali əlavə et',
    addGuardian: 'Valideyin əlavə et',
    confirm: 'Təsdiq et',
    cancel: 'Ləğv et',
    addStudent: 'Tələbə əlavə et',
    addEmployee: 'İşçi əlavə et',
    addLesson: 'Dərs əlavə et',
    addExam: 'Imtahan əlavə et',
    addSubject: 'Mövzu əlavə et',
    addPackage: 'Paket əlavə et',
    addBranch: 'Filial əlavə et',
    editBranch: 'Filialı redaktə et',
    addRoom: 'Otaq əlavə et',
    addTransaction: 'Əməliyyat əlavə et',
    addExpense: 'Xərc əlavə et',
    add: 'Əlavə et',
    edit: 'Redaktə et',
    addGroup: 'Qrup əlavə et',
    addLowerCategory: 'Aşağı kateqoriya əlavə et',
    editLesson: 'Dərsi redaktə et',
    addUpperCategory: 'Yuxarı kateqoriya əlavə et',
    addFeedback: 'Əks etmə əlavə et',
    addRecurrence: 'Təkrar əlavə et',
    editGroup: 'Qrupu redaktə et',
    addTeacher: 'Müəllim əlavə et',
    editRecurrence: 'Təkrarı redaktə et',
    execute: 'İcra et',
    managePermissions: 'İcazələri idarə et',
    editRoom: 'Otaqı redaktə et',
    update: 'Yenilə',
    submit: 'Göndər',
    sendMe: 'Mənə yeni şifrə göndər',
    clickHere: 'Bura vurun',
    addUser: 'İstifadəçi əlavə et',
    yes: 'Bəli',
    no: 'Xeyr',
    all: 'Hamısı',
    continueToLogin: 'Davam edin və daxil olun'
  },
  alerts: {
    passwordReset: 'Şifrə bərpa etmə təlimatları e-poçtunuza göndərildi'
  },
  tooltips: {
    view: 'Baxış',
    edit: 'Redaktə et',
    remove: 'Sil',
    archiveUser: 'İstifadəçini arxivlə',
    unArchiveUser: 'İstifadəçini arxivdən çıxar',
    archiveGuardian: 'Valideyini arxivlə',
    unArchiveGuardian: 'Valideyini arxivdən çıxar',
    archiveStudent: 'Tələbəni arxivlə',
    unArchiveStudent: 'Tələbəni arxivdən çıxar',
    archiveLesson: 'Dərsi arxivlə',
    archiveEmployee: 'İşçini arxivlə',
    unArchiveEmployee: 'İşçini arxivdən çıxar',
    archiveExam: 'İmtahanı arxivlə',
    archiveSubject: 'Mövzunu arxivlə',
    archiveRoom: 'Otağı arxivlə',
    archiveBranch: 'Filialı arxivlə',
    validEmail: 'Göstərilmiş e-poçt ünvanı doğru olmalıdır',
    archiveGroup: 'Qrupu arxivlə',
    unArchiveGroup: 'Qrupu arxivdən çıxar',
    archiveUpperCategory: 'Yuxarı kateqoriyanı arxivlə',
    archiveLowerCategory: 'Aşağı kateqoriyanı arxivlə',
    deleteGroup: 'Qrupu sil',
    deleteExam: 'İmtahanı sil',
    deleteLesson: 'Dərsi sil',
    deleteBranch: 'Filialı sil',
    deleteSubject: 'Mövzunu sil',
    deletePackage: 'Paketi sil',
    deleteRoom: 'Otağı sil',
    deletUpperCategory: 'Yuxarı kateqoriyanı sil',
    deleteLowerCategory: 'Aşağı kateqoriyanı sil',
    deleteTransaction: 'Əməliyyatı sil',
    deleteStudent: 'Tələbəni sil',
    deleteEmployee: 'İşçini sil',
    deleteTeacher: 'Müəllimi sil',
    deleteGuardian: 'Valideyini sil',
    deleteUser: 'İstifadəçini sil',
    deleteRecurrence: 'Təkrarı sil',
    permissions: 'İcazələr',
    execute: 'İcra et',
    languageSwitcher: 'Dil seçici'
  },
  typography: {
    amount:
      'Əgər tarixi və ya məbləği dəyişdirmək istəyirsinizsə, xahiş edirik aşağıdakı sahələrdən istifadə edin. Qalan hallarda nəzəriyyəyə alın.',
    email: 'Davam etmək üçün e-poçtunuzu yoxlayın',
    currentMaintenance: 'Sayt hazırda təmir üçün bağlıdır',
    appologizeMaintenance: 'Yaradılan bütün narazılıqlar üçün özür dilerik',
    present: 'Hazır',
    absent: 'İştirak etməyib'
  },
  messages: {
    successfulProcess: 'Proses uğurla başa çatdı',
    unSuccessfulProcess: 'Proses uğursuz oldu',
    unknownError: 'Nələr olduğunu başa düşmək mümkün deyil',
    success: 'Uğurlu',
    error: 'Xəta',
    archiveUser: 'Bu istifadəçini arxivləmək istədiyinizə əminsiniz?',
    unArchiveUser: 'Bu istifadəçini arxivdən çıxarmaq istədiyinizə əminsiniz?',
    archiveGuardian: 'Bu valideyni arxivləmək istədiyinizə əminsiniz?',
    unArchiveGuardian: 'Bu valideyni arxivdən çıxarmaq istədiyinizə əminsiniz?',
    archiveStudent: 'Bu tələbəni arxivləmək istədiyinizə əminsiniz?',
    unArchiveStudent: 'Bu tələbəni arxivdən çıxarmaq istədiyinizə əminsiniz?',
    archiveEmployee: 'Bu işçini arxivləmək istədiyinizə əminsiniz?',
    unArchiveEmployee: 'Bu işçini arxivdən çıxarmaq istədiyinizə əminsiniz?',
    archiveExam: 'Bu imtahanı arxivləmək istədiyinizə əminsiniz?',
    archiveSubject: 'Bu mövzunu arxivləmək istədiyinizə əminsiniz?',
    archiveRoom: 'Otağ arxivlənir',
    archiveBranch: 'Bu filialı arxivləmək istədiyinizə əminsiniz?',
    archiveLowerCategory:
      'Bu alt kateqoriyanı arxivləmək istədiyinizə əminsiniz?',
    archiveUpperCategory:
      'Bu üst kateqoriyanı arxivləmək istədiyinizə əminsiniz?',
    archiveGroup: 'Bu qrupu arxivləmək istədiyinizə əminsiniz?',
    unArchiveGroup: 'Bu qrupu arxivdən çıxarmaq istədiyinizə əminsiniz?',
    deleteGroup: 'Bu qrupu silmək istədiyinizə əminsiniz?',
    notFound: 'Məlumat tapılmadı!',
    deleteExam: 'Bu imtahanı silmək istədiyinizə əminsiniz?',
    deleteUser: 'Bu istifadəçini silmək istədiyinizə əminsiniz?',
    deleteLesson: 'Bu dərsi silmək istədiyinizə əminsiniz?',
    deleteBranch: 'Bu filialı silmək istədiyinizə əminsiniz?',
    deleteSubject: 'Bu mövzunu silmək istədiyinizə əminsiniz?',
    deletePackage: 'Bu paketi silmək istədiyinizə əminsiniz?',
    deleteRoom: 'Bu otağı silmək istədiyinizə əminsiniz?',
    deletUpperCategory: 'Bu üst kateqoriyanı silmək istədiyinizə əminsiniz?',
    deleteLowerCategory: 'Bu alt kateqoriyanı silmək istədiyinizə əminsiniz?',
    deleteTransaction: 'Bu əməliyyatı silmək istədiyinizə əminsiniz?',
    deleteStudentFromGroup:
      'Bu tələbəni qrupdan silmək istədiyinizə əminsiniz?',
    deleteTeacherFromGroup:
      'Bu müəllimi qrupdan silmək istədiyinizə əminsiniz?',
    deleteGuardian: 'Bu qoruyucunu silmək istədiyinizə əminsiniz?',
    deleteStudent: 'Bu tələbəni silmək istədiyinizə əminsiniz?',
    deleteEmployee: 'Bu işçini silmək istədiyinizə əminsiniz?',
    deleteRecurrence: 'Bu təkrarlama işini silmək istədiyinizə əminsiniz?',
    wantToSignin: 'Yenidən giriş yoxlamaq istəyirsiniz?',
    addStudentToGroup: 'Qrupa tələbə əlavə edin',
    addTeacherToGroup: 'Qrupa müəllim əlavə edin'
  },
  modal: {
    title: {
      permissions: 'İcazələri İdarə Et'
    },
    subtitle: {
      permissions:
        'İcazələri aktivləşdirmək və ya deaktivləşdirmək üçün aşağıdakı keçid düymələrini dəyişdirin'
    }
  },
  selectInput: {
    active: 'Aktiv',
    achived: 'Arxivlənmiş'
  }
};

export default azJSON;
