import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useTranslation } from 'react-i18next';
import { makeDeleteGenericService } from 'src/shared/services/generic-services';

export const useMakeDeleteLesson = () => {
  const { t } = useTranslation();
  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();

  const { mutate: makeDeleteLesson } = useMutation(
    'make-delete-lesson',
    makeDeleteGenericService,
    {
      onSuccess: (e) => {
        if (e.status === 204) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('lessons-get-all');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    }
  );

  return { makeDeleteLesson };
};
