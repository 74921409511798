import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericService } from 'src/shared/services/generic-services';
import { TRoomsRoot } from '../type';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { roomsMapper } from '../mappers';

export const useRooms = function (paginated = true) {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  const { page, limit } = useContext(TableContext);

  const { data, isLoading } = useQuery({
    queryKey: ['rooms-get-all', 'rooms', { page, limit, name, paginated }],
    queryFn: getGenericService,
    select: ({ data }: { data: TRoomsRoot }) => {
      const mappedRooms = roomsMapper(data);
      if (data?.status === 'success') {
        return mappedRooms;
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
