import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export function GroupsPageActions() {
  const { t } = useTranslation();

  return (
    <Grid container rowSpacing={{ xs: 1 }}>
      <Grid item>
        <Button
          sx={{
            mr: { xs: 1 }
          }}
          component={Link}
          to="/dashboard/groups/add-group"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addGroup')}
        </Button>
      </Grid>
    </Grid>
  );
}
