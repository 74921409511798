import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function SubmitButton({
  load,
  children,
  size,
  sx,
  callback,
  type = 'submit'
}: {
  load: Boolean;
  children?: string;
  size?: 'large' | 'small' | 'medium';
  sx?: any;
  callback?: () => any;
  type?: any;
}) {
  return (
    <>
      {load ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '40px', width: '200px' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Button
          sx={{
            px: 4,
            ...sx
          }}
          variant="contained"
          type={type}
          size={size ? size : 'medium'}
          onClick={() => {
            callback?.();
          }}
        >
          {children}
        </Button>
      )}
    </>
  );
}
