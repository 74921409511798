import { Grid, Box, Card } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DATE_TYPES } from 'src/shared/costants/constants';

export const EmployeeDetails = ({ data }: { data: any }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sx={{ mb: 5 }}>
      <Card sx={{ p: 3 }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.code')}: {data?.code}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.fullName')}: {data?.fullName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.email')}: {data?.email}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.phoneNumber')}: {data?.phoneNumbers}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.gender')}:{' '}
                {t(`inputs.options.${data?.gender}`)}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.dateOfBirth')}:{' '}
                {moment(data?.dateOfBirth).format(DATE_TYPES.mdy)}
              </Box>
            </Grid>
            {data?.roles?.includes('teacher') && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px'
                  }}
                >
                  {t('inputs.label.subjects')}:{' '}
                  {data?.subjects?.map((item) => item.name).join(', ')}
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.roles')}:{' '}
                {data?.roles?.filter((item) => item !== 'employee').join(', ')}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.positions')}:{' '}
                {data?.positions?.map((item) => item.title).join(', ')}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.description')}: {data?.description}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};
