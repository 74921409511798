import { useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { AlertContext } from 'src/shared/contexts/Alert';
import { useSubjects } from 'src/modules/subjects/hooks/use-subjects';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { addPackageMapper } from '../mappers';
import { usePackage } from './use-package';

export const usePackageForm = function () {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const { packageId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = usePackage();
  const subjectsData = useSubjects(false);

  const { mutate: addAndEditPackageFormMutate, isLoading: isSubmitting } =
    useMutation('add-package', addAndUpdateGenericService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('packages-get-all');
          if (!packageId) navigate('/dashboard/packages');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const validationSchema = Yup.object().shape({
    price: Yup.number().required(t('yup.required.price')),
    name: Yup.string().required(t('yup.required.name'))
  });

  const formik = useFormik({
    initialValues: {
      price: data?.price || '',
      name: data?.name || '',
      subjects: data?.subjects || []
    },
    onSubmit: (values) => {
      const mappedPackage = addPackageMapper(values);
      if (values) {
        if (packageId) {
          addAndEditPackageFormMutate({
            data: mappedPackage,
            type: 'packages',
            genericId: packageId
          });
        } else {
          addAndEditPackageFormMutate({
            data: mappedPackage,
            type: 'packages'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    data,
    subjectsData,
    packageId,
    isSubmitting
  };
};
