import { UserApi, UserAuth } from '../types';

export const userAuthMapper = function (userApi: UserApi): UserAuth {
  return {
    id: userApi.id,
    code: userApi.code,
    branches: userApi.branches?.map((branch) => ({
      id: branch.id,
      name: branch.name,
      code: branch.code
    })),
    email: userApi.email,
    fullName: userApi.fullName,
    patronymic: userApi.patronymic,
    permissions: userApi.permissions?.map((permission) => permission.slug),
    profileImage: userApi.profileImage,
    roles: userApi.roles
  };
};
