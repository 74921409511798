import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTransactionsTable } from '../hooks/use-transactions-table';
import { useTransactions } from '../hooks/use-transactions';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { Box, Button, Card, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TransactionsSearch from '../components/transactions-search';
import { TableContext } from 'src/shared/contexts/table-context';
import { TransactionsPageActions } from '../components/transactions-page-actions';

export function TransactionsContainer() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { limit, page, setLimit, setPage } = useContext(TableContext);

  const { data } = useTransactions();

  const [columns, options, routes] = useTransactionsTable();

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <PageWrapper
        isPaper={false}
        routes={routes}
        title={t('transactions.name')}
        action={<TransactionsPageActions />}
      >
        <Card
          sx={{
            p: 2,
            mb: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '4px'
          }}
        >
          <Box
            onClick={handleFilter}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%', cursor: 'pointer' }}
          >
            <Typography>Filter</Typography>
            {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
            {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
          </Box>
          {isOpen && <TransactionsSearch />}
        </Card>
        <Card>
          <GenericTableV2
            rows={data?.data}
            rowsCount={data?.total}
            columns={columns}
            options={options}
            limit={limit}
            page={page}
            setPage={setPage}
            setLimit={setLimit}
          />
        </Card>
      </PageWrapper>
    </>
  );
}
