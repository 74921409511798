import { useQuery } from 'react-query';

import { queryError } from 'src/shared/utils/query-error';
import { getLowerCategoriesByUpperSlug } from 'src/shared/services/generic-services';

export const useLowerCategoriesByUpperSlug = (value) => {
  const { data } = useQuery([value], getLowerCategoriesByUpperSlug, {
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data;
      }
    },
    onError: queryError
  });

  return {
    data
  };
};
