import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { TLessonsRoot } from '../types';
import { getGenericService } from 'src/shared/services/generic-services';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { useSearchParams } from 'react-router-dom';

export const useLessons = function () {
  const [searchParams] = useSearchParams();
  const queryParams = {
    q: searchParams.get('q'),
    isExtra: searchParams.get('isExtra'),
    teacher: searchParams.get('teacher')
  };

  const { page, limit } = useContext(TableContext);
  const { data, isLoading } = useQuery({
    queryKey: ['lessons-get-all', 'lessons', { page, limit, ...queryParams }],
    queryFn: getGenericService,
    select: ({ data }: { data: TLessonsRoot }) => {
      if (data?.status === 'success') {
        return { data: data?.data, total: data?.total };
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
