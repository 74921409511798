import React from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';

export const ThemeContext = React.createContext((): void => {});

const ThemeProviderWrapper: React.FC = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
