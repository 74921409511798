import { useContext, useState } from 'react';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Link } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useGuardiansTable } from '../hooks/use-guardians-table';
import GuardianSearch from '../components/guardian-search';
import { useGuardians } from '../hooks/use-guardians';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { TableContext } from 'src/shared/contexts/table-context';

export const GuardiansContainer = () => {
  const { limit, setLimit, page, setPage } = useContext(TableContext);
  const { t } = useTranslation();
  const { data } = useGuardians();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [columns, options, routes] = useGuardiansTable();
  const handleFilter = () => {
    setIsOpen((s) => !s);
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('guardians.name')}
      action={
        <Link
          to="/dashboard/guardians/add-guardian"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addGuardian')}</Button>
        </Link>
      }
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px'
        }}
      >
        <Box
          onClick={handleFilter}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>{t('inputs.label.filter')}</Typography>
          {!isOpen ? (
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />
          )}
        </Box>
        {isOpen && <GuardianSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data?.data}
          rowsCount={data?.total}
          columns={columns}
          options={options}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
        />
      </Card>
    </PageWrapper>
  );
};
