import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { AddEditRoomForm } from '../components/add-edit-room-form';
import { useRoomForm } from '../hooks/use-room-form';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { styled } from '@mui/material';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const RoomContainer = function () {
  const { t } = useTranslation();
  const { formik, data, isSubmitting, roomId, user } = useRoomForm();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('rooms.name'),
      href: '/dashboard/rooms'
    },
    {
      label: t(`rooms.${roomId ? 'editRoom' : 'addRoom'}`),
      href: '/dashboard/parents/add-room'
    }
  ];

  if (!data && roomId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={roomId ? `${t('rooms.room')}: ${data?.id}` : t('rooms.addRoom')}
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <AddEditRoomForm
            formik={formik}
            isSubmitting={isSubmitting}
            roomId={roomId}
            user={user}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
