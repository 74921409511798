import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { TPackagesRoot } from 'src/modules/packages/type';
import { getGenericService } from 'src/shared/services/generic-services';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';

export const usePackages = function (paginated = true) {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  const { page, limit } = useContext(TableContext);
  const { data, isLoading } = useQuery({
    queryKey: [
      'packages-get-all',
      'packages',
      { page, limit, name, paginated }
    ],
    queryFn: getGenericService,
    select: ({ data }: { data: TPackagesRoot }) => {
      if (data?.status === 'success') {
        return { data: data?.data, total: data?.total };
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
