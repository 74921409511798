import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { Link } from 'react-router-dom';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useMakeDeleteSubject } from '../hooks/use-make-delete-subject';

export const SubjectsTableActionButtons: FC<{
  subject;
}> = ({ subject }) => {
  const { t } = useTranslation();
  const { makeDeleteSubject } = useMakeDeleteSubject();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/subjects/${subject.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            color="primary"
            component={Link}
            to={`/dashboard/subjects/edit/${subject.id}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.deleteSubject')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteSubject({
                      type: 'subjects',
                      genericId: subject.id
                    });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteSubject'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
