import { Outlet } from 'react-router';
import { BranchesContainer } from '../containers/branches-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Branches() {
  return (
    <TableContextProvider>
      <BranchesContainer />
      <Outlet />
    </TableContextProvider>
  );
}
