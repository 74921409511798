import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OmInput } from 'src/shared/components';
import { LoadingButton } from '@mui/lab';
import { IRoomDetails } from 'src/modules/rooms/type';

export const GroupForm = ({
  data,
  formik,
  groupId,
  roomsData,
  studentsData,
  teachersData,
  isSubmitting,
  user
}) => {
  const { t } = useTranslation();

  const isBranch = user?.branches?.length <= 1;
  const rooms = roomsData?.data?.filter(
    (room) => room.branch.id === formik.values?.branch?.id
  );

  return (
    <Box component="form" onSubmit={formik?.handleSubmit}>
      <Grid
        container
        spacing={2}
        sx={{
          p: 3
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.name')}
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            fullWidth
            disabled={isBranch}
            options={user?.branches || []}
            value={formik.values.branch || null}
            onChange={(_e, newValue) => {
              formik.setFieldValue('branch', newValue ? newValue : '');
              formik.setFieldValue('room', '');
            }}
            getOptionLabel={(option) => `${option.name} `}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                required
                fullWidth
                name="branch"
                {...params}
                label={t('inputs.label.branch')}
              />
            )}
          />
          {!!formik.touched.branch && formik.errors.branch && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.branch}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            fullWidth={true}
            options={rooms || []}
            disablePortal
            onChange={(_e, newValue: IRoomDetails) => {
              formik.setFieldValue('room', newValue ? newValue?.id : '');
            }}
            value={
              roomsData?.data.find((room) => room.id === formik.values.room) ||
              null
            }
            getOptionLabel={(option: IRoomDetails) => {
              return ` ${option.number} (${option?.branch?.name})`;
            }}
            renderInput={(params) => (
              <TextField
                required
                fullWidth
                {...params}
                label={t('rooms.room')}
              />
            )}
          />
          {!!formik.errors.room && formik.touched.room && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.room}
            </Typography>
          )}
        </Grid>
        {!groupId && (
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={teachersData?.data || []}
              multiple
              disablePortal
              disabled={false}
              value={[...formik.values?.teachers]}
              filterSelectedOptions
              disableClearable
              onChange={(_e, newValue) => {
                formik.setFieldValue('teachers', newValue);
              }}
              getOptionLabel={(option: any) => {
                return `${option?.fullName}(${option?.code})`;
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.label.teachers')}
                  error={
                    formik.touched.teachers && Boolean(formik.errors.teachers)
                  }
                />
              )}
            />
          </Grid>
        )}
        {!groupId && (
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={studentsData?.data || []}
              multiple
              disablePortal
              value={[...formik.values?.students]}
              disabled={false}
              disableClearable
              filterSelectedOptions
              onChange={(_e, newValue) => {
                formik.setFieldValue('students', newValue);
              }}
              getOptionLabel={(option: any) => {
                return `${option?.fullName} (${option?.code})`;
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.label.students')}
                  error={
                    formik.touched.students && Boolean(formik.errors.students)
                  }
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            component="fieldset"
            error={formik.touched.weekdays && Boolean(formik.errors.weekdays)}
          >
            <FormLabel component="legend">
              {t('inputs.label.weekdays')}
            </FormLabel>
            <RadioGroup
              row
              aria-label="weekdays"
              name="weekdays"
              value={formik.values.weekdays}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <FormControlLabel value="135" control={<Radio />} label="1-3-5" />
              <FormControlLabel value="246" control={<Radio />} label="2-4-6" />
            </RadioGroup>
            {formik.touched.paymentMethod &&
              Boolean(formik.errors.paymentMethod) && (
                <FormHelperText sx={{ ml: 0 }}>
                  {formik.errors.paymentMethod}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Grid item xs={12} sm={3} md={2}>
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              variant="contained"
              fullWidth
              color="primary"
            >
              {t(`buttons.${groupId ? 'update' : 'addGroup'}`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
