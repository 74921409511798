import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput
} from '@mui/material';

type OmInputProps = {
  formik: any;
  label: string;
  name: string;
  type?: string;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  readOnly?: boolean;
  endAdornment?: any;
  startAdornment?: any;
  onWheel?: (e: any) => {};
  onKeyUp?: (e: any) => void;
  disabled?: boolean;
  placeholder?: string;
};

export const OmInput: React.FC<OmInputProps> = ({
  formik,
  label,
  name,
  type = 'text',
  multiline = false,
  rows = 1,
  required,
  readOnly,
  endAdornment,
  startAdornment,
  onWheel = (e: any) => {},
  onKeyUp = (e: any) => {},
  disabled,
  placeholder = false
}) => {
  return (
    <FormControl
      required={required}
      variant="outlined"
      fullWidth
      error={formik.touched[name] && Boolean(formik.errors[name])}
      sx={
        type === 'number'
          ? {
              '& input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none'
              }
            }
          : {}
      }
    >
      <InputLabel htmlFor="title">{label}</InputLabel>
      <OutlinedInput
        multiline={multiline}
        rows={rows}
        id={name}
        label={label}
        type={type}
        readOnly={readOnly}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        {...formik.getFieldProps(name)}
        onBlur={() => formik.handleBlur.bind(formik)}
        onWheel={onWheel}
        onKeyUp={onKeyUp}
        disabled={disabled}
        placeholder={placeholder}
      />
      {formik.touched[name] && Boolean(formik.errors[name]) && (
        <FormHelperText sx={{ ml: 0 }}>{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};
