import { Box, Badge, styled, useTheme, css } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    display: flex;
    text-decoration: none;
    width: 53px;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: ${theme.typography.fontWeightBold};
  `
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 122px;
        height: 38px;

`
);

const StyledBox = styled(Box)(
  () => css`
    width: 180px;
    height: 60px;
    margin-left: -50px;
  `
);

const StyledLogoWrapper = styled(LogoWrapper)(() => css``);

function Logo() {
  const theme = useTheme();

  return (
    <StyledLogoWrapper to="/overview">
      <Badge
        sx={{
          '.MuiBadge-badge': {
            fontSize: theme.typography.pxToRem(10),
            right: 6,
            top: 11,
            background: '#7E6AFF'
          }
        }}
        overlap="circular"
        color="success"
        badgeContent={process.env.REACT_APP_VERSION}
      >
        <LogoSignWrapper>
          <StyledBox>
            <img
              src={process.env.REACT_APP_LOGO_PRIMARY_URL}
              alt="logo"
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            />
          </StyledBox>
        </LogoSignWrapper>
      </Badge>
    </StyledLogoWrapper>
  );
}

export default Logo;
