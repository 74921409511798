import { User } from '../pages/user';
import { UserDetails } from '../pages/user-details';
import { Users } from '../pages/users';

const usersRouter = {
  path: 'users',
  children: [
    {
      path: '',
      element: <Users />,
      children: []
    },
    // {
    //   path: 'add-user',
    //   element: <User />,
    //   children: []
    // },
    {
      path: ':userId',
      element: <UserDetails />,
      children: []
    },
    {
      path: 'edit-user/:userId',
      element: <User />,
      children: []
    }
  ]
};

export default usersRouter;
