import { FC } from 'react';

import { Typography } from '@mui/material';

import fixDecimal from 'src/shared/utils/fix-decimal';

export const AmountTransactionsTable: FC<{ amount: number; type: boolean }> = ({
  amount,
  type
}) => {
  const amountString = fixDecimal(amount);
  const color = type ? 'rgb(20, 184, 166)' : 'rgb(209, 67, 67)';

  return (
    <Typography style={{ color: color, fontWeight: 'bold' }}>
      {type ? `${amountString}` : `${amountString}`}
    </Typography>
  );
};
