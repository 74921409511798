import { useQuery } from 'react-query';
import { getGenericService } from 'src/shared/services/generic-services';
import { queryError } from 'src/shared/utils/query-error';
import { TTransactionsRoot } from '../types';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';

export const useTransactions = (paginated = true) => {
  const [searchParams] = useSearchParams();

  const q = searchParams.get('q');
  const category = searchParams.get('category');
  const branch = searchParams.get('branch');
  const createdBy = searchParams.get('createdBy');
  const paymentMethod = searchParams.get('paymentMethod');
  const relatedTo = searchParams.get('relatedTo');
  const isIncome = searchParams.get('transactionType');

  const { page, limit } = useContext(TableContext);

  const { data } = useQuery({
    queryKey: [
      'transactions-get-all',
      'transactions',
      {
        page,
        limit,
        q,
        category,
        branch,
        createdBy,
        paymentMethod,
        relatedTo,
        isIncome,
        paginated
      }
    ],
    queryFn: getGenericService,
    select: ({ data }: { data: TTransactionsRoot }) => {
      if (data?.status === 'success') {
        return { data: data?.data, total: data.total };
      }
    },

    onError: queryError
  });

  return {
    data
  };
};
