import { Outlet } from 'react-router';
import { RecurrencesContainer } from '../containers/recurrences-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Recurrences() {
  return (
    <TableContextProvider>
      <RecurrencesContainer />
      <Outlet />
    </TableContextProvider>
  );
}
