import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { GroupActionButtons } from '../components/group-action-buttons';
import { Box } from '@mui/material';
import { IGroup } from '../types';
import UsersAvatarsList from 'src/shared/components/users-avatars';
import { IUserAvatarTiny } from 'src/modules/users/type';

export const useGroupsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('groups.name'),
      href: '/dashboard/groups'
    }
  ];
  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      // {
      //   id: 'code',
      //   align: 'left',
      //   label: t('table.code'),
      //   extructor: (group: any) => group?.code
      // },
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (group: any) =>
          `${group?.name} (${group?.weekdays.split('').join('-')})`
      },
      {
        id: 'room',
        align: 'left',
        label: t('table.room'),
        extructor: (group: any) => group?.room
      },
      {
        id: 'students',
        align: 'center',
        label: t('table.students'),
        extructor: (group: IGroup) => group?.students,
        render: (students: IUserAvatarTiny[]) => {
          return (
            <Box display="flex" justifyContent="center">
              <UsersAvatarsList users={students} />
            </Box>
          );
        }
      },
      {
        id: 'actions',
        label: t('table.actions'),
        align: 'right',
        extructor: (group: any) => {
          return <GroupActionButtons group={group} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
