import { Outlet } from 'react-router';
import { StudentContainer } from '../containers/student-container';

export const Student = function () {
  return (
    <>
      <StudentContainer />
      <Outlet />
    </>
  );
};
