import { Recurrence } from '../pages/recurrence';
import { RecurrenceDetails } from '../pages/recurrence-details';
import { Recurrences } from '../pages/recurrences';

const recurrencesRouter = {
  path: 'recurrences',
  children: [
    {
      path: '',
      element: <Recurrences />,
      children: []
    },

    {
      path: ':recurrenceId',
      element: <RecurrenceDetails />
    },
    {
      path: 'add-recurrence',
      element: <Recurrence />,
      children: []
    },
    {
      path: 'edit-recurrence/:recurrenceId',
      element: <Recurrence />
    }
  ]
};

export default recurrencesRouter;
