import { Box, Typography, Container, styled, css, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';
import Logo from '../../../shared/components/logo-sign';

const MainContent = styled(Box)(
  () => css`
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 8rem;
  `
);

const StyledContainer = styled(Container)(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `
);

const StyledBox = styled(Box)(
  () => css`
    padding-bottom: 120px;
    width: 35rem;
  `
);
function StatusMaintenance() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('maintenance.name')}</title>
      </Helmet>
      <MainContent>
        <StyledContainer maxWidth="md">
          <Logo />
          <StyledBox textAlign="center">
            <Container maxWidth="xs">
              <Typography
                variant="h2"
                sx={{
                  mt: 4,
                  mb: 2,
                  width: '27rem'
                }}
              >
                {t('typography.currentMaintenance')}
              </Typography>
              <Typography
                variant="h3"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 4,
                  width: '27rem'
                }}
              >
                {t('typography.appologizeMaintenance')}
              </Typography>
            </Container>
            <Grid item sx={{ marginLeft: '50px' }}>
              <img alt="Maintenance" height={250} src="/undraw_monitor.svg" />
            </Grid>
          </StyledBox>
        </StyledContainer>
      </MainContent>
    </>
  );
}

export default StatusMaintenance;
