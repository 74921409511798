import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Box
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ManagePermissionsActionButtons } from './action-buttons';
import { useTranslation } from 'react-i18next';
import { useUpdateUserPermissions } from '../hooks/use-update-user-permissions';
import { useEffect, useRef } from 'react';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { LoadingButton } from '@mui/lab';

export const PermissionsModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { employeeId } = useParams();
  const { pathname } = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const isModalOpen = pathname.includes('permissions');

  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      if (location.pathname.includes('permissions')) {
        navigate(`/dashboard/employees`);
      }
    });
  }, [location.pathname]);

  const handleModalClose = () => {
    navigate(`/dashboard/employees/${employeeId}`);
  };

  const { data, formik, isLoading } = useUpdateUserPermissions();

  if (!data) {
    return (
      <Grid container justifyContent="center">
        <LoadingCircle />
      </Grid>
    );
  }
  return (
    <Dialog
      fullWidth
      ref={modalRef}
      maxWidth="sm"
      open={isModalOpen}
      onClose={handleModalClose}
    >
      <DialogTitle
        sx={{
          p: 3,
          borderBottom: '1px solid #d9dce1',
          marginBottom: '9px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography variant="h3" paddingBottom="7px" gutterBottom>
          {t('modal.title.permissions')}
        </Typography>
        <Typography variant="subtitle1">
          {t(t('modal.subtitle.permissions'))}
        </Typography>
      </DialogTitle>
      <Box component={'form'} onSubmit={formik.handleSubmit}>
        {data?.data?.map((permission, i) => (
          <DialogContent
            key={i}
            dividers
            sx={{
              p: 1.7,
              borderTop: '0'
            }}
          >
            <Grid
              container
              spacing={3}
              justifyContent={'space-between'}
              sx={{
                padding: '0'
              }}
            >
              <Grid
                item
                xs={12}
                lg={7}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}
              >
                {permission?.title}
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <ManagePermissionsActionButtons
                  permission={permission}
                  formik={formik}
                />
              </Grid>
            </Grid>
          </DialogContent>
        ))}
        <DialogActions
          sx={{
            p: 3
          }}
        >
          <Button color="secondary" onClick={handleModalClose}>
            {t('Cancel')}
          </Button>

          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            fullWidth
            color="primary"
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {data ? t('buttons.update') : t('employees.addEmployee')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
