import PageWrapper from 'src/shared/components/page-wrapper';
import { Button, Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEmployee } from '../hooks/use-employee';
import { EmployeeDetails } from '../components/employee-details';
import { Link, useParams } from 'react-router-dom';

export const EmployeeDetailsContainer = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useEmployee();

  const { employeeId } = useParams();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('employees.name'),
      href: '/dashboard/employees'
    },
    {
      label: t('employees.employee'),
      href: '/dashboard/employees/employee'
    }
  ];
  return (
    <>
      <PageWrapper
        isPaper={false}
        routes={routes}
        title={`${t('employees.employee')}: ${data?.code ? data?.code : ''}`}
        action={
          <>
            <Link
              to={`/dashboard/employees/${employeeId}/permissions`}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="contained">
                {t('buttons.managePermissions')}
              </Button>
            </Link>
            <Link
              to={`/dashboard/employees/edit-employee/${employeeId}`}
              style={{ textDecoration: 'none' }}
            >
              <Button sx={{ ml: 1 }} variant="contained">
                {t('buttons.edit')}
              </Button>
            </Link>
          </>
        }
      >
        {isLoading ? (
          <Card
            sx={{
              height: '500px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress size={40} />
          </Card>
        ) : (
          <>
            <Grid container>
              <EmployeeDetails data={data} />
            </Grid>
          </>
        )}
      </PageWrapper>
    </>
  );
};
