import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTeachers } from 'src/modules/employees/hooks/use-teachers';

function LessonSearch() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: teachersData } = useTeachers(false);

  const formik = useFormik({
    initialValues: {
      q: '',
      isExtra: '',
      teacher: ''
    },
    onSubmit: () => {}
  });

  let q = formik.values.q;
  let isExtra = formik.values.isExtra;
  let teacher = formik.values.teacher;

  const triggerSearch = useCallback(function (q, isExtra, teacher) {
    if (q.trim() !== '') {
      searchParams.set('q', q);
    } else {
      searchParams.delete('q');
    }
    if (isExtra.trim() !== '') {
      searchParams.set('isExtra', isExtra);
    } else {
      searchParams.delete('isExtra');
    }
    if (teacher.trim() !== '') {
      searchParams.set('teacher', teacher);
    } else {
      searchParams.delete('teacher');
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(q, isExtra, teacher);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [q, isExtra, teacher]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={t('inputs.label.searchLesson')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('q')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
          fullWidth
          options={teachersData?.data || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('teacher', newValue ? newValue?.id : '')
          }
          value={
            teachersData?.data?.find((user) => {
              return user?.id === formik.values?.teacher;
            }) || null
          }
          getOptionLabel={(option) => `${option.fullName} (${option.code})`}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.label.teacher')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t('inputs.label.isExtra')}</FormLabel>
          <RadioGroup
            row
            aria-label="isIncome"
            name="isIncome"
            value={formik.values.isExtra}
            onChange={(event, newValue) => {
              formik.setFieldValue('isExtra', newValue);
            }}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value={''}
              control={<Radio />}
              label={`${t('buttons.all')}`}
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={`${t('buttons.yes')}`}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={`${t('buttons.no')}`}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default LessonSearch;
