import PageWrapper from 'src/shared/components/page-wrapper';
import { Button, Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStudent } from '../hooks/use-student';
import { StudentDetails } from '../components/student-details';
import { Link, useParams } from 'react-router-dom';

export const StudentDetailsContainer = () => {
  const { t } = useTranslation();
  const { studentId } = useParams();

  const { data, isLoading } = useStudent();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('students.name'),
      href: '/dashboard/students'
    },
    {
      label: t('students.student'),
      href: '/dashboard/students/student'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('students.student')}: ${data?.fullName ? data?.code : ''}`}
      action={
        <>
          <Link
            to={`/dashboard/students/edit-student/${studentId}`}
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{ ml: 1 }} variant="contained">
              {t('buttons.edit')}
            </Button>
          </Link>
        </>
      }
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <StudentDetails data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
