import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getUsersForRoleService } from 'src/modules/user-services/services';
import { TableContext } from 'src/shared/contexts/table-context';
import { queryError } from 'src/shared/utils/query-error';
import { guardiansMapper } from '../mappers';

export const useGuardians = function (paginated = true) {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const archived = searchParams.get('archived');
  const { page, limit } = useContext(TableContext);
  const { data, isLoading } = useQuery({
    queryKey: [
      'guardians-get-all',
      'guardian',
      { page, limit, q, archived, paginated }
    ],
    queryFn: getUsersForRoleService,
    select: ({ data }) => {
      const mappedGuardians = guardiansMapper(data);
      if (data?.status === 'success') {
        return mappedGuardians;
      }
    },
    onError: queryError
  });
  return { data, isLoading };
};
