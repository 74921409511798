import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Box, Card, Tooltip, Typography, css, styled } from '@mui/material';

import LoginForm from '../components/LoginForm';
import Logo from 'src/shared/components/Logo';
import Header from '../components/header/header';
import Slider from 'react-slick';
import Scrollbar from 'src/shared/components/Scrollbar';
import { SidebarHeader } from '../components/sidebar/sidebar-header';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 440px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TopWrapper = styled(Box)(
  () => css`
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    padding: 20px;
    justify-content: center;
  `
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

const graduatesData = [
  {
    id: 1,
    fullname: 'Милана  Намазова',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/lj3gkyto9dhoy333ioid.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 80,
      height: 80,
      left: 0,
      top: 40
    }
  },
  {
    id: 2,
    fullname: 'Камилла  Новрузова',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706772428/team-photos/a97iw56rtjosjzqp1gwf.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 90,
      height: 90,
      left: 80,
      top: 0
    }
  },
  {
    id: 3,
    fullname: 'Анар Ахмедов',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/b3cyavx1scjisvz4cuaz.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 110,
      height: 110,
      top: -162,
      left: 170
    }
  },
  {
    id: 4,
    fullname: 'Айхан Гардашханов',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/rp2cut6i1y483zydlzcx.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 70,
      height: 70,
      top: -162,
      left: 259
    }
  },
  {
    id: 5,
    fullname: 'Александра Шляхтина',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/zoj3si139c1h4oaclo43.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 80,
      height: 80,
      left: 0,
      top: 40
    }
  },
  {
    id: 6,
    fullname: 'Ибрагим  Оруджбейли',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/owizi1bc707o2tbp2oj7.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 90,
      height: 90,
      left: 80,
      top: 0
    }
  },
  {
    id: 7,
    fullname: 'Диана Клименко',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/dtemmf9zawfm2i7bwztq.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 110,
      height: 110,
      top: -162,
      left: 170
    }
  },
  {
    id: 8,
    fullname: 'Дашдамир Рагимов',
    image:
      'https://res.cloudinary.com/ddiwagbct/image/upload/v1706771551/team-photos/fdetwbem8x0ipg0ps77f.webp',
    link: 'https://aspirans.az/#teachers',
    styles: {
      width: 70,
      height: 70,
      top: -162,
      left: 259
    }
  }
];

function LoginBasic() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000
  };
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('login.title')}</title>
      </Helmet>
      <Header />
      <SidebarHeader />
      <Content>
        <MainContent>
          <TopWrapper>
            <Box sx={{ width: { sm: '546px', xs: '100%' } }}>
              <Card
                sx={{
                  mt: 3,
                  paddingLeft: { sm: '36px', xs: '22px' },
                  paddingRight: { sm: '36px', xs: '22px' },
                  paddingTop: { sm: '45px', xs: '22px' },
                  paddingBottom: '27px'
                }}
              >
                <Box>
                  <Box
                    sx={{
                      mb: 2,
                      img: {
                        width: '53px',
                        height: '38px'
                      }
                    }}
                  >
                    <Logo />
                  </Box>
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 1
                    }}
                  >
                    {t('signin.title')}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{
                      mb: 3
                    }}
                  >
                    {t('signin.label')}
                  </Typography>
                </Box>
                <LoginForm />
              </Card>
            </Box>
          </TopWrapper>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginBasic;
