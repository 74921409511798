import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/shared/hooks/useAuth';
import { useLowerCategoriesByUpperSlug } from 'src/modules/lower-categories/hooks/use-lower-categories-by-upper-slug';
import { useUsers } from 'src/modules/users/hooks/use-users';
import { useEmployees } from 'src/modules/employees/hooks/use-employees';

function TransactionsSearch() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { data: lowerCategoriesByExpenses } =
    useLowerCategoriesByUpperSlug('expenses');
  const { data: lowerCategoriesByIncomes } =
    useLowerCategoriesByUpperSlug('incomes');
  const { user } = useAuth();
  const { data: employeesData } = useEmployees(false);
  const { data: users } = useUsers(false);

  const isSingleBranch =
    user?.branches?.length <= 1
      ? user.branches?.map((branch) => ({
          id: branch?.id,
          name: branch?.name
        }))[0]
      : '';

  const formik = useFormik({
    initialValues: {
      q: '',
      category: '',
      branch: isSingleBranch?.id || '',
      createdBy: '',
      paymentMethod: '',
      relatedTo: '',
      transactionType: ''
    },
    onSubmit: () => {}
  });

  let q = formik.values.q;
  let category = formik.values?.category;
  let branch = formik.values?.branch;
  let createdBy = formik.values?.createdBy;
  let paymentMethod = formik.values?.paymentMethod;
  let relatedTo = formik.values?.relatedTo;
  let transactionType = formik.values?.transactionType;

  const triggerSearch = useCallback(function (
    q,
    category,
    branch,
    createdBy,
    paymentMethod,
    relatedTo,
    transactionType
  ) {
    if (q.trim() !== '') {
      searchParams.set('q', q);
    } else {
      searchParams.delete('q');
    }

    if (category.trim() !== '') {
      searchParams.set('category', category);
    } else {
      searchParams.delete('category');
    }

    if (branch.trim() !== '') {
      searchParams.set('branch', branch);
    } else {
      searchParams.delete('branch');
    }

    if (createdBy.trim() !== '') {
      searchParams.set('createdBy', createdBy);
    } else {
      searchParams.delete('createdBy');
    }

    if (paymentMethod.trim() !== '') {
      searchParams.set('paymentMethod', paymentMethod);
    } else {
      searchParams.delete('paymentMethod');
    }

    if (relatedTo.trim() !== '') {
      searchParams.set('relatedTo', relatedTo);
    } else {
      searchParams.delete('relatedTo');
    }
    if (transactionType.trim() !== '') {
      searchParams.set('transactionType', transactionType);
    } else {
      searchParams.delete('transactionType');
    }

    setSearchParams(searchParams);
  },
  []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(
        q,
        category,
        branch,
        createdBy,
        paymentMethod,
        relatedTo,
        transactionType
      );
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [
    q,
    category,
    branch,
    createdBy,
    paymentMethod,
    relatedTo,
    transactionType
  ]);

  const data = lowerCategoriesByExpenses?.data
    .concat(lowerCategoriesByIncomes?.data)
    ?.map((item) => ({ id: item?._id, title: item?.title }));

  const paymentMethods = [
    { id: 1, name: 'cash' },
    { id: 2, name: 'online' }
  ];
  const transactionTypes = [
    { id: 1, name: 'income' },
    { id: 2, name: 'expense' }
  ];

  const isBranch = user?.branches?.length <= 1;
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={t('inputs.label.searchTransaction')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('q')}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
          fullWidth
          options={users?.data || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('relatedTo', newValue ? newValue.id : '')
          }
          value={
            users?.data?.find((user) => {
              return user.id === formik.values?.relatedTo;
            }) || null
          }
          getOptionLabel={(option) => `${option.fullName} (${option.code})`}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.label.relatedTo')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Autocomplete
          fullWidth
          options={data || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('category', newValue ? newValue.id : '')
          }
          value={
            data?.find((category) => category.id === formik.values?.category) ||
            null
          }
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.label.category')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Autocomplete
          fullWidth
          disabled={isBranch}
          options={user?.branches || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('branch', newValue ? newValue.id : '')
          }
          value={
            user.branches?.find((branch) => {
              return branch.id === formik.values.branch;
            }) || null
          }
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => (
            <TextField fullWidth {...params} label={t('inputs.label.branch')} />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <Autocomplete
          fullWidth
          options={paymentMethods || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('paymentMethod', newValue ? newValue.name : '')
          }
          value={
            paymentMethods?.find((paymentMethod) => {
              return paymentMethod.name === formik.values.paymentMethod;
            }) || null
          }
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.label.paymentMethod')}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <Autocomplete
          fullWidth
          options={transactionTypes || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue(
              'transactionType',
              newValue ? newValue.name : ''
            )
          }
          value={
            transactionTypes?.find((transactionType) => {
              return transactionType.name === formik.values.transactionType;
            }) || null
          }
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.label.transactionType')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
          fullWidth
          options={employeesData?.data || []}
          onChange={(_e, newValue: any) =>
            formik.setFieldValue('createdBy', newValue ? newValue?.id : '')
          }
          value={
            users?.data?.find((user) => {
              return user?.id === formik.values?.createdBy;
            }) || null
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => `${option.fullName} (${option.code})`}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.label.createdBy')}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default TransactionsSearch;
