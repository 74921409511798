import { Outlet } from 'react-router';
import { GuardianContainer } from '../containers/guardian-container';

export const Guardian = function () {
  return (
    <>
      <GuardianContainer />
      <Outlet />
    </>
  );
};
