import { Checkbox, FormControlLabel } from '@mui/material';

import React from 'react';

type OmCheckboxProps = {
  formik: any;
  label: string;
  name: string;
  required?: boolean;
  checked?: boolean;
};

export const OmCheckbox: React.FC<OmCheckboxProps> = ({
  formik,
  label,
  name,
  required,
  checked
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          required={required}
          name={name}
          color="primary"
          checked={checked}
          {...formik.getFieldProps(name)}
        />
      }
      label={label}
    />
  );
};
