import { Autocomplete, Grid, TextField } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLowerCategoriesByUpperSlug } from 'src/modules/lower-categories/hooks/use-lower-categories-by-upper-slug';
import ArchiveFilter from 'src/modules/components/archive-filter';

function BranchSearch() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: roles } = useLowerCategoriesByUpperSlug('roles');

  const rolesData = roles?.data?.map((item) => ({
    id: item?._id,
    title: item?.title,
    slug: item?.slug
  }));

  const formik = useFormik({
    initialValues: {
      q: '',
      role: '',
      archived: ''
    },
    onSubmit: () => {}
  });

  let q = formik.values?.q;
  let role = formik.values?.role;
  let archived = formik.values?.archived;

  const triggerSearch = useCallback(function (q, role, archived) {
    if (q.trim() !== '') {
      searchParams.set('q', q);
    } else {
      searchParams.delete('q');
    }

    if (role.trim() !== '') {
      searchParams.set('role', role);
    } else {
      searchParams.delete('role');
    }
    if (archived.trim() !== '') {
      searchParams.set('archived', archived);
    } else {
      searchParams.delete('archived');
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(q, role, archived);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [q, role, archived]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={t('inputs.label.search')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('q')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
          autoSelect
          disablePortal
          filterSelectedOptions
          options={rolesData || []}
          onChange={(_e, newValue) => {
            formik.setFieldValue('role', newValue ? newValue?.slug : '');
          }}
          getOptionLabel={(option: any) => option?.title}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField fullWidth {...params} label={t('inputs.label.role')} />
          )}
        />
      </Grid>
      <ArchiveFilter formik={formik} type="Users" />
    </Grid>
  );
}

export default BranchSearch;
