import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSubjectForm } from '../hooks/use-subject-form';
import { styled } from '@mui/material';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import { FormCheckbox, FormInputText } from 'src/shared/components/form-hook';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const SubjectContainer = function () {
  const { t } = useTranslation();
  const { handleSubmit, control, setValue, initialValues, isSubmitting, id } =
    useSubjectForm();

  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleModalClose = () => {
    navigate(`/dashboard/subjects`);
  };

  if (!initialValues && id) {
    return (
      <Dialog
        fullWidth
        open={true}
        ref={modalRef}
        maxWidth="sm"
        onClose={handleModalClose}
      >
        <Grid
          container
          justifyContent={'center'}
          sx={{ padding: '160px 30px' }}
        >
          <CircularProgress />
        </Grid>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullWidth
      open={true}
      ref={modalRef}
      maxWidth="sm"
      onClose={handleModalClose}
    >
      <DialogTitle
        variant="h3"
        sx={{
          p: 3,
          borderBottom: '1px solid #d9dce1',
          marginBottom: '9px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {t(`subjects.${id ? 'editSubject' : 'addSubject'}`)}
      </DialogTitle>
      <Box sx={{ p: 4 }} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              required
              label={t('inputs.label.name')}
              name="name"
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <FormCheckbox
              control={control}
              name="isTought"
              label={t('inputs.label.isTought')}
              setValue={setValue}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              sx={{
                mt: 3
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="medium"
              variant="contained"
            >
              {t('buttons.confirm')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
