import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { GroupsPageActions } from '../components/groups-page-actions';
import { useGroupsTable } from '../hooks/use-groups-table';
import { useGroups } from '../hooks/use-groups';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { Box, Card, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GroupsSearch from '../components/groups-search';
import { TableContext } from 'src/shared/contexts/table-context';

export function GroupsContainer() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { limit, page, setLimit, setPage } = useContext(TableContext);

  const [columns, options, routes] = useGroupsTable();

  const { data } = useGroups();

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('groups.name')}
      action={<GroupsPageActions />}
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px'
        }}
      >
        <Box
          onClick={handleFilter}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>{t('inputs.label.filter')}</Typography>
          {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
          {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
        </Box>
        {isOpen && <GroupsSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data?.data}
          rowsCount={data?.total}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
}
