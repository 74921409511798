import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IRoom } from '../type';
import { RoomsTableActionButtons } from '../components/rooms-table-action-buttons';

export const useRoomsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('rooms.name'),
      href: '/dashboard/rooms'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'branch',
        align: 'left',
        label: t('table.branch'),
        extructor: (room: IRoom) => room?.branch?.name
      },
      {
        id: 'number',
        align: 'center',
        label: t('table.number'),
        extructor: (room: IRoom) => room?.number
      },
      {
        id: 'size',
        align: 'center',
        label: t('table.size'),
        extructor: (room: IRoom) => room?.size
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (room: IRoom) => {
          return <RoomsTableActionButtons room={room} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
