import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid, Card, CircularProgress, Button } from '@mui/material';
import { useGroup } from '../hooks/use-group';
import { GroupDetailsForm } from '../components/group-details-form';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { TeachersTable } from '../components/teachers-table';
import { StudentsTable } from '../components/students-table';

export const GroupDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGroup();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('groups.name'),
      href: '/dashboard/groups'
    },
    {
      label: t('groups.group'),
      href: '/dashboard/groups/group'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('groups.group')}: ${data ? data?.code : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <GroupDetailsForm data={data} />
              </Grid>
            </Card>
          </Grid>
          <StudentsTable />
          <TeachersTable />
        </Grid>
      )}
    </PageWrapper>
  );
};
