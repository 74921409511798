import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { LessonForm } from '../components/lesson-form';
import { useLessonForm } from '../hooks/use-lesson-form';
import { styled } from '@mui/material';
import LoadingCircle from 'src/shared/components/Loading-circle';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const LessonContainer = function () {
  const { t } = useTranslation();
  const {
    formik,
    isLoading,
    groupsData,
    teachersData,
    subjectsData,
    isAdding,
    roomsData,
    data
  } = useLessonForm();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('lessons.name'),
      href: '/dashboard/lessons'
    },
    {
      label: data ? t('lessons.editLesson') : t('lessons.addLesson'),
      href: '/dashboard/lessons/add-lesson'
    }
  ];

  if (!data && !isAdding) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }
  return (
    <PageWrapper
      routes={routes}
      title={data ? t('lessons.editLesson') : t('lessons.addLesson')}
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <LessonForm
            formik={formik}
            isLoading={isLoading}
            groupsData={groupsData}
            teachersData={teachersData}
            subjectsData={subjectsData}
            roomsData={roomsData}
            data={data}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
