import {
  IBranchDetails,
  IBranchDetailsAPI,
  IBranchForm,
  IBranchFormAPI,
  TBranchesRootAPI
} from '../type';

export function branchMapper(value) {
  const branch = { name: value.name, id: value.id };
  return branch;
}

export function addAndEditBranchMapper(values: any): any {
  return {
    name: values?.name,
    address: values?.address,
    managers: values?.managers?.map((item) => item?.id)
  };
}

export function branchDetailsMapper(branch: any): any {
  return {
    id: branch?.id,
    code: branch?.code,
    managers: branch?.managers,
    company: branch?.company,
    address: branch?.address,
    name: branch?.name,
    balance: branch?.balance
  };
}

export function branchesMapper(data: TBranchesRootAPI) {
  const branches = data?.data?.map((branch) => branchMapper(branch));
  const total = data?.total;
  return { data: branches, total: total };
}
