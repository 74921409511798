import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericServiceById } from 'src/shared/services/generic-services';
import { detailsEmployeeMapper } from '../mappers';

export const useEmployee = () => {
  const { employeeId } = useParams();

  const { data, isLoading } = useQuery(
    ['users', employeeId],
    getGenericServiceById,
    {
      select: ({ data }) => {
        const mappedEmployeeDetails = detailsEmployeeMapper(data?.data);
        if (data?.status === 'success') {
          return mappedEmployeeDetails;
        }
      },
      onError: queryError
    }
  );

  return {
    data,
    isLoading
  };
};
