import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericServiceById } from 'src/shared/services/generic-services';
import { detailsUserMapper } from '../mappers';

export const useUser = () => {
  const { userId } = useParams();

  const { data, isLoading } = useQuery(
    ['users', userId],
    getGenericServiceById,
    {
      select: ({ data }): any => {
        const mappedDetailsUser = detailsUserMapper(data?.data);
        if (data?.status === 'success') {
          return mappedDetailsUser;
        }
      },
      onError: queryError
    }
  );

  return {
    data,
    isLoading
  };
};
