import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OmInput, OmSelect } from 'src/shared/components';
import { OmDesktopDatePicker } from 'src/shared/components/form-components/om-desktop-date-picker';
import useAuth from 'src/shared/hooks/useAuth';

export const StudentForm = function ({
  formik,
  isSubmitting,
  guardiansData,
  packagesData,
  studentId
}) {
  const { t } = useTranslation();
  const { user } = useAuth();
  // branches:
  console.log(user?.branches);

  const relationships = [
    {
      id: 'father',
      name: t('inputs.options.father')
    },
    {
      id: 'mother',
      name: t('inputs.options.mother')
    }
  ];

  const genders = [
    { id: 'male', name: t('inputs.options.male') },
    { id: 'female', name: t('inputs.options.female') },
    { id: 'unknown', name: t('inputs.options.unknown') }
  ];

  const classes = Array.from({ length: 12 }, (_, index) => ({
    id: index + 1,
    name: index + 1
  }));

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <OmSelect
            disabled={user?.branches.length === 1}
            required
            formik={formik}
            label={t('inputs.label.branch')}
            name="registeredBranches"
            options={user?.branches}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.name')}
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.surname')}
            name="surname"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.fatherName')}
            name="patronymic"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.email')}
            name="email"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.phoneNumber')}
            name="phoneNumbers"
            placeholder="(xx)xxxxxxx"
            type="number"
            onWheel={(e: any) => e.target.blur()}
            startAdornment={
              formik.values.isForeign ? (
                <></>
              ) : (
                <InputAdornment position="start">+994</InputAdornment>
              )
            }
            endAdornment={
              <Box display={'flex'}>
                <FormControlLabel
                  sx={{ flex: 1, whiteSpace: 'nowrap' }}
                  label={`${t('inputs.label.isForeign')}`}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        formik.setFieldValue('isForeign', e.target.checked)
                      }
                      checked={formik.values.isForeign}
                      name="isForeign"
                    />
                  }
                />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.label.groupDP')}
            name="groupDP"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmSelect
            required
            formik={formik}
            label={t('inputs.label.schoolAdmittionYear')}
            name="schoolAdmittionYear"
            options={classes}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            fullWidth
            options={guardiansData?.data || []}
            value={formik?.values?.guardian || null}
            onChange={(_e, newValue) => {
              formik.setFieldValue('guardian', newValue ? newValue : '');
              formik.setFieldValue('relationship', '');
            }}
            getOptionLabel={(option) => `${option.fullName}  `}
            renderInput={(params) => (
              <TextField
                required
                fullWidth
                {...params}
                label={t('inputs.label.guardian')}
              />
            )}
          />
          {formik.touched.guardian && formik.errors.guardian && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.guardian}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OmSelect
            required
            formik={formik}
            label={t('inputs.label.relationship')}
            name="relationship"
            options={relationships || []}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmSelect
            required
            formik={formik}
            label={t('inputs.label.gender')}
            name="gender"
            options={genders || []}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OmDesktopDatePicker
            formik={formik}
            label={t('inputs.label.dateOfBirth')}
            name="dateOfBirth"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            options={packagesData?.data || []}
            disablePortal
            filterSelectedOptions
            onChange={(_e, newValue) => {
              formik.setFieldValue('package', newValue ? newValue : '');
            }}
            value={formik?.values?.package || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: any) => {
              return `${option?.name}`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.package')}
                error={formik.touched.package && Boolean(formik.errors.package)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.label.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t(`buttons.${studentId ? 'update' : 'addStudent'}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
