import { Typography, Autocomplete, Box, Grid, TextField } from '@mui/material';
import { OmInput } from 'src/shared/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';

export const AddEditRoomForm = function ({
  formik,
  user,
  roomId,
  isSubmitting
}) {
  const { t } = useTranslation();
  const isBranch = user?.branches?.length <= 1;

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            disabled={isBranch}
            options={user?.branches || []}
            value={formik.values.branch || null}
            onChange={(_e, newValue) => {
              formik.setFieldValue('branch', newValue ? newValue : '');
            }}
            getOptionLabel={(option) => `${option.name} `}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => (
              <TextField
                required
                fullWidth
                name="branch"
                {...params}
                label={t('inputs.label.branch')}
              />
            )}
          />
          {formik.touched.branch && formik.errors.branch && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943'
              }}
            >
              {formik.errors.branch}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.name')}
            name="name"
          />
        </Grid>
        <Grid item xs={12}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.number')}
            name="number"
            type="number"
            onWheel={(e: any) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12}>
          <OmInput
            formik={formik}
            label={t('inputs.label.size')}
            name="size"
            type="number"
            onWheel={(e: any) => e.target.blur()}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t(`buttons.${roomId ? 'update' : 'addRoom'}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
