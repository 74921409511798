import cuid from 'cuid';
import type { FC } from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LoadingCircle from '../Loading-circle';

import { IGenericTableColumnsV2, IOptionsV2 } from './types';

interface GenericTableProps {
  rows: any[];
  columns: IGenericTableColumnsV2;
  options: IOptionsV2;
  limit: string;
  rowsCount?: number;
  page: string;
  setPage: (a: string) => void;
  setLimit: (a: string) => void;
}

const GenericTableV2: FC<GenericTableProps> = ({
  rows,
  columns,
  page,
  limit,
  setPage,
  setLimit,
  options,
  rowsCount = 400
}) => {
  const { t } = useTranslation();
  function handlePageChange(_, newPage) {
    setPage(String(newPage));
  }

  function handleLimitChange(event) {
    setLimit(event.target.value);
  }

  if (!rows) {
    return (
      <Box
        sx={{
          minHeight: '600px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LoadingCircle />
      </Box>
    );
  }

  return (
    <>
      {rows?.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  key={cuid()}
                  align="left"
                  padding="normal"
                  sx={{ textTransform: 'none' }}
                >
                  {t('table.rowsOrder')}
                </TableCell>
                {columns.map(({ align, label }) => {
                  return (
                    <TableCell
                      key={cuid()}
                      align={align}
                      padding="normal"
                      sx={{ textTransform: 'none' }}
                    >
                      {label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item, i) => {
                return (
                  <TableRow
                    hover
                    key={cuid()}
                    // selected={selectedItems.includes(row.id)}
                  >
                    <TableCell align={'left'}>
                      <Typography noWrap variant="subtitle2">
                        {i + 1}
                      </Typography>
                    </TableCell>
                    {columns.map(({ id, extructor, render, align }) => (
                      <TableCell align={align} key={id}>
                        <Typography noWrap variant="subtitle2">
                          {render ? render(extructor(item)) : extructor(item)}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t('messages.notFound')}
        </Typography>
      )}
      <Box p={2}>
        <TablePagination
          component="div"
          count={rowsCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={Number(page)}
          rowsPerPage={Number(limit)}
          rowsPerPageOptions={options.pageLimits}
          labelRowsPerPage={t('inputs.label.rows')}
        />
      </Box>
    </>
  );
};

export default GenericTableV2;
