import { GroupFormPage } from '../pages/group-form-page';
import { AddStudentModal } from '../pages/add-student-modal';
import { GroupDetails } from '../pages/group-details';
import { Groups } from '../pages/groups';
import { AddTeacherModal } from '../pages/add-teacher-modal';

const groupsRouter = {
  path: 'groups',
  children: [
    {
      path: '',
      element: <Groups />
    },
    {
      path: 'add-group',
      element: <GroupFormPage />
    },
    {
      path: ':groupId',
      element: <GroupDetails />,
      children: [
        {
          path: 'add-student',
          element: <AddStudentModal />
        },

        {
          path: 'add-teacher',
          element: <AddTeacherModal />
        }
      ]
    },
    {
      path: 'edit/:groupId',
      element: <GroupFormPage />
    }
  ]
};

export default groupsRouter;
