import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getGenericService } from 'src/shared/services/generic-services';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TableContext } from 'src/shared/contexts/table-context';
import { branchesMapper } from '../mappers';

export const useBranches = function (paginated = true) {
  const [searchParams] = useSearchParams();

  const queryParams = {
    name: searchParams.get('name')
  };

  const { page, limit } = useContext(TableContext);

  const { data, isLoading } = useQuery(
    [
      'branches-get-all',
      'branches',
      { page, limit, paginated, ...queryParams }
    ],
    getGenericService,
    {
      select: ({ data }) => {
        const mappedBranches = branchesMapper(data);
        if (data?.status === 'success') {
          return mappedBranches;
        }
      },
      onError: queryError
    }
  );

  return {
    data,
    isLoading
  };
};
