import { FC, useState, createContext, useEffect } from 'react';

type UIContext = {
  lessonMode: boolean;
  setLessonMode: React.Dispatch<React.SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UIContext = createContext<UIContext>({} as UIContext);

export const UIProvider: FC = ({ children }) => {
  const [lessonMode, setLessonMode] = useState(false);

  const value = {
    lessonMode,
    setLessonMode
  };
  useEffect(() => {
    setLessonMode(JSON.parse(localStorage.getItem('lessonMode')));
  }, []);

  useEffect(() => {
    localStorage.setItem('lessonMode', JSON.stringify(lessonMode));
  }, [lessonMode]);

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
