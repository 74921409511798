import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';

export const ExecutionStatus = ({ recurrence }) => {
  const { t } = useTranslation();
  const executionStatus =
    recurrence?.executionCount === 0 ? (
      <Chip
        label={t('table.notExecutedYet')}
        color="primary"
        size="small"
        style={{
          backgroundColor: '#f44336',
          borderRadius: '8px',
          padding: '15px 10px'
        }}
      />
    ) : (
      <Chip
        label={`${t('table.executed')} (${recurrence?.executionCount})`}
        color="primary"
        size="small"
        style={{
          backgroundColor: '#4caf50',
          borderRadius: '8px',
          padding: '15px 10px'
        }}
      />
    );
  return executionStatus;
};
