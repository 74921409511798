import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import guardiansRouter from 'src/modules/guardians/routes';
import SuspenseLoader from 'src/shared/components/SuspenseLoader';

import branchesRouter from 'src/modules/branches/routes';
import employeesRouter from 'src/modules/employees/routes';
import groupsRouter from 'src/modules/groups/router';
import lessonsRouter from 'src/modules/lessons/router';
import maintenanceRouter from 'src/modules/maintenance/routes';
import packagesRouter from 'src/modules/packages/routes';
import recurrencesRouter from 'src/modules/recurrences/routes';
import roomsRouter from 'src/modules/rooms/routers';
import studentsRouter from 'src/modules/students/routes';
import subjectsRouter from 'src/modules/subjects/routes';
import transactionsRouter from 'src/modules/transactions/router';
import usersRouter from 'src/modules/users/routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Profile = Loader(lazy(() => import('src/modules/profile/pages/profile')));

const dashboardsRoutes = [
  {
    path: '',
    element: <Navigate to="/dashboard/stats" />
  },
  // dashboardRouter,
  // booksRouter,
  usersRouter,
  groupsRouter,
  branchesRouter,
  guardiansRouter,
  studentsRouter,
  employeesRouter,
  // examsRouter,
  subjectsRouter,
  packagesRouter,
  roomsRouter,
  transactionsRouter,
  // upperCategoriesRouter,
  // lowerCategoriesRouter,
  // feedbacksRouter,
  recurrencesRouter,
  lessonsRouter,
  maintenanceRouter,

  {
    path: 'profile',
    element: <Profile />
  },
  {
    path: '*',
    element: <Navigate to="/dashboard/users" />
  }
];

export default dashboardsRoutes;
