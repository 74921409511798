import { Outlet } from 'react-router';
import { UsersContainer } from '../containers/users-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Users() {
  return (
    <TableContextProvider>
      <UsersContainer />
      <Outlet />
    </TableContextProvider>
  );
}
