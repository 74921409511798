import { Chip, Tooltip, Typography } from '@mui/material';
import { ITransaction } from '../types';
import { useTranslation } from 'react-i18next';

export const TitleStatusAndHidden = ({
  transaction
}: {
  transaction: ITransaction;
}) => {
  if (transaction.description) {
    const { t } = useTranslation();
    return (
      <Tooltip title={t('tooltips.test')}>
        <Typography>
          {transaction.title}{' '}
          {transaction.hidden && (
            <Chip
              label="hidden"
              color="primary"
              size="small"
              style={{
                backgroundColor: '#ffc107',
                borderRadius: '12px'
              }}
            />
          )}
        </Typography>
      </Tooltip>
    );
  } else {
    return (
      <Typography>
        {transaction.title}{' '}
        {transaction.hidden && (
          <Chip
            label="hidden"
            color="primary"
            size="small"
            style={{ backgroundColor: '#ffc107', borderRadius: '12px' }}
          />
        )}
      </Typography>
    );
  }
};
