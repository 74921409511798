import { Outlet } from 'react-router';
import { SubjectContainer } from '../containers/subject-container';

export const Subject = function () {
  return (
    <>
      <SubjectContainer />
      <Outlet />
    </>
  );
};
