import { useState } from 'react';
import { TableContext } from '../contexts/table-context';

function TableContextProvider({ children }) {
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState('50');

  const value = { page, limit, setPage, setLimit };
  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}

export default TableContextProvider;
