import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import { useBranch } from './use-branch';
import { addAndEditBranchMapper } from '../mappers';
import { useManagers } from 'src/modules/employees/hooks/use-managers';

export const useBranchForm = function () {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { branchId } = useParams();
  const { data: managersData } = useManagers(false);
  const { data } = useBranch();

  const { mutate: addAndUpdateBranchMutate, isLoading: isSubmitting } =
    useMutation('add-and-update-branch', addAndUpdateGenericService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('branches-get-all');
          if (!branchId) navigate('/dashboard/branches');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.required.name')),
    address: Yup.string().required(t('yup.required.address'))
  });
  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      address: data?.address || '',
      managers: data?.managers || []
    },
    onSubmit: (values) => {
      const addAndEditBranchBody = addAndEditBranchMapper(values);

      if (values) {
        if (branchId) {
          addAndUpdateBranchMutate({
            type: 'branches',
            genericId: branchId,
            data: addAndEditBranchBody
          });
        } else {
          addAndUpdateBranchMutate({
            data: addAndEditBranchBody,
            type: 'branches'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return { data, formik, managersData, branchId, isSubmitting };
};
