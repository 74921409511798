import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material';
import { OmInput } from 'src/shared/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import Manat from 'src/assets/icon/manat';

export const PackageForm = function ({
  formik,
  isSubmitting,
  packageId,
  subjectsData
}) {
  const { t } = useTranslation();

  const subjects = subjectsData?.data?.data;
  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2} sx={{ maxWidth: '550px' }}>
        <Grid item xs={12}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.name')}
            name="name"
          />
        </Grid>
        <Grid item xs={12}>
          <OmInput
            required
            formik={formik}
            label={t('inputs.label.price')}
            name="price"
            type="number"
            onWheel={(e: any) => e.target.blur()}
            endAdornment={
              <InputAdornment position="end">
                <Manat />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            multiple
            options={subjects || []}
            onChange={(_e, newValue) =>
              formik.setFieldValue('subjects', newValue ? newValue : '')
            }
            value={formik.values.subjects || []}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.label.subjects')}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          fullWidth
          color="primary"
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t(`buttons.${packageId ? 'update' : 'addPackage'}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
