import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  addAndUpdateSubUser,
  addAndUpdateUser
} from 'src/modules/user-services/services';
import { useUser } from './use-user';
import { userFormMapper } from '../mappers';
import { REGULAR_EXPRESSIONS } from 'src/shared/costants/constants';
import { useLowerCategoriesByUpperSlug } from 'src/modules/lower-categories/hooks/use-lower-categories-by-upper-slug';

export const useUserForm = function () {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: categoriesByRoles } = useLowerCategoriesByUpperSlug('roles');
  const { data } = useUser();
  const isAdding = pathname.includes('add');
  const roles = data?.roles
    ?.filter((role) => role !== 'employee')
    ?.map((item) => ({ id: item, name: item }));

  const {
    mutate: addAndEditUserFormMutate,
    isLoading: isAddingAndEditingUser
  } = useMutation('user-form', addAndUpdateUser, {
    onSuccess: (e: any) => {
      if (e.status === 200 || e.status === 201) {
        handleMessage(true, t('messages.successfulProcess'), 'success');
        queryClient.invalidateQueries('users-get-all');
        navigate('/dashboard/users');
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg =
        error?.response?.data?.message || t('messages.unknownError');
      handleMessage(true, errorMsg, 'error');
    }
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    isForeign: Yup.boolean(),
    phoneNumbers: Yup.string().when('isForeign', {
      is: false,
      then: Yup.string()
        .min(9, t('yup.required.minPhoneNumber'))
        .max(9, t('yup.required.maxPhoneNumber'))
        .matches(REGULAR_EXPRESSIONS.phone, t('yup.required.validPhoneNumber'))
        .required()
    })
  });

  const isForeign = data && data?.phoneNumbers.toString().slice(0, 3) !== '994';
  const phoneNumber = isForeign
    ? data?.phoneNumbers.toString()
    : data?.phoneNumbers.toString().slice(3);

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      surname: data?.surname || '',
      patronymic: data?.patronymic || '',
      roles: roles || [],
      description: data?.description || '',
      note: data?.note || '',
      phoneNumbers: phoneNumber || '',
      email: data?.email || '',
      dateOfBirth: data?.dateOfBirth || '',
      isForeign: isForeign || false,
      gender: data?.gender || ''
    },
    onSubmit: (values) => {
      const mappedUser = userFormMapper(values);
      if (values) {
        if (userId) {
          addAndEditUserFormMutate({
            data: mappedUser,
            userId: userId
          });
        } else {
          addAndEditUserFormMutate({
            data: mappedUser,
            role: 'user'
          });
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isAddingAndEditingUser,
    categoriesByRoles,
    isAdding,
    data
  };
};
