import { Outlet } from 'react-router';
import { EmployeesContainer } from '../containers/employees-container';
import TableContextProvider from 'src/shared/provider/table-context-provider';

export function Employees() {
  return (
    <TableContextProvider>
      <EmployeesContainer />
    </TableContextProvider>
  );
}
