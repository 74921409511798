import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { StudentForm } from '../components/student-form';
import { useStudentForm } from '../hooks/use-student-form';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { styled } from '@mui/material';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export const StudentContainer = function () {
  const { t } = useTranslation();
  const { formik, isSubmitting, guardiansData, packagesData, studentId, data } =
    useStudentForm();
  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('students.name'),
      href: '/dashboard/students'
    },
    {
      label: studentId
        ? `${t('students.editStudent')}: ${data?.fullName} ${
            data?.groups?.length
              ? `(${data?.groups
                  ?.map((group: any) => group?.branch?.name)
                  .join(', ')})`
              : ''
          }`
        : t('students.addStudent'),
      href: '/dashboard/parents/add-student'
    }
  ];

  if (!data && studentId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }

  return (
    <PageWrapper
      routes={routes}
      title={
        studentId
          ? `${t('students.student')}: ${data?.code}`
          : t('students.addStudent')
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <StudentForm
            formik={formik}
            studentId={studentId}
            isSubmitting={isSubmitting}
            guardiansData={guardiansData}
            packagesData={packagesData}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
