import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTransactionForm } from '../hooks/use-transaction-form';
import { TransactionForm } from '../components/transaction-form';

export const TransactionFormContainer = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const {
    formik,
    isSubmitting,
    incomesUpperCategories,
    expensesUpperCategories,
    studentsData,
    employeesData,
    transactionType,
    user
  } = useTransactionForm();

  const transactionRoute =
    (location.pathname.includes('add-expense') &&
      t('transactions.addExpense')) ||
    (location.pathname.includes('add-transaction') &&
      t('transactions.addTransaction'));

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('transactions.name'),
      href: '/dashboard/transactions'
    },
    {
      label: transactionRoute,
      href: '/dashboard/transactions'
    }
  ];

  const isAddTransaction = transactionRoute === 'add transaction';

  return (
    <PageWrapper routes={routes} title={transactionRoute}>
      <Card sx={{ p: 3 }}>
        <Grid container>
          <TransactionForm
            formik={formik}
            isSubmitting={isSubmitting}
            isAddTransaction={isAddTransaction}
            incomesUpperCategories={incomesUpperCategories}
            expensesUpperCategories={expensesUpperCategories}
            studentsData={studentsData}
            employeesData={employeesData}
            transactionType={transactionType}
            user={user}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
