export function groupMapper(values: any): any {
  const group = {
    branch: values?.branch?.id,
    name: values?.name,
    room: values?.room,
    students: values?.students?.map((student) => student.id),
    teachers: values?.teachers?.map((teacher) => teacher.id),
    weekdays: values?.weekdays
  };
  return group;
}

export function editGroupMapper(values: any): any {
  const editGroup = {
    id: values?.id,
    code: values?.code,
    branch: values?.branch,
    name: values?.name,
    room: values?.room,
    students: values?.students,
    teachers: values?.teachers,
    weekdays: [...values?.weekdays.split('')]
  };
  return editGroup;
}

export function groupDetailsMapper(values) {
  const group = {
    id: values?.id,
    code: values?.code,
    branch: values?.branch,
    name: values?.name,
    room: values?.room?.name,
    students: values?.students,
    teachers: values?.teachers,
    weekdays: values?.weekdays,
    archived: values?.archived
  };
  return group;
}

export function groupsMapper(data) {
  const groups = {
    data: data?.data.map((group) => groupDetailsMapper(group)),
    total: data?.total
  };
  return groups;
}
