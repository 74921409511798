import { Expense } from '../pages/expense';
import { Income } from '../pages/income';
import { TransactionDetails } from '../pages/transaction-details';
import { Transactions } from '../pages/transactions';

const transactionsRouter = {
  path: 'transactions',
  children: [
    {
      path: '',
      element: <Transactions />,
      children: []
    },
    {
      path: ':transactionId',
      element: <TransactionDetails />
    },
    {
      path: 'add-transaction',
      element: <Income />
    },
    {
      path: 'add-expense',
      element: <Expense />
    }
  ]
};

export default transactionsRouter;
