import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DetailsTable from './details-table';

export const LessonDetails = ({ data }: { data: any }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sx={{ mb: 5 }}>
      <Card sx={{ p: 3 }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.topic')}: {data?.topic}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.group')}: {data?.group?.name}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.teacher')}: {data?.teacher?.fullName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.subject')}: {data?.subject?.name}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.room')}: {data?.room?.number} (
                {data?.room?.branch?.name})
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px'
                }}
              >
                {t('inputs.label.isExtra')}:{' '}
                {data?.isExtra
                  ? t('inputs.label.extra')
                  : t('inputs.label.notExtra')}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={5.7} py={6}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('typography.present')} ({data?.present?.length})
          </Typography>
          <Grid sx={{ py: 1 }}>
            <DetailsTable data={data?.present} type="present" />
          </Grid>
        </Grid>
        <Grid item xs={12} md={5.7} py={6}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('typography.absent')} ({data?.absent?.length})
          </Typography>
          <Grid sx={{ py: 1 }}>
            <DetailsTable data={data?.absent} type="absent" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
