import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ArchiveFilter from 'src/modules/components/archive-filter';

function StudentSearch() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      q: '',
      archived: ''
    },
    onSubmit: () => {}
  });

  let q = formik.values.q;
  let archived = formik.values?.archived;

  const triggerSearch = useCallback(function (q, archived) {
    if (q.trim() !== '') {
      searchParams.set('q', q);
    } else {
      searchParams.delete('q');
    }
    if (archived.trim() !== '') {
      searchParams.set('archived', archived);
    } else {
      searchParams.delete('archived');
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(q, archived);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [q, archived]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('inputs.label.search')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('q')}
        />
      </Grid>
      <ArchiveFilter formik={formik} type="Students" />
    </Grid>
  );
}

export default StudentSearch;
