import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type OmDesktopDatePickerProps = {
  formik: any;
  label: string;
  name: string;
  readOnly?: boolean;
};

export const OmDesktopDatePicker: React.FC<OmDesktopDatePickerProps> = ({
  formik,
  label,
  name,
  readOnly
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={formik.values[name]}
        onChange={(newDate) => {
          formik.setFieldValue(name, newDate);
        }}
        label={label}
        readOnly={readOnly}
        format="DD/MM/YYYY"
        name={name}
        slotProps={{
          textField: {
            variant: 'outlined',
            error: formik.touched[name] && Boolean(formik.errors[name]),
            helperText: formik.touched[name] && formik.errors[name],
            fullWidth: true
          }
        }}
      />
    </LocalizationProvider>
  );
};
