import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { RecurrenceDetailsForm } from '../components/recurrence-details-form';
import { useRecurrence } from '../hooks/use-recurrence';

export const RecurrenceDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useRecurrence();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('recurrences.name'),
      href: '/dashboard/recurrences'
    },
    {
      label: `${data ? data?.title : ''}`,
      href: `/dashboard/recurrences/${data?.id}`
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('recurrences.recurrence')}: ${data ? data?.title : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <RecurrenceDetailsForm data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
