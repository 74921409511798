import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useRecurrence } from './use-recurrence';
import { getPriority, recurrenceMapper } from '../mappers';
import { addAndUpdateGenericService } from 'src/shared/services/generic-services';
import useAuth from 'src/shared/hooks/useAuth';
import { useLowerCategoriesByUpperSlug } from 'src/modules/lower-categories/hooks/use-lower-categories-by-upper-slug';
import { useUsers } from 'src/modules/users/hooks/use-users';
import dayjs from 'dayjs';

export const useRecurrenceForm = function () {
  const { t } = useTranslation();
  const { handleMessage } = useContext(AlertContext);
  const { recurrenceId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useRecurrence();
  const { data: incomeCategories } = useLowerCategoriesByUpperSlug('incomes');
  const { data: expenseCategories } = useLowerCategoriesByUpperSlug('expenses');

  const { user } = useAuth();

  const { data: usersData } = useUsers(false);

  const priorities = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' }
  ];

  const { mutate: addAndEditRecurrenceFormMutate, isLoading: isSubmitting } =
    useMutation('add-recurrence', addAndUpdateGenericService, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(true, t('messages.successfulProcess'), 'success');
          queryClient.invalidateQueries('recurrences-get-all');
          if (!recurrenceId) navigate('/dashboard/recurrences');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      }
    });

  const isSingleBranch =
    user?.branches?.length <= 1
      ? user.branches?.map((branch) => ({
          id: branch.id,
          name: branch.name
        }))[0]
      : '';
  const validationSchema = Yup.object().shape({
    amount: Yup.number().required(t('yup.required.amount')),
    branch: Yup.object().required(t('yup.required.branch')),
    title: Yup.string().required(t('yup.required.title')),
    category: Yup.object().required(t('yup.required.category')),
    relatedTo: Yup.object().required(t('yup.required.relatedTo'))
  });
  const isIncome = data?.isIncome ? 'income' : 'expense';

  const priority = getPriority(data);

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      amount: data?.amount || '',
      description: data?.description || '',
      category: data?.category || '',
      isIncome: (data && isIncome) || 'income',
      paymentMethod: data?.paymentMethod || 'cash',
      branch: data?.branch || isSingleBranch,
      relatedTo: data?.relatedTo || '',
      periodicity: data?.periodicity || '',
      priority: priority || '',
      remindDuration: data?.remindDuration || '',
      dueDate: data?.dueDate ? dayjs(data?.dueDate) : null,
      hidden: data?.hidden || false,
      recipients: data?.recipients || ''
    },
    onSubmit: (values) => {
      const mappedRecurrence = recurrenceMapper(values);

      if (values) {
        if (recurrenceId) {
          addAndEditRecurrenceFormMutate({
            data: mappedRecurrence,
            genericId: recurrenceId,
            type: 'recurrences'
          });
        } else {
          addAndEditRecurrenceFormMutate({
            data: mappedRecurrence,
            type: 'recurrences'
          });
        }
      }
    },
    validationSchema,
    enableReinitialize: true
  });

  return {
    formik,
    isSubmitting,
    incomeCategories,
    expenseCategories,
    usersData,
    recurrenceId,
    data,
    priorities,
    user
  };
};
