import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { GuardiansTableActionButtons } from '../components/guardians-table-action-buttons';
import { IGuardian } from '../type';

export const useGuardiansTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('guardians.name'),
      href: '/dashboard/guardians'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      // {
      //   id: 'code',
      //   align: 'left',
      //   label: t('table.code'),
      //   extructor: (guardian: IGuardian) => guardian.code
      // },
      {
        id: 'fullName',
        align: 'left',
        label: t('table.fullName'),
        extructor: (guardian: IGuardian) => guardian.fullName
      },

      {
        id: 'email',
        align: 'center',
        label: t('table.email'),
        extructor: (guardian: IGuardian) => guardian.email
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (guardian: IGuardian) => {
          return <GuardiansTableActionButtons guardian={guardian} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
