import { AddLesson } from '../pages/add-lesson';
import { LessonDetails } from '../pages/lesson-details';
import { Lessons } from '../pages/lessons';

const lessonsRouter = {
  path: 'lessons',
  children: [
    {
      path: '',
      element: <Lessons />,
      children: []
    },
    {
      path: 'add-lesson',
      element: <AddLesson />,
      children: []
    },
    {
      path: ':lessonId',
      element: <LessonDetails />,
      children: []
    }
    // {
    //   path: 'edit-lesson/:lessonId',
    //   element: <AddLesson />,
    //   children: []
    // }
  ]
};

export default lessonsRouter;
