import { AxiosResponse } from 'axios';

import getAuthToken from 'src/shared/utils/getAuthToken';
import axios from 'src/shared/utils/axios';
export const updateUserPermissions = async ({
  employeeId,
  data
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/users/${employeeId}/permissions`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken()
      }
    });
  } catch (e) {
    return e;
  }
};
