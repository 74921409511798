import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useGroupForm } from '../hooks/use-group-form';
import { GroupForm } from '../components/group-form';
import { styled } from '@mui/material';
import LoadingCircle from 'src/shared/components/Loading-circle';
import { Grid } from '@mui/material';

const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

export function GroupFormContainer() {
  const { t } = useTranslation();

  const {
    data,
    formik,
    isSubmitting,
    roomsData,
    studentsData,
    teachersData,
    groupId,
    user
  } = useGroupForm();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('groups.name'),
      href: '/dashboard/groups'
    },
    {
      label: t(`groups.${groupId ? 'editGroup' : 'addGroup'}`),
      href: '/dashboard/groups'
    }
  ];

  if (!data && groupId) {
    return (
      <StyledGrid container justifyContent="center">
        <LoadingCircle />
      </StyledGrid>
    );
  }
  return (
    <PageWrapper
      routes={routes}
      title={
        groupId ? `${t('groups.group')}: ${data?.code}` : t('groups.addGroup')
      }
    >
      <GroupForm
        data={data}
        formik={formik}
        isSubmitting={isSubmitting}
        groupId={groupId}
        roomsData={roomsData}
        studentsData={studentsData}
        teachersData={teachersData}
        user={user}
      />
    </PageWrapper>
  );
}
