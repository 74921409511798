import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IBranchDetails } from '../type';
import { BranchesTableActionButtons } from '../components/branches-table-action-buttons';

export const useBranchesTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 50,
    pageLimits: [50, 100, 250, 500]
  };

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('branches.name'),
      href: '/dashboard/branches'
    }
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (branch: IBranchDetails) => branch?.name
      },

      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (branch: IBranchDetails) => {
          return <BranchesTableActionButtons branch={branch} />;
        }
      }
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
