import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJSON from './translations/en';
import azJSON from './translations/az';
import ruJSON from './translations/ru';

const resources = {
  az: { translation: azJSON },
  en: { translation: enJSON },
  ru: { translation: ruJSON }
};

const storedLanguage = localStorage.getItem('language');
const defaultLanguage =
  storedLanguage || (process.env.REACT_APP_ENV === 'development' ? 'en' : 'ru');

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  react: { useSuspense: true },
  interpolation: { escapeValue: false }
});

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
