import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
// import EditIcon from '@mui/icons-material/Edit';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { Link } from 'react-router-dom';
import { useMakeDeleteLesson } from '../hooks/use-make-delete-lesson';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

export const LessonsTableActionButtons: FC<{
  lesson;
}> = ({ lesson }) => {
  const { t } = useTranslation();
  const { makeDeleteLesson } = useMakeDeleteLesson();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/lessons/${lesson.id}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton
            component={Link}
            to={`/dashboard/lessons/edit-lesson/${lesson.id}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <Tooltip title={t('tooltips.deleteLesson')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    makeDeleteLesson({ type: 'lessons', genericId: lesson.id });
                    dispatch({
                      type: 'close'
                    });
                  },
                  message: t('messages.deleteLesson'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'error'
                }
              });
            }}
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
