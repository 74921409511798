import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRoom } from '../hooks/use-room';
import { RoomDetails } from '../components/room-details';

export const RoomDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useRoom();

  const routes = [
    {
      label: t('home.name'),
      href: '/dashboard'
    },
    {
      label: t('rooms.name'),
      href: '/dashboard/rooms'
    },
    {
      label: t('rooms.room'),
      href: '/dashboard/room'
    }
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('rooms.room')}: ${data?.id ? data?.id : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <RoomDetails data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
